import { Flex, Stack, Text, useColorMode } from '@chakra-ui/react';
import { IconUSDC, IconUSDT } from '../../../../../assets/Icons';
import { useState } from 'react';
import QRScreenComponent from '../../../../../components/QRScreenComponent';

const WalletPurchase = () => {
	const { colorMode } = useColorMode();
	const [isOpenQRModal, setIsOpenQRModal] = useState(false);

	const openQRModal = () => {
		setIsOpenQRModal(true);
	};
	const closeQRModal = () => {
		setIsOpenQRModal(false);
	};

	return (
		<>
			<Stack
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.50'
				}
				border='0.5px solid'
				borderColor='transparencyBlack.100'
				borderRadius='12px'
				p='20px'
				gap='15px'
			>
				<Text fontSize='16px' fontWeight='bold'>
					Select cryptocurrency to deposit
				</Text>
				<Flex justifyContent='space-evenly'>
					<Stack
						w='80px'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.50'
						}
						align='center'
						justifyContent='center'
						border='0.5px solid'
						borderColor='transparencyBlack.100'
						borderRadius='5px'
						p='10px'
						gap='10px'
						cursor='pointer'
						onClick={openQRModal}
					>
						<IconUSDT boxSize='20px' />
						<Text lineHeight='100%' fontSize='14px'>
							USDT
						</Text>
					</Stack>
					<Stack
						w='80px'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.50'
						}
						align='center'
						justifyContent='center'
						border='0.5px solid'
						borderColor='transparencyBlack.100'
						borderRadius='5px'
						p='10px'
						gap='10px'
						cursor='pointer'
						onClick={openQRModal}
					>
						<IconUSDC boxSize='20px' />
						<Text lineHeight='100%' fontSize='14px'>
							USDC
						</Text>
					</Stack>
				</Flex>
			</Stack>
			<QRScreenComponent
				isOpenQRModal={isOpenQRModal}
				closeQRModal={closeQRModal}
			/>
		</>
	);
};

export default WalletPurchase;
