import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';

import { IconPlus } from '../../../../assets/Icons';
import FAQsTable from './FAQsTable';
import { useState } from 'react';

const newFAQInitialValues = { question: '', answer: '' };

const FAQsComponent = ({ propertyInfo, setPropertyInfo }) => {
	const [newFAQ, setNewFAQ] = useState(newFAQInitialValues);

	const handleInputChange = event => {
		const { name, value } = event.target;
		setNewFAQ(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleAddFAQ = () => {
		if (newFAQ.question && newFAQ.answer) {
			setPropertyInfo(prevState => ({
				...prevState,
				faqs: [...prevState.faqs, newFAQ],
			}));
			setNewFAQ({ question: '', answer: '' });
		}
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Question</FormLabel>
				<Input
					variant='withBorder'
					name='question'
					value={newFAQ.question}
					onChange={handleInputChange}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Response</FormLabel>
				<Textarea
					variant='withBorder'
					rows={5}
					name='answer'
					value={newFAQ.answer}
					onChange={handleInputChange}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				<Button variant='secondary' gap='5px' onClick={handleAddFAQ}>
					<IconPlus boxSize='13px' mt='-2px' />
					<Text fontSize='13px'>Add question</Text>
				</Button>
			</Flex>
			<Box gridColumn='span 12'>
				<FAQsTable
					propertyInfo={propertyInfo}
					setPropertyInfo={setPropertyInfo}
				/>
			</Box>
		</Grid>
	);
};

export default FAQsComponent;
