import {
	Button,
	Flex,
	FormLabel,
	Input,
	InputGroup,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import ImageComponent from './ImageComponent';
import { useState } from 'react';
import { IconArrowDown } from '../../../../assets/Icons';

const GalleryComponent = () => {
	const { colorMode } = useColorMode();
	const [uploadedImages, setUploadedImages] = useState([]);
	const [featuredImage, setFeaturedImage] = useState();

	const handleDrop = event => {
		event.preventDefault();
		event.stopPropagation();

		const files = Array.from(event.dataTransfer.files);
		const newImages = files.map(file => URL.createObjectURL(file));

		setUploadedImages(prevImages => [...prevImages, ...newImages]);
	};

	const handleDragOver = event => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleClick = () => {
		document.getElementById('fileInput').click();
	};

	const addImage = event => {
		const files = Array.from(event.target.files);
		const newImages = files.map(file => URL.createObjectURL(file));

		setUploadedImages(prevImages => [...prevImages, ...newImages]);
	};

	const removeImage = url => {
		setUploadedImages(prevImages =>
			prevImages.filter(imageUrl => imageUrl !== url),
		);
	};

	return (
		<Stack gap='20px'>
			<InputGroup display='flex' flexDir='column'>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Add images
					</Text>
				</FormLabel>
				<Flex
					role='button'
					h='100px'
					alignItems='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.100'
					}
					border='1px dashed'
					borderColor={
						colorMode === 'dark' ? 'brand.200' : 'brand.500'
					}
					borderRadius='12px'
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onClick={handleClick}
					cursor='pointer'
					p='10px'
				>
					<Input
						id='fileInput'
						type='file'
						multiple
						accept='image/*'
						style={{ display: 'none' }}
						onChange={addImage}
					/>
					<Text
						fontSize='12px'
						fontWeight='400'
						color={
							colorMode === 'dark'
								? 'transparencyWhite.500'
								: 'transparencyBlack.800'
						}
					>
						Drag and drop images here or click to select
					</Text>
				</Flex>
			</InputGroup>
			<Flex wrap='wrap' gap='15.55px'>
				{uploadedImages.map((image, index) => (
					<ImageComponent
						key={index}
						url={`url(${image})`}
						onRemove={() => removeImage(image)}
					/>
				))}
			</Flex>
			<InputGroup display='flex' flexDir='column' zIndex={100}>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Featured Image
					</Text>
				</FormLabel>
				<Menu>
					<MenuButton
						h='40px'
						w='100%'
						fontWeight='400'
						borderRadius='5px'
						textAlign='start'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.100'
						}
						_hover={{
							bg:
								colorMode === 'dark'
									? 'transparencyWhite.200'
									: 'transparencyBlack.200',
						}}
						_active={{
							border: '0.5px solid',
							borderColor:
								colorMode === 'dark'
									? 'brand.200'
									: 'brand.500',
						}}
						rightIcon={<IconArrowDown boxSize='12px' />}
						as={Button}
						isDisabled={uploadedImages.length === 0}
					>
						<Text
							fontSize='12px'
							fontWeight='400'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.800'
							}
						>
							{uploadedImages.length === 0
								? 'Upload an image'
								: featuredImage}
						</Text>
					</MenuButton>
					<MenuList fontSize='12px' zIndex={10}>
						{uploadedImages.map((elem, i) => (
							<MenuItem
								key={i}
								value={elem}
								onClick={() => setFeaturedImage(elem)}
							>
								{elem}
							</MenuItem>
						))}
					</MenuList>
				</Menu>
			</InputGroup>

			{featuredImage && (
				<ImageComponent
					url={featuredImage}
					onRemove={() => setFeaturedImage('')}
				/>
			)}

			<InputGroup display='flex' flexDir='column'>
				<FormLabel>
					<Text
						variant='colored'
						fontSize='16px'
						fontWeight='700'
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						Featured Video
					</Text>
				</FormLabel>
				<Input />
			</InputGroup>
		</Stack>
	);
};

export default GalleryComponent;
