import { extendTheme } from '@chakra-ui/react';

const config = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
};

const colors = {
	brand: {
		200: 'rgba(28, 245, 161,1)',
		250: 'rgba(28, 245, 161,0.5)',
		500: 'rgba(17, 154, 210,1)',
		550: 'rgba(17, 154, 210, 0.5)',
	},

	light: {
		primary: 'rgb(17, 154, 210)', // main color
	},

	dark: {
		primary: 'rgb(28, 245, 161)', // primary color
	},

	negative: 'rgba(235, 45, 45, 1)',

	transparencyWhite: {
		50: 'rgba(255, 255, 255, 0.0)',
		100: 'rgba(255, 255, 255, 0.1)',
		200: 'rgba(255, 255, 255, 0.2)',
		300: 'rgba(255, 255, 255, 0.3)',
		400: 'rgba(255, 255, 255, 0.4)',
		500: 'rgba(255, 255, 255, 0.5)',
		600: 'rgba(255, 255, 255, 0.6)',
		700: 'rgba(255, 255, 255, 0.7)',
		800: 'rgba(255, 255, 255, 0.8)',
		900: 'rgba(255, 255, 255, 0.9)',
		950: 'rgba(255, 255, 255, 1)',
	},
	transparencyBlack: {
		50: 'rgba(0, 0, 0, 0.05)',
		100: 'rgba(0, 0, 0, 0.1)',
		200: 'rgba(0, 0, 0, 0.2)',
		300: 'rgba(0, 0, 0, 0.3)',
		400: 'rgba(0, 0, 0, 0.4)',
		500: 'rgba(0, 0, 0, 0.5)',
		600: 'rgba(0, 0, 0, 0.6)',
		700: 'rgba(0, 0, 0, 0.7)',
		800: 'rgba(0, 0, 0, 0.8)',
		900: 'rgba(0, 0, 0, 0.9)',
		950: 'rgba(0, 0, 0, 1)',
	},
};

const styles = {
	global: () => ({
		body: {
			height: '100%',
			width: '100%',
			_dark: {
				backgroundAttachment: 'fixed',
				bg: `radial-gradient(circle at left bottom, ${colors.transparencyWhite[100]} 0%, black 50%, transparent), radial-gradient(circle at right, ${colors.transparencyWhite[100]} 0%, #0d0e0f 50%, transparent)`,
				'::-webkit-scrollbar': {
					width: '9px',
					backgroundColor: 'rgba(255, 255, 255, 0.2)',
				},
				'::-webkit-scrollbar-thumb': {
					backgroundColor: 'rgba(255, 255, 255, 0.3)',
					borderRadius: '3px',
				},
			},
			_light: {
				backgroundAttachment: 'fixed',
				bg: `linear-gradient(
					to top left,
					rgba(255, 255, 255, 0.45) 0%,
					rgba(238, 238, 238, 0.78) 26%,
					rgba(248, 248, 248, 1) 59%,
					rgba(255, 255, 255, 0.19) 100%,
					rgba(255, 255, 255, 0.12) 88%,
					rgba(102, 102, 102, 0) 100%
				  )`,

				'::-webkit-scrollbar': {
					width: '9px',
					backgroundColor: 'rgba(0, 0, 0, 0.1)',
				},
				'::-webkit-scrollbar-thumb': {
					backgroundColor: 'rgba(0, 0, 0, 0.2)',
					borderRadius: '3px',
				},
			},
		},
	}),
};

const fonts = {
	heading: 'inter',
	body: 'Plus Jakarta Sans',
	filters: 'Urbanist',
};

const breakpoints = {
	'2xl': '1200px',
};

const components = {
	Button: {
		baseStyle: {
			fontWeight: '400',
			transition: 'filter 0.2s, transform 0.2s',
			borderRadius: '5px',
			fontSize: '13px',
			letterSpacing: 0.02,
		},

		variants: {
			main: {
				fontSize: '13px',
				fontWeight: 'regular',
				_dark: {
					bg: colors.brand[200],
					color: colors.transparencyBlack[800],
					_hover: {
						bg: colors.brand[200],
						filter: 'brightness(1.1)',
					},
					_active: {
						filter: 'brightness(0.8)',
						transform: 'scale(0.98)',
					},
				},

				_light: {
					bg: colors.brand[500],
					color: colors.transparencyWhite[900],
					_hover: {
						bg: colors.brand[500],
						filter: 'brightness(1.1)',
					},
					_active: {
						filter: 'brightness(0.8)',
						transform: 'scale(0.98)',
					},
				},
			},

			secondary: {
				_dark: {
					bg: colors.transparencyWhite[100],

					_hover: {
						bg: colors.transparencyWhite[200],
						filter: 'brightness(1.1)',
					},
					_active: {
						filter: 'brightness(0.8)',
						transform: 'scale(0.98)',
					},
				},
				_light: {
					bg: colors.transparencyWhite[800],
					border: '1px solid',
					borderColor: colors.transparencyBlack[100],

					_hover: {
						bg: 'transparencyWhite.100',
					},
					_active: {
						transform: 'scale(0.98)',
					},
				},
			},

			squared: {
				fontSize: '11px',
				borderRadius: 'none',
				p: '10px',
				transition: 'background-color 0.5s',
				_dark: {
					bg: colors.transparencyWhite[100],

					_hover: {
						bg: colors.transparencyWhite[200],
						filter: 'brightness(1.1)',
					},
					_active: {
						filter: 'brightness(0.8)',
						transform: 'scale(0.98)',
					},
				},
				_light: {
					bg: colors.transparencyBlack[50],

					_hover: {
						bg: 'transparencyBlack.200',
					},
					_active: {
						bg: 'transparencyBlack.100',
					},
				},
			},

			mainNoActive: {
				bg: colors.light.primary,
				color: 'white',
				_hover: {
					bg: colors.light.primary,
					filter: 'brightness(1.1)',
				},
				_dark: {
					bg: colors.dark.primary,
					color: 'black',
					_hover: {
						bg: colors.dark.primary,
						filter: 'brightness(1.1)',
					},
				},
			},

			negative: {
				bg: colors.negative,
				color: 'white',
			},
		},
	},

	Switch: {
		baseStyle: {
			track: {
				bg: colors.transparencyBlack[100],
				_checked: {
					bg: colors.transparencyWhite[200],
				},
			},

			thumb: {
				_checked: {
					bg: colors.transparencyBlack[400],
				},
			},
			_focus: {
				boxShadow: 'none',
				outline: 'none',
				border: 'none',
			},
		},
		variants: {
			secondary: {
				track: {
					bg: colors.transparencyWhite[100],
					_checked: {
						bg: colors.brand[200],
					},
				},

				thumb: {
					bg: colors.transparencyWhite[300],

					_checked: {
						bg: colors.transparencyWhite[950],
					},
				},
			},
		},
	},

	Link: {
		baseStyle: {
			transition: 'filter 0.2s',
			_hover: {
				textDecoration: 'none',
				filter: 'brightness(0.8)',
			},
		},

		variants: {
			isActive: {
				_light: { color: colors.brand[500] },
				_dark: { color: colors.brand[200] },
			},
		},
	},

	FormLabel: {
		variants: {
			propertyLabel: {
				fontWeight: 'bold',
				fontSize: '16px',
				lineHeight: '20px',
				letterSpacing: -0.02,
				color: colors.brand[500],
				_dark: {
					color: colors.brand[200],
				},
			},
		},
	},

	Input: {
		baseStyle: {
			field: {
				fontSize: '13px',
				letterSpacing: -0.02,
				_placeholder: {
					fontSize: '13px',
				},
				_dark: {
					border: 'none',
					bg: 'transparencyWhite.100',
					_focus: {
						border: `1px solid ${colors.brand[200]}`,
						outline: 'none',
						boxShadow: 'none',
					},
					_active: {
						boxShadow: 'none',
					},
					_disabled: {
						border: `1px solid ${colors.transparencyWhite[200]}`,
					},
				},

				_light: {
					color: 'transparencyBlack.800',
					_placeholder: {
						color: 'transparencyBlack.500',
					},
					border: 'none',
					bg: 'rgba(0, 0, 0, 0.05)',
					_focus: {
						border: `1px solid ${colors.brand[500]}`,
						outline: 'none',
						boxShadow: 'none',
					},
					_active: {
						boxShadow: 'none',
					},
					_disabled: {
						border: `1px solid ${colors.transparencyBlack[200]}`,
					},
				},
			},
		},

		variants: {
			error: {
				field: {
					_dark: {
						border: `1px solid ${colors.negative}`,
						_focus: {
							border: `1px solid ${colors.negative}`,
						},
					},
					_light: {
						border: `1px solid ${colors.negative}`,
						_focus: {
							border: `1px solid ${colors.negative}`,
						},
					},
				},
			},
			noStyled: {
				field: {
					_dark: {
						bg: 'none',
						_focus: {
							boxShadow: `none`,
							border: `none`,
						},
					},
					_light: {
						bg: 'none',
						_focus: {
							boxShadow: `none`,
							border: `none`,
						},
					},
				},
			},
			withBorder: {
				field: {
					_dark: {
						border: `0.5px solid ${colors.brand[200]}`,
					},
					_light: {
						border: `0.5px solid ${colors.brand[500]}`,
					},
				},
			},

			search: {
				field: {
					borderRadius: '42px',
					fontWeight: '400',
					fontSize: '14px',
					lineHeight: '100%',

					bg: 'transparencyBlack.100',
					_placeholder: {
						color: 'transparencyBlack.500',
					},
					_dark: {
						bg: 'transparencyWhite.100',
						_placeholder: {
							color: 'transparencyWhite.500',
						},
					},
				},
			},
		},
	},

	Textarea: {
		baseStyle: {
			fontSize: '13px',
			letterSpacing: -0.02,
			_placeholder: {
				fontSize: '13px',
			},
			_dark: {
				border: 'none',
				bg: 'transparencyWhite.100',
				_focus: {
					border: `1px solid ${colors.brand[200]}`,
					outline: 'none',
					boxShadow: 'none',
				},
				_active: {
					boxShadow: 'none',
				},
				_disabled: {
					border: `1px solid ${colors.transparencyWhite[200]}`,
				},
			},

			_light: {
				color: 'transparencyBlack.800',
				_placeholder: {
					color: 'transparencyBlack.500',
				},
				border: 'none',
				bg: 'rgba(0, 0, 0, 0.05)',
				_focus: {
					border: `1px solid ${colors.brand[500]}`,
					outline: 'none',
					boxShadow: 'none',
				},
				_active: {
					boxShadow: 'none',
				},
				_disabled: {
					border: `1px solid ${colors.transparencyBlack[200]}`,
				},
			},
		},

		variants: {
			withBorder: {
				_dark: {
					border: `1px solid ${colors.brand[200]}`,
				},
				_light: {
					border: `1px solid ${colors.brand[500]}`,
				},
			},
		},
	},

	Text: {
		baseStyle: {
			fontSize: '15',
		},
		variants: {
			colored: {
				color: colors.brand[500],
				_dark: {
					color: colors.brand[200],
				},
			},
			smallColored: {
				fontSize: '12',
				fontWeight: '400',
				lineHeight: '150%',
				color: colors.light.primary,
				_dark: {
					color: colors.dark.primary,
				},
			},
			gray: {
				color: colors.transparencyBlack[600],
				_dark: {
					color: colors.transparencyWhite[500],
				},
			},
			small: {
				fontSize: '10',
				fontWeight: '400',
				color: 'transparencyBlack.500',
				_dark: {
					color: 'transparencyWhite.400',
				},
			},
			inverse: {
				fontSize: '16',
				lineHeight: '140%',
				color: 'transparencyBlack.800',
				fontWeight: '600',
				_dark: {
					color: 'transparencyBlack.800',
				},
			},
		},
	},

	Card: {
		baseStyle: {
			container: {
				bg: 'transparencyWhite.950',
				border: 'none',
				boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
				_dark: {
					bg: 'transparencyWhite.100',
					boxShadow: 'none',
				},
			},
			body: {
				borderRadius: '6px',
			},
		},
	},

	Radio: {
		baseStyle: {
			control: {
				display: 'none',
			},
			container: {
				px: '15px',
				py: '10px',

				bg: colors.transparencyBlack[100],
				textAlign: 'center',
				transition: 'background-color 0.5s, color 0.5s',
				_hover: {
					bg: colors.transparencyBlack[200],
				},
				_checked: {
					bg: colors.brand[500],
					color: 'white',
				},
				_dark: {
					bg: colors.transparencyWhite[100],
					_hover: {
						bg: colors.transparencyWhite[200],
					},
					_checked: {
						bg: colors.brand[200],
						color: 'black',
					},
				},
			},
			label: {
				m: 0,
			},
		},

		variants: {
			leftRounded: {
				container: {
					borderRadius: '5px 0 0 5px',
				},
			},

			rightRounded: {
				container: {
					borderRadius: '0 5px 5px 0',
				},
			},
		},
	},

	Tabs: {
		baseStyle: {
			tab: {
				_dark: {
					color: 'transparencyWhite.500',
				},
				_light: {
					color: 'transparencyBlack.500',
				},
				_selected: {
					color: colors.brand[500],
					_dark: {
						color: 'white',
						fontWeight: 'bold',
						borderColor: colors.brand[200],
					},
					_light: {
						color: 'transparencyBlack.700',
						fontWeight: 'bold',
						borderColor: colors.brand[500],
					},
				},
			},
		},
	},

	Accordion: {
		baseStyle: {
			container: {
				mb: '15px',
				borderRadius: '12px',
				bg: colors.transparencyWhite[600],
				_dark: {
					bg: colors.transparencyWhite[100],
				},
			},
			button: {
				fontSize: '20px',
				color: colors.brand[500],
				borderRadius: '15px',

				_dark: {
					color: colors.brand[200],
				},
				_hover: {
					bg: 'none',
				},
			},
			icon: {
				bg: colors.transparencyBlack[100],
				borderRadius: '50%',
				boxSize: '40px',
				p: '5px',
				_dark: {
					bg: colors.transparencyWhite[100],
				},
			},
		},
	},
	Menu: {
		baseStyle: {
			list: {
				bg: colors.transparencyWhite[100],
				backdropFilter: 'blur(80px)',

				_dark: {
					bg: colors.transparencyWhite[100],
					backdropFilter: 'blur(80px)',
				},
			},
			item: {
				bg: 'transparent',
				transition: 'background-color 0.3s',
				_hover: {
					bg: colors.transparencyBlack[100],
				},

				_dark: {
					bg: 'transparent',
					_hover: {
						bg: colors.transparencyWhite[100],
					},
				},
			},
			button: {
				bg: colors.transparencyBlack[100],
				py: 0.5,
				px: 2,
				_hover: {
					bg: colors.transparencyBlack[200],
				},

				_active: {
					bg: colors.transparencyBlack[200],
				},
				_dark: {
					bg: colors.transparencyWhite[100],
					_hover: {
						bg: colors.transparencyWhite[200],
					},
					_active: {
						bg: colors.transparencyWhite[200],
					},
				},
			},
		},

		variants: {
			settings: {
				list: {
					_light: {
						bg: colors.transparencyBlack[100],
					},
					_dark: {
						bg: colors.transparencyWhite[100],
					},
				},
			},

			withoutHover: {
				item: {
					_light: {
						_hover: {
							bg: 'transparent',
						},
					},
					_dark: {
						_hover: {
							bg: 'transparent',
						},
					},
				},
			},

			withoutBg: {
				list: {
					bg: colors.transparencyWhite[600],
					backdropFilter: 'blur(25px)',
					_dark: {
						bg: colors.transparencyWhite[100],
					},
				},
				item: {
					bg: 'none',
					_hover: {
						bg: 'none',
						color: 'black',
					},
					_dark: {
						bg: 'none',
						_hover: {
							bg: 'none',
							color: 'white',
						},
					},
				},
				button: {
					bg: colors.light.primary,
					color: colors.light.primary,
					py: 0,
					px: 0,
					_hover: {
						bg: 'none',
						color: 'none',
					},
					_active: {
						bg: 'none',
						color: 'none',
					},
					_dark: {
						bg: 'none',
						color: colors.dark.primary,
						_hover: {
							bg: 'none',
							color: 'none',
						},
						_active: {
							bg: 'none',
							color: 'none',
						},
					},
				},
			},
			simple: {
				button: {
					_light: {
						bg: 'none',
						_active: {
							bg: 'none',
						},
						_hover: {
							bg: 'none',
						},
					},
					_dark: {
						bg: 'none',
						_active: {
							bg: 'none',
						},
						_hover: {
							bg: 'none',
						},
					},
				},
			},
			filters: {
				list: {
					bg: 'transparencyWhite.900',
					_dark: {
						bg: 'transparencyBlack.800',
					},
				},
			},
		},
	},

	Modal: {
		baseStyle: {
			overlay: {
				backdropFilter: 'blur(27.2px) brightness(1.2)',
				background: colors.transparencyBlack[300],
				_dark: {
					background: colors.transparencyBlack[600],
				},
			},
			dialog: {
				bg: 'transparent',
				boxShadow: 'none',
			},
		},
	},

	Progress: {
		baseStyle: {
			track: {
				bg: colors.transparencyBlack[100],
				borderRadius: '50px',
				_dark: {
					bg: colors.transparencyWhite[900],
				},
			},
			filledTrack: {
				borderRadius: '50px',
				bg: colors.light.primary,
				_dark: {
					bg: colors.dark.primary,
				},
			},
		},
	},
	Divider: {
		baseStyle: {
			borderColor: 'transparencyBlack.100',
			_dark: {
				borderColor: 'transparencyWhite.200',
			},
		},
	},
	Tooltip: {
		baseStyle: {
			bg: 'transparencyBlack.100',
			backdropFilter: 'blur(20px)',
			color: 'black',
			fontWeight: '400',
			fontSize: '12px',
			borderRadius: '8px',
			p: '7px 10px',

			_dark: {
				bg: 'transparencyWhite.100',
				color: 'white',
			},
		},
	},
};

export const theme = extendTheme({
	config,
	styles,
	components,
	fonts,
	colors,
	breakpoints,
});
