import { useState } from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import GridCenterContainer from '../../../components/GridCenterContainer';
import BoxComponent from '../../../components/BoxComponent';
import InputComponent from '../../../components/InputComponent';
import { validatePasswordConfirm } from '../../../utils/validations/authValidations';
import { changePassword } from '../../../services/userService';
import { useTranslation } from 'react-i18next';

/**
*
	@name ChangePasswordResetPassword
	@description Component that change the password. It has to be written twice and match
	@returns {JS.Element}
	@author Edunknown | Unknown Gravity - All-in-one Blockchain Company
	@Version 1.0.0
*/

const initialState = {
	oldPassword: '',
	newPassword: '',
	passwordConfirm: '',
};

const ChangePasswordResetPassword = ({ nextStep, userInfo }) => {
	const { t } = useTranslation();
	const [password, setPassword] = useState(initialState);
	const [errorMsg, setErrorMsg] = useState();

	const handleChange = event => {
		const { name, value } = event.target;
		if (errorMsg) {
			setErrorMsg(undefined);
		}
		setPassword({ ...password, [name]: value });
	};

	const onSubmit = () => {
		const error = validatePasswordConfirm(
			password.newPassword,
			password.passwordConfirm,
		);
		if (error) {
			setErrorMsg(error);
		} else {
			changePassword(userInfo?.email, password).then(response => {
				if (response.success) {
					nextStep();
				} else {
					setErrorMsg(response.message);
				}
			});
		}
	};

	return (
		<GridCenterContainer>
			<BoxComponent
				w={{ base: null, sm: '400px' }}
				headerMsg={t('changePasswordResetPassword.headerMsg')}
				explainMsg={t('changePasswordResetPassword.explainMsg')}
				containerGap='30px'
			>
				<Stack gap='20px'>
					<InputComponent
						label={t(
							'changePasswordResetPassword.inputNewPassword.label',
						)}
						name='newPassword'
						placeholder={t(
							'changePasswordResetPassword.inputNewPassword.placeholder',
						)}
						value={password.newPassword}
						onChange={handleChange}
						errorMsg={errorMsg}
						type={'password'}
					/>
					<InputComponent
						label={t(
							'changePasswordResetPassword.inputPasswordConfirm.label',
						)}
						name='passwordConfirm'
						placeholder={t(
							'changePasswordResetPassword.inputPasswordConfirm.placeholder',
						)}
						value={password.passwordConfirm}
						onChange={handleChange}
						errorMsg={errorMsg}
						type={'password'}
					/>
					<Button
						variant='main'
						w='100%'
						mt='15px'
						onClick={onSubmit}
						isDisabled={password.newPassword === ''}
					>
						{t('changePasswordResetPassword.button')}
					</Button>
					{errorMsg && (
						<Text
							fontWeight='700'
							color='negative'
							textAlign='center'
						>
							{errorMsg}
						</Text>
					)}
				</Stack>
			</BoxComponent>
		</GridCenterContainer>
	);
};

export default ChangePasswordResetPassword;
