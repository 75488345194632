import { createIcon } from '@chakra-ui/react';

const IconStar = createIcon({
	displayName: 'IconStar',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.8125 1.38684L9.9755 5.99462L14.8125 6.73805L11.3125 10.3227L12.1385 15.3868L7.8125 12.9946L3.4865 15.3868L4.3125 10.3227L0.8125 6.73805L5.6495 5.99462L7.8125 1.38684Z'
			stroke='#FFD600'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconStar;
