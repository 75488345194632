import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerComponent.css';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { IconArrowDown } from '../../assets/Icons';
import { formatDateLongMonth } from '../../utils/utils';

const DatePickerComponent = ({ name, setInfo, stringData }) => {
	const [selectedDate, setSelectedDate] = useState('');

	const handleDateChange = date => {
		setSelectedDate(date);
		const dateLongMonth = date ? formatDateLongMonth(date) : '';
		setInfo(prevInfo => ({
			...prevInfo,
			data: {
				...prevInfo.data,
				[name]: stringData ? dateLongMonth : date.toISOString(),
			},
		}));
	};

	const formattedDate = selectedDate ? formatDateLongMonth(selectedDate) : '';

	return (
		<DatePicker
			selected={selectedDate}
			onChange={handleDateChange}
			customInput={
				<InputGroup>
					<InputRightElement>
						<IconArrowDown />
					</InputRightElement>
					<Input
						name={name}
						placeholder='Select date'
						value={formattedDate}
						readOnly
						cursor='pointer'
					/>
				</InputGroup>
			}
			wrapperClassName='datepicker-wrapper'
			calendarClassName='datepicker-calendar'
			inputClassName='datepicker-input'
			showPopperArrow={false}
			dateFormat='MM/dd/yyyy'
		/>
	);
};

export default DatePickerComponent;
