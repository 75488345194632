import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import MenuSelectComponent from '../../../../components/MenuSelectComponent';

const FinancialDetailsComponent = () => {
	const { colorMode } = useColorMode();

	const purchasedOrPledgeOptions = ['Purchase', 'Pledge'];
	const distributionFrequencyOptions = [
		'Monthly',
		'Quarterly',
		'Annually',
		'Date of sale',
	];

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 4'>
				<FormLabel variant='propertyLabel'>
					Total to be collected
				</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>$</Text>
				</Flex>
			</FormControl>

			<FormControl gridColumn='span 4'>
				<FormLabel variant='propertyLabel'>Price per token</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>$</Text>
				</Flex>
			</FormControl>

			<FormControl gridColumn='span 4'>
				<FormLabel variant='propertyLabel'>
					Potential monthly payment
				</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>$</Text>
				</Flex>
			</FormControl>
			<FormControl gridColumn='span 3'>
				<FormLabel variant='propertyLabel'>Potential ROI</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>%</Text>
				</Flex>
			</FormControl>
			<FormControl gridColumn='span 3'>
				<FormLabel variant='propertyLabel'>Appreciation</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>%</Text>
				</Flex>
			</FormControl>
			<FormControl gridColumn='span 3'>
				<FormLabel variant='propertyLabel'>
					Yearly investment return
				</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>%</Text>
				</Flex>
			</FormControl>
			<FormControl gridColumn='span 3'>
				<FormLabel variant='propertyLabel'>Min ticket price</FormLabel>
				<Flex align='center' gap='10px'>
					<Input />
					<Text variant='colored'>$</Text>
				</Flex>
			</FormControl>
			<FormControl gridColumn='span 4'>
				<FormLabel variant='propertyLabel'>
					Distribution frequency
				</FormLabel>
				<MenuSelectComponent options={distributionFrequencyOptions} />
			</FormControl>
			<FormControl gridColumn='span 4'>
				<FormLabel variant='propertyLabel'>Payment methods</FormLabel>
				<Input />
			</FormControl>
			<FormControl gridColumn='span 4'>
				<FormLabel variant='propertyLabel'>Purchase/Pledge</FormLabel>
				<MenuSelectComponent options={purchasedOrPledgeOptions} />
			</FormControl>

			<Box gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Attach documents</FormLabel>
				<Flex
					h='88px'
					border='0.5px dashed'
					borderColor={
						colorMode === 'dark' ? 'brand.200' : 'brand.500'
					}
					borderRadius='12px'
					bg='transparencyWhite.100'
				>
					<Text>Drop documents or click to upload</Text>
				</Flex>
			</Box>
		</Grid>
	);
};

export default FinancialDetailsComponent;
