import { IconView, IconUnview } from '../assets/Icons';
import {
	FormControl,
	FormLabel,
	Text,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';

const InputType = {
	Text: 'text',
	Password: 'password',
};

/**
*
    @name InputComponent
    @description An styled input component, it has a focus style, and another
				 style for when we have an error message in the website.
    @returns {JS.Element} InputComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const InputComponent = ({
	label,
	value,
	errorMsg = null,
	onChange,
	name,
	placeholder,
	type,
	autoFocus,
	gridColumn,
	isModal,
	enterAction,
	variant,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassWord = () => {
		setShowPassword(!showPassword);
	};

	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			event.preventDefault();
			enterAction();
		}
	};

	return (
		<FormControl gridColumn={gridColumn}>
			<FormLabel mb='9px'>
				<Text
					lineHeight='100%'
					fontSize='14px'
					fontWeight='700'
					variant={isModal ? 'colored' : 'none'}
				>
					{label}
				</Text>
			</FormLabel>
			<InputGroup>
				<Input
					variant={variant}
					w='100%'
					type={
						type === InputType.Password &&
						(showPassword ? 'text' : 'password')
					}
					name={name}
					fontSize='12px'
					placeholder={placeholder}
					_placeholder={{
						fontWeight: '400',

						color: 'transparencyWhite.500',
					}}
					value={value}
					onChange={onChange}
					outline={errorMsg ? '1px solid red' : 'none'}
					autoFocus={autoFocus}
					onKeyDown={enterAction && handleKeyPress}
					h='40px'
				/>
				{type === InputType.Password && (
					<InputRightElement>
						<IconButton
							icon={showPassword ? <IconView /> : <IconUnview />}
							onClick={toggleShowPassWord}
							bg='none'
							_hover={{ bg: 'none' }}
						/>
					</InputRightElement>
				)}
			</InputGroup>
		</FormControl>
	);
};

export default InputComponent;
