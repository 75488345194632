import { useEffect, useState } from 'react';
import GridCenterContainer from '../../../components/GridCenterContainer';
import {
	Button,
	HStack,
	Link,
	PinInput,
	PinInputField,
	Stack,
	Text,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';
import { resetPassword, validateCode } from '../../../services/userService';
import { useTranslation } from 'react-i18next';

/**
*
    @name ValidateEmailResetPassword
	@description Component that verify the code is written is the same than the generated one
	@returns {JS.Element} ValidateEmailResetPassword.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const ValidateEmailResetPassword = ({ nextStep, userInfo, type }) => {
	const { t } = useTranslation();
	const email = userInfo?.dataValues?.email || userInfo?.email;
	const theme = useTheme();
	const { colorMode } = useColorMode();
	const [code, setCode] = useState('');
	const [errorMsg, setErrorMsg] = useState();
	const [activeResendCode, setActiveResendCode] = useState(false);
	const [timeActiveResendCode, setTimeActiveResendCode] = useState(60);

	const pinStyles = {
		border: 'solid 2px',
		borderColor: 'transparencyBlack.200',
		borderRadius: '12px',
		fontSize: '32px',
		fontWeight: 'bold',
		_dark: {
			borderColor: 'transparencyWhite.300',
		},
		_focus: {
			borderColor: theme.colors.brand[500],
			boxShadow: `0 0 4px ${theme.colors.brand[500]}`,

			_dark: {
				borderColor: theme.colors.brand[200],
				boxShadow: `0 0 4px ${theme.colors.brand[200]}`,
			},
		},
	};

	useEffect(() => {
		if (!activeResendCode) {
			if (timeActiveResendCode <= 0) {
				setActiveResendCode(true);
				setTimeActiveResendCode(60);
				return;
			}

			const intervalId = setInterval(() => {
				setTimeActiveResendCode(prevTime => prevTime - 1);
			}, 1000);

			return () => clearInterval(intervalId);
		}
	}, [timeActiveResendCode, activeResendCode]);

	const handleChange = event => {
		setCode(event);
	};

	const onSubmit = () => {
		validateCode(code, type).then(response => {
			if (response.success) {
				nextStep();
			} else {
				setErrorMsg(response.message);
			}
		});
	};

	const resendEmail = () => {
		resetPassword(email)
			.then(response => {
				if (!response.success) {
					setErrorMsg(response.message);
				} else {
					setActiveResendCode(false);
				}
			})
			.catch(() => {
				setErrorMsg(
					t('validateEmailResetPassword.errorMsg.badRequest'),
				);
			});
	};

	return (
		<GridCenterContainer textAlign='center'>
			<Text fontSize='20px' fontWeight='800' mb='3px'>
				{t('validateEmailResetPassword.title')}
			</Text>
			<Text fontSize='14px' fontWeight='400'>
				{`${t('validateEmailResetPassword.subtitle')} ${email}`}
			</Text>
			<Text fontSize='14px' fontWeight='400'>
				{t('validateEmailResetPassword.message')}
			</Text>

			<Stack
				w={{ base: null, sm: '400px' }}
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.200'
						: 'transparencyBlack.100'
				}
				justifyContent='center'
				align='center'
				borderRadius='10px'
				p='30px'
				mt='15px'
			>
				<HStack gap={5} w='100%' placeContent='center' mb={6}>
					<PinInput
						size='lg'
						autoFocus='true'
						placeholder=''
						onChange={handleChange}
					>
						<PinInputField {...pinStyles} />
						<PinInputField {...pinStyles} />
						<PinInputField {...pinStyles} />
						<PinInputField {...pinStyles} />
						<PinInputField {...pinStyles} />
					</PinInput>
				</HStack>
				<Button
					variant='main'
					maxW='150px'
					onClick={onSubmit}
					isDisabled={!code}
				>
					{t('validateEmailResetPassword.button')}
				</Button>
				<Text
					variant='gray'
					textAlign='center'
					fontWeight={'400'}
					mt={6}
				>
					{t('validateEmailResetPassword.question')}
					{activeResendCode ? (
						<Link variant='isActive' onClick={resendEmail}>
							{t('validateEmailResetPassword.link')}
						</Link>
					) : (
						<Link variant='isActive' ms={5}>
							{timeActiveResendCode} s
						</Link>
					)}
				</Text>
				{errorMsg && (
					<Text fontWeight='700' color='negative' textAlign='center'>
						{errorMsg}
					</Text>
				)}
			</Stack>
		</GridCenterContainer>
	);
};

export default ValidateEmailResetPassword;
