import { Flex, Grid, GridItem } from '@chakra-ui/react';
import SortFilterUnit from './SortFilterUnit';
import {
	alphabeticalFilters,
	statusFilters,
	typesFilters,
	oldFilters,
	priceFilters,
} from '../../../../data/optionsData.js';
import {
	IconAZ,
	IconBuilding,
	IconNewestToOldest,
	IconPriceHighToLow,
	IconTrade,
} from '../../../../assets/Icons/index.js';

const SortFiltersComponent = ({ isTablet, isMobile }) => {
	const gridColSpan = () => {
		if (isMobile) {
			return 4;
		} else if (isTablet && !isMobile) {
			return 2;
		} else {
			return 1;
		}
	};
	const lastFilterW = () => {
		if (isMobile) {
			return '100%';
		} else if (isTablet && !isMobile) {
			return '50%';
		} else {
			return 'fit-content';
		}
	};

	return (
		<Grid
			gap='10px'
			placeContent={isTablet ? 'center' : 'flex-end'}
			gridAutoFlow={isTablet ? 'row' : 'column'}
			templateColumns={isTablet ? 'repeat(4, minmax(0, 1fr))' : 'auto'}
		>
			<GridItem colSpan={gridColSpan()} w='100%'>
				<SortFilterUnit
					filtersList={alphabeticalFilters}
					icon={IconAZ}
					w={isTablet ? '100%' : 'fit-content'}
					isTablet={isTablet}
				/>
			</GridItem>
			<GridItem colSpan={gridColSpan()} w='100%'>
				<SortFilterUnit
					filtersList={statusFilters}
					icon={IconTrade}
					w={isTablet ? '100%' : 'fit-content'}
					isTablet={isTablet}
				/>
			</GridItem>
			<GridItem colSpan={gridColSpan()} w='100%'>
				<SortFilterUnit
					filtersList={typesFilters}
					icon={IconBuilding}
					w={isTablet ? '100%' : 'fit-content'}
					isTablet={isTablet}
				/>
			</GridItem>
			<GridItem colSpan={gridColSpan()} w='100%'>
				<SortFilterUnit
					filtersList={priceFilters}
					icon={IconNewestToOldest}
					w={isTablet ? '100%' : 'fit-content'}
					isTablet={isTablet}
				/>
			</GridItem>
			<GridItem
				colSpan={isTablet ? 4 : 1}
				w='100%'
				justifyContent='center'
			>
				<Flex justifyContent='center'>
					<SortFilterUnit
						filtersList={oldFilters}
						icon={IconPriceHighToLow}
						w={lastFilterW()}
						isTablet={isTablet}
					/>
				</Flex>
			</GridItem>
		</Grid>
	);
};

export default SortFiltersComponent;
