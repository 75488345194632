import { createIcon } from '@chakra-ui/react';

const IconDotsEmpty = createIcon({
	displayName: 'IconDotsEmpty',
	viewBox: '0 0 27 24',
	path: (
		<>
			<path
				d='M13.4952 14.3341C14.727 14.3341 15.7396 13.3215 15.7396 12.3007C15.7396 11.2798 14.727 10.2673 13.4952 10.2673C12.2633 10.2673 11.2508 11.2798 11.2508 12.3007C11.2508 13.3215 12.2633 14.3341 13.4952 14.3341Z'
				stroke='currentColor'
				fill='none'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.3841 14.3341C25.6159 14.3341 26.6285 13.3215 26.6285 12.3007C26.6285 11.2798 25.6159 10.2673 24.3841 10.2673C23.1522 10.2673 22.1396 11.2798 22.1396 12.3007C22.1396 13.3215 23.1522 14.3341 24.3841 14.3341Z'
				stroke='currentColor'
				fill='none'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2.60633 14.3341C3.83811 14.3341 4.85078 13.3215 4.85078 12.3007C4.85078 11.2798 3.83811 10.2673 2.60633 10.2673C1.37455 10.2673 0.361878 11.2798 0.361878 12.3007C0.361878 13.3215 1.37455 14.3341 2.60633 14.3341Z'
				stroke='currentColor'
				fill='none'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</>
	),
});

export default IconDotsEmpty;
