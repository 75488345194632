import { Flex } from '@chakra-ui/react';
import { IconClose } from '../../../../assets/Icons';

const ImageComponent = ({ url, onRemove }) => {
	return (
		<Flex
			boxSize='100px'
			borderRadius='5px'
			backgroundImage={url}
			backgroundSize='cover'
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
			justify='end'
			p='5px'
		>
			<Flex
				backgroundColor='transparencyWhite.800'
				boxSize='15px'
				borderRadius='50%'
				align='center'
				justify='center'
				cursor='pointer'
				onClick={onRemove}
			>
				<IconClose boxSize='9px' color='transparencyBlack.800' />
			</Flex>
		</Flex>
	);
};

export default ImageComponent;
