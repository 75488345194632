import { createIcon } from '@chakra-ui/react';

const IconStar2 = createIcon({
	displayName: 'IconStar2',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.875 1.38684L10.038 5.99462L14.875 6.73805L11.375 10.3227L12.201 15.3868L7.875 12.9946L3.549 15.3868L4.375 10.3227L0.875 6.73805L5.712 5.99462L7.875 1.38684Z'
			fill='#FFD600'
			stroke='#FFD600'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconStar2;
