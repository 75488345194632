import { useState } from 'react';
import ModalComponent from '../../../../components/ModalComponent';
import WalletComponent from './WalletComponent';
import SucessfulComponent from '../../../../components/SuccessfulComponent';
import { Box, Flex } from '@chakra-ui/react';

const ClaimExperience = ({ closeModal, isOpen }) => {
	const [step, setStep] = useState(1);

	return (
		<>
			<ModalComponent
				isOpen={isOpen}
				onClose={closeModal}
				modalCross={false}
			>
				<Flex align='center' justifyContent='center'>
					{step === 1 ? (
						<WalletComponent
							setStep={setStep}
							closeModal={closeModal}
						/>
					) : (
						<Box w='320px'>
							<SucessfulComponent
								w='335px'
								h='305px'
								text='Congratulations. You have claimed all your winnings.'
								buttonTitle='Back to dashboard'
								modal={true}
								p='30px'
							/>
						</Box>
					)}
				</Flex>
			</ModalComponent>
		</>
	);
};

export default ClaimExperience;
