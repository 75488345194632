import { Flex, Stack, Text, useColorMode } from '@chakra-ui/react';
import SettingSelectorComponent from './SettingSelectorComponent';
import { languages } from '../../../data/languages';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { updateUser } from '../../../redux/states/user';
import { updateUserSettings } from '../../../services/userService';
import { currencies } from '../../../data/currencies';
import { colorModes } from '../../../data/colorModes';
import { useTranslation } from 'react-i18next';

const AppSettingsComponent = ({ userData }) => {
	const { t } = useTranslation();
	const { setColorMode } = useColorMode();
	const dispatch = useDispatch();

	const languageOptions = languages;
	const currencyOptions = currencies;
	const appearanceOptions = colorModes;

	const [selectedSettings, setSelectedSettings] = useState(userData);
	const selectedLanguage = languages.find(obj => {
		return obj.value === selectedSettings.language;
	});
	const selectedCurrency = currencies.find(obj => {
		return obj.value === selectedSettings.currency;
	});
	const selectedAppareance = colorModes.find(obj => {
		return obj.value === selectedSettings.appearance;
	});

	const handleOnSelect = async e => {
		const { name, value } = e.target;
		setSelectedSettings({ ...selectedSettings, [name]: value });
		name === 'appearance' && setColorMode(value);
		await updateUserSettings({ [name]: value });
		dispatch(updateUser({ [name]: value }));
	};

	return (
		<Stack pt='15px' pb='10px' gap='35px'>
			<Text
				variant='colored'
				textAlign='center'
				fontSize='24px'
				fontWeight='bold'
				letterSpacing={-0.02}
				lineHeight='20px'
			>
				{t('settings.appSettings.title')}
			</Text>
			<Stack gap='35px'>
				<Flex>
					<SettingSelectorComponent
						label={t('settings.appSettings.language')}
						options={languageOptions}
						onSelect={handleOnSelect}
						selectedOption={selectedLanguage}
						name='language'
					/>
					<SettingSelectorComponent
						label={t('settings.appSettings.currency')}
						options={currencyOptions}
						onSelect={handleOnSelect}
						selectedOption={selectedCurrency}
						name='currency'
					/>
					<SettingSelectorComponent
						label={t('settings.appSettings.appearance')}
						options={appearanceOptions}
						selectedOption={selectedAppareance}
						onSelect={handleOnSelect}
						name='appearance'
					/>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default AppSettingsComponent;
