import { createIcon } from '@chakra-ui/react';

const IconListForSale = createIcon({
	displayName: 'IconListForSale',
	viewBox: '0 0 15 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M2.49545 1.76111L1.40345 3.21711C1.18716 3.50549 1.07901 3.64969 1.08152 3.77038C1.0837 3.87542 1.13295 3.97392 1.21567 4.03868C1.31072 4.11311 1.49096 4.11311 1.85145 4.11311H12.2114C12.5719 4.11311 12.7522 4.11311 12.8472 4.03868C12.9299 3.97392 12.9792 3.87542 12.9814 3.77038C12.9839 3.64969 12.8757 3.50549 12.6594 3.21711L11.5674 1.76111M2.49545 1.76111C2.61864 1.59684 2.68024 1.51471 2.75831 1.45547C2.82746 1.40301 2.90576 1.36386 2.98922 1.34002C3.08345 1.31311 3.18611 1.31311 3.39145 1.31311H10.6714C10.8768 1.31311 10.9794 1.31311 11.0737 1.34002C11.1571 1.36386 11.2354 1.40301 11.3046 1.45547C11.3826 1.51471 11.4442 1.59684 11.5674 1.76111M2.49545 1.76111L1.17945 3.51578C1.01322 3.73741 0.930112 3.84822 0.871094 3.97026C0.818725 4.07854 0.78053 4.19313 0.757452 4.31118C0.731445 4.44422 0.731445 4.58274 0.731445 4.85978L0.731445 13.0731C0.731445 13.8572 0.731445 14.2492 0.884036 14.5487C1.01826 14.8121 1.23243 15.0263 1.49586 15.1605C1.79533 15.3131 2.18737 15.3131 2.97145 15.3131L11.0914 15.3131C11.8755 15.3131 12.2676 15.3131 12.567 15.1605C12.8305 15.0263 13.0446 14.8121 13.1789 14.5487C13.3314 14.2492 13.3314 13.8572 13.3314 13.0731V4.85978C13.3314 4.58274 13.3314 4.44422 13.3054 4.31118C13.2824 4.19313 13.2442 4.07854 13.1918 3.97026C13.1328 3.84822 13.0497 3.73741 12.8834 3.51578L11.5674 1.76111M9.83145 6.91311C9.83145 7.65572 9.53645 8.36791 9.01134 8.89301C8.48624 9.41811 7.77405 9.71311 7.03145 9.71311C6.28884 9.71311 5.57665 9.41811 5.05155 8.89301C4.52644 8.36791 4.23145 7.65572 4.23145 6.91311'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconListForSale;
