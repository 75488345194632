import { Card, CardBody } from '@chakra-ui/react';
import SliderPropertyCard from './SliderPropertyCard/SliderPropertyCard';
import { SpainCircle } from '../../../assets/countries';
import InfoPropertyCardDiscovery from './InfoPropertyCardDiscovery';
import { propertyPercentage } from '../../../utils/utils';

/**
*
    @name PropertyCard
    @description It is the component that shows the photos and the information of the property in a Card format.
    @returns {JS.Element} SliderPropertyCard.js
    @Version 1.0.0
*/
const PropertyCardDiscovery = ({ project, dash, maxW = '312px', h }) => {
	return (
		<>
			{project && (
				<Card h={h} maxW={maxW} borderRadius='12px' cursor='pointer'>
					<CardBody p='0' w='100%'>
						<SliderPropertyCard project={project} dash={dash} />
						<InfoPropertyCardDiscovery
							project={project}
							percentage={propertyPercentage(project)}
							icon={SpainCircle}
							dash={dash}
						/>
					</CardBody>
				</Card>
			)}
		</>
	);
};

export default PropertyCardDiscovery;
