import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import SmallBoxBigNumberComponent from './SmallBoxBigNumberComponent';

const MarketplaceTransactionsComponent = ({ isTablet }) => {
	return (
		<Stack w='100%' h='100%' minH='150px' p='15px 25px' gap='10px'>
			<Text
				fontSize='16px'
				fontWeight='700'
				letterSpacing={0.02}
				lineHeight='12px'
			>
				Total Marketplace transactions
			</Text>
			<SimpleGrid columns={3} spacing='20px' w='100%' h='100%'>
				<SmallBoxBigNumberComponent
					bigText={15}
					smallText='Buy'
					isTablet={isTablet}
				/>
				<SmallBoxBigNumberComponent
					bigText={24}
					smallText='For Sale'
					isTablet={isTablet}
				/>
				<SmallBoxBigNumberComponent
					bigText={12}
					smallText='Exchange'
					isTablet={isTablet}
				/>
			</SimpleGrid>
		</Stack>
	);
};
export default MarketplaceTransactionsComponent;
