import { Flex, Text } from '@chakra-ui/react';
import useCountdownTimer from '../../../../hooks/useCountdownTimer';

import BoxCountdownUnitComponent from './BoxCountdownUnitComponent';

const Countdown = ({ endDate }) => {
	const { days, hours, minutes, seconds } = useCountdownTimer(endDate);

	return (
		<>
			<Flex gap='5px'>
				<BoxCountdownUnitComponent>{days}d</BoxCountdownUnitComponent>

				<Text
					py='5px'
					fontWeight='600'
					fontSize='14px'
					lineHeight='150%'
				>
					:
				</Text>

				<BoxCountdownUnitComponent>{hours}h</BoxCountdownUnitComponent>

				<Text
					py='5px'
					fontWeight='600'
					fontSize='14px'
					lineHeight='150%'
				>
					:
				</Text>

				<BoxCountdownUnitComponent>
					{minutes}m
				</BoxCountdownUnitComponent>

				<Text
					py='5px'
					fontWeight='600'
					fontSize='14px'
					lineHeight='150%'
				>
					:
				</Text>

				<BoxCountdownUnitComponent>
					{seconds}s
				</BoxCountdownUnitComponent>
			</Flex>
		</>
	);
};

export default Countdown;
