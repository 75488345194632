import { createIcon } from '@chakra-ui/react';

const DollarIcon = createIcon({
	displayName: 'DollarIcon',
	viewBox: '0 0 16 16',

	path: (
		<path
			d='M8.125 1.51129V13.5113M10.625 3.69311H6.875C6.41087 3.69311 5.96575 3.89425 5.63756 4.25227C5.30937 4.61029 5.125 5.09588 5.125 5.6022C5.125 6.10852 5.30937 6.59411 5.63756 6.95213C5.96575 7.31016 6.41087 7.51129 6.875 7.51129H9.375C9.83913 7.51129 10.2842 7.71243 10.6124 8.07045C10.9406 8.42848 11.125 8.91406 11.125 9.42038C11.125 9.92671 10.9406 10.4123 10.6124 10.7703C10.2842 11.1283 9.83913 11.3295 9.375 11.3295H5.125'
			stroke='currentColor'
			strokeWidth='1.5'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default DollarIcon;
