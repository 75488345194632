import { createIcon } from '@chakra-ui/react';

const IconCheck = createIcon({
	displayName: 'IconCheck',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M4.6 7.99829L6.7 10.0983L10.9 5.89829M14.75 7.99829C14.75 11.8643 11.616 14.9983 7.75 14.9983C3.88401 14.9983 0.75 11.8643 0.75 7.99829C0.75 4.1323 3.88401 0.998291 7.75 0.998291C11.616 0.998291 14.75 4.1323 14.75 7.99829Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconCheck;
