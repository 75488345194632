import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

export const login = async credentials => {
	try {
		const token = encodeToken({
			username: credentials?.username,
			password: credentials?.password,
		});

		const response = await axios.post('/login', {
			token,
		});
		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data?.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong during login.',
			};
		}
	}
};

export const me = async () => {
	try {
		const response = await axios.get('/me');
		return { success: true, data: response.data.data };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const logout = async () => {
	try {
		const response = await axios.delete('/logout');

		return { success: true, data: response.data.data };
	} catch (error) {
		if (error.response) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};
