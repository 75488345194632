import { createIcon } from '@chakra-ui/react';

const IconBathroom = createIcon({
	displayName: 'IconBathroom',
	viewBox: '0 0 15 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M2.5438 9.48736H8.12238V10.6927H11.6096V9.48999H12.995C13.0848 10.9235 13.0918 12.1998 11.8416 13.1727C11.8263 13.1845 11.814 13.2007 11.7801 13.2361C11.9339 13.3863 12.0854 13.5348 12.2355 13.6814C12.0329 13.9091 11.8702 14.0921 11.6837 14.3023C11.5191 14.1026 11.3411 13.871 11.1472 13.66C11.0902 13.5978 10.9893 13.5724 10.9066 13.5663C10.7715 13.5566 10.6345 13.5825 10.4982 13.5829C8.68682 13.5842 6.87545 13.5838 5.06371 13.5838C4.97695 13.5838 4.88425 13.6035 4.80419 13.575C4.56255 13.4897 4.40916 13.6214 4.26544 13.825C4.14294 13.9984 4.00332 14.1547 3.87561 14.3132C3.70322 14.1 3.54722 13.9074 3.37446 13.6933C3.49025 13.5728 3.63732 13.4196 3.80263 13.2471C3.6887 13.1512 3.60344 13.0847 3.52264 13.0111C2.91724 12.4625 2.58997 11.7348 2.54715 10.8412C2.52592 10.3985 2.5438 9.95322 2.5438 9.48736Z'
				fill='currentColor'
			/>
			<path
				d='M2.54715 6.17117C2.54417 6.09192 2.53896 6.02055 2.53896 5.94918C2.53858 4.78891 2.53747 3.62907 2.53896 2.46879C2.54045 1.1938 3.29292 0.304108 4.37565 0.313303C4.73904 0.316368 5.1136 0.307611 5.46284 0.404374C6.08239 0.576445 6.47259 1.08171 6.66099 1.79364C6.69748 1.932 6.74476 1.99899 6.87396 2.04014C7.60298 2.27264 8.11456 3.07389 8.12386 3.96796C8.12535 4.112 8.12386 4.25605 8.12386 4.41236H4.67314C4.50857 3.38913 4.98142 2.33306 5.96995 2.00337C5.88059 1.5467 5.49821 1.1614 5.09684 1.14476C4.7934 1.13206 4.48883 1.13075 4.18539 1.14476C3.66227 1.16884 3.24154 1.73366 3.2393 2.411C3.23558 3.57828 3.23819 4.746 3.23819 5.91328C3.23819 5.99297 3.23819 6.07265 3.23819 6.17073L2.54715 6.17117Z'
				fill='currentColor'
			/>
			<path
				d='M8.11262 8.66816C8.03964 8.66816 7.98491 8.66816 7.93018 8.66816C6.15716 8.66816 4.38376 8.6686 2.61074 8.66772C2.2291 8.66772 1.9547 8.45537 1.85827 8.09284C1.71306 7.54729 2.04182 7.02188 2.54298 7.014C3.07615 7.00568 3.60932 7.01181 4.14249 7.01181C5.395 7.01181 6.64714 7.01181 7.89965 7.01181C7.96667 7.01181 8.03369 7.01181 8.11262 7.01181V8.66816Z'
				fill='currentColor'
			/>
			<path
				d='M10.9078 9.89367H8.83278C8.82942 9.81661 8.82384 9.74656 8.82384 9.67651C8.82309 8.83104 8.82309 7.98513 8.82384 7.13966C8.82421 6.528 9.1083 6.19173 9.6262 6.18911C9.81833 6.18823 10.0104 6.18342 10.2026 6.19042C10.6341 6.20662 10.9159 6.5477 10.9171 7.05647C10.9193 7.93872 10.9178 8.82053 10.9171 9.70278C10.9171 9.76013 10.9118 9.81705 10.9078 9.89367Z'
				fill='currentColor'
			/>
			<path
				d='M11.6219 8.66028V7.01181C11.9592 7.01181 12.2917 7.0105 12.6238 7.01225C12.7969 7.01312 12.9738 6.99648 13.1432 7.02932C13.5129 7.10113 13.7534 7.45709 13.7382 7.8726C13.7233 8.28023 13.4675 8.63007 13.1045 8.6524C12.6175 8.68261 12.1279 8.66028 11.6219 8.66028Z'
				fill='currentColor'
			/>
		</>
	),
});

export default IconBathroom;
