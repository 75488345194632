import { createIcon } from '@chakra-ui/react';

const IconUSDT = createIcon({
	displayName: 'IconUSDT',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M13.8535 5.98671C13.8535 5.23004 11.7946 4.59874 9.05857 4.45309L9.05836 3.24748H12.4746V0.738403H3.13312V3.24748H6.54913V4.45899C3.86291 4.61462 1.85352 5.23934 1.85352 5.98671C1.85352 6.73418 3.86291 7.35895 6.54913 7.51432V11.8436H9.05836V7.52022C11.7946 7.37489 13.8535 6.74337 13.8535 5.98671ZM7.85349 6.73211C4.91671 6.73211 2.53592 6.27758 2.53592 5.71655C2.53592 5.24152 4.24307 4.84271 6.54913 4.73175V5.01768H6.54939V6.1894C6.96515 6.21272 7.40209 6.22494 7.85349 6.22494C8.26914 6.22494 8.67278 6.21437 9.05863 6.19503V4.72739C11.4147 4.83155 13.1712 5.23456 13.1712 5.71655C13.1711 6.27758 10.7903 6.73211 7.85349 6.73211Z'
			fill='currentColor'
		/>
	),
});

export default IconUSDT;
