import { createIcon } from '@chakra-ui/react';

const IconDiscovery = createIcon({
	displayName: 'IconDiscovery',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M14.9375 15.1379L11.5542 11.7546M7.15972 5.02683V9.6935M4.82639 7.36016H9.49306M13.3819 7.36016C13.3819 10.7966 10.5962 13.5824 7.15972 13.5824C3.72328 13.5824 0.9375 10.7966 0.9375 7.36016C0.9375 3.92372 3.72328 1.13794 7.15972 1.13794C10.5962 1.13794 13.3819 3.92372 13.3819 7.36016Z'
			stroke='currentColor'
			fill='none'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconDiscovery;
