import { createIcon } from '@chakra-ui/react';

const IconShare = createIcon({
	displayName: 'IconShare',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M14.8125 8.18884V11.4701C14.8125 12.7827 14.8125 13.439 14.557 13.9404C14.3323 14.3814 13.9738 14.7399 13.5328 14.9646C13.0314 15.2201 12.3751 15.2201 11.0625 15.2201H4.5C3.18738 15.2201 2.53107 15.2201 2.02971 14.9646C1.5887 14.7399 1.23016 14.3814 1.00545 13.9404C0.75 13.439 0.75 12.7827 0.75 11.4701V8.18884M10.9062 4.28259L7.78125 1.15759M7.78125 1.15759L4.65625 4.28259M7.78125 1.15759V10.5326'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconShare;
