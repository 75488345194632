// Componente principal de rutas

import AppRoutes from './routes/AppRoutes';
import './appStyle.css';

function App() {
	return <AppRoutes />;
}

export default App;
