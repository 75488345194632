import SendEmailResetPassword from './components/SendEmailResetPassword';

/**
*
    @name ResetPassword
    @description Page of the Reset Password experience. The steps are:
					- Introduce mail. An email will be sent with a code to given email 
					- Validate mail. User has to write the code given in the email
					- Change password. User has to write twice the new password
					- Sucessfull message. A succesful alert will appear 
					  with a button to go back to the login screen.
	@returns {JSX.Element} index.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const ResetPassword = () => {
	return <SendEmailResetPassword />;
};

export default ResetPassword;
