import { AddIcon } from '@chakra-ui/icons';
import { Avatar, Box, Input, useColorMode } from '@chakra-ui/react';

const AvatarChangeComponent = ({
	onChange,
	size,
	errorImage,
	avatarImage,
	onEdit = true,
}) => {
	const { colorMode } = useColorMode();

	const avatarColor =
		colorMode === 'dark'
			? 'transparencyWhite.200'
			: 'transparencyBlack.200';

	const image =
		typeof avatarImage === 'string' && avatarImage.length > 0
			? `https://elevex-cdn.s3.eu-west-3.amazonaws.com/avatars/${avatarImage}`
			: avatarImage?.type
				? URL.createObjectURL(avatarImage)
				: null;
	return (
		<>
			<Box
				w='fit-content'
				h='fit-content'
				role='group'
				cursor='pointer'
				as='label'
				htmlFor='photo'
				zIndex={6}
				position='relative'
			>
				{onEdit ? (
					<>
						<Avatar
							size={size || 'lg'}
							transition='filter 0.2s'
							_groupHover={{ filter: 'brightness(0.5)' }}
							bg={avatarColor}
							src={image}
							_active={{ filter: 'brightness(0.7)' }}
							borderColor={errorImage ? 'red' : undefined}
							borderWidth={errorImage ? '1px' : null}
						/>
						<AddIcon
							position='absolute'
							top='50%'
							left='50%'
							transform='translate(-50%, -50%)'
							color='white'
							boxSize='20px'
							transition='color 0.2s opacity 0.2s'
							opacity='0'
							_groupHover={{
								opacity: '1',
							}}
						/>
					</>
				) : (
					<Avatar
						size={size || 'lg'}
						transition='filter 0.2s'
						bg={'transparencyWhite.200'}
						src={image}
						cursor={'default'}
					/>
				)}
			</Box>
			{onEdit && (
				<Input
					id='photo'
					type='file'
					hidden
					name='photo'
					onChange={onChange}
				/>
			)}
		</>
	);
};

export default AvatarChangeComponent;
