import { motion, AnimatePresence } from 'framer-motion';
import GridCenterContainer from '../../../components/GridCenterContainer';
import { variants, duration } from './SliderVerifyConfiguration';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { selectElementsOptions } from '../../../utils/SelectElementsOptions';
import { Flex } from '@chakra-ui/react';

/**
*
    @name Register
    @description The register page that include the Register component
    @returns {JS.Element} Register.js
    @author Saguado | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const SliderVerifyComponent = () => {
	const { state } = useLocation();
	const { userInfo, type } = state;
	const elements = selectElementsOptions(type);
	const [steps, setSteps] = useState(0);
	const maxSteps = elements.length;
	const nextStep = () => {
		if (steps < maxSteps - 1) {
			setSteps(prevSteps => prevSteps + 1);
		}
	};

	return (
		<Flex w='100%' h='100%' justifyContent='center' alignItems='center'>
			<GridCenterContainer>
				<AnimatePresence mode='wait'>
					{elements.map((element, index) => {
						if (index === steps) {
							const Component = element.component;
							return (
								<motion.div
									key={index}
									initial='initial'
									animate='animate'
									exit='exit'
									variants={variants}
									transition={{ duration }}
								>
									<Component
										userInfo={userInfo}
										nextStep={nextStep}
										{...element.props}
									/>
								</motion.div>
							);
						}
						return null;
					})}
				</AnimatePresence>
			</GridCenterContainer>
		</Flex>
	);
};

export default SliderVerifyComponent;
