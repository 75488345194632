import { Tab, Text, useColorMode } from '@chakra-ui/react';

const TabComponent = ({ title, icon }) => {
	const { colorMode } = useColorMode();

	return (
		<Tab
			borderRadius='5px'
			maxH='28px'
			fontWeight='400'
			gap='5px'
			p='10px 7px'
			_selected={{ fontWeight: '500', bg: 'transparencyWhite.100' }}
		>
			{icon}

			<Text
				fontSize='12px'
				lineHeight='20px'
				color={colorMode === 'dark' ? 'white' : 'black'}
			>
				{title}
			</Text>
		</Tab>
	);
};

export default TabComponent;
