import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import SmallBoxBigNumberComponent from './SmallBoxBigNumberComponent';

const HigherROIComponent = ({ isTablet }) => {
	return (
		<Stack w='100%' h='100%' minH='150px' p='15px 25px' gap='10px'>
			<Text
				fontSize='16px'
				fontWeight='700'
				letterSpacing={0.02}
				lineHeight='12px'
			>
				Higher ROI per project of
			</Text>
			<SimpleGrid columns={2} spacing='20px' w='100%' h='100%'>
				<SmallBoxBigNumberComponent
					bigText='10,3%'
					smallText='Rental'
					isTablet={isTablet}
				/>
				<SmallBoxBigNumberComponent
					bigText='14%'
					smallText='Exchange'
					isTablet={isTablet}
				/>
			</SimpleGrid>
		</Stack>
	);
};

export default HigherROIComponent;
