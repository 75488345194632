import { useState } from 'react';
import {
	Button,
	Flex,
	Link,
	Stack,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import ClaimExperience from './ClaimExperience';
import BoxComponent from '../../../components/BoxComponent';
import { useTheme } from '@emotion/react';
import DollarIcon from '../../../assets/Icons/currency/DollarIcon';
import { IconWallet } from '../../../assets/Icons';
import { currencyFormatter } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

/**
*
    @name BalanceComponent
    @description Component that shows the balance of the user and the ready to claim balance. It allows to claim the ready to claim amount.
	@returns {JSX.Element} BalanceComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const BalanceComponent = ({ user, isTablet }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const iconColor = useColorModeValue(
		theme.colors.light.primary,
		theme.colors.dark.primary,
	);
	const buttonIconColor = useColorModeValue('white', 'black');
	const [isOpen, setIsOpen] = useState(false);

	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	return (
		<>
			<BoxComponent
				w={isTablet ? '100%' : '500px'}
				maxW='500px'
				h={isTablet ? 'auto' : '75px'}
				p='0'
				inPadding='10px 20px'
			>
				<Flex
					flexDir={isTablet ? 'column' : 'row'}
					justify={'space-around'}
				>
					<Flex
						justify={'center'}
						w='100%'
						gap={isTablet ? '40px' : '40px'}
					>
						<Stack gap='8px'>
							<Text
								variant='gray'
								fontSize='12'
								letterSpacing={-0.02}
								lineHeight='100%'
							>
								{t('dashboard.balance.currentAccountBalance')}
							</Text>
							<Text
								fontSize='16'
								fontWeight='700'
								lineHeight='100%'
							>
								{currencyFormatter(user.accBalance)}
							</Text>
							<Flex gap='10px' alignItems='center'>
								<IconWallet color={iconColor} boxSize='14px' />
								<Link
									variant='isActive'
									fontSize='10'
									lineHeight='100%'
								>
									{t('dashboard.balance.accessWallet')}
								</Link>
							</Flex>
						</Stack>
						<Stack gap='8px'>
							<Text
								variant='gray'
								fontSize='12'
								letterSpacing={-0.02}
								lineHeight='100%'
							>
								{t('dashboard.balance.incomeReadyToClaim')}
							</Text>
							<Text
								fontSize='16'
								fontWeight='700'
								lineHeight='100%'
							>
								{currencyFormatter(user.claim)}
							</Text>
						</Stack>
					</Flex>
					<Button
						w='96px'
						variant='main'
						fontWeight='400'
						alignSelf='center'
						justifySelf='center'
						leftIcon={
							<DollarIcon
								color={buttonIconColor}
								boxSize='18px'
							/>
						}
						gridColumn={isTablet ? 'span 2' : 'none'}
						pr={5}
						mt={isTablet ? '4' : '0'}
						onClick={openModal}
					>
						{t('dashboard.balance.claim')}
					</Button>
				</Flex>
			</BoxComponent>

			<ClaimExperience closeModal={closeModal} isOpen={isOpen} />
		</>
	);
};

export default BalanceComponent;
