import { useTranslation } from 'react-i18next';
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	Image,
	HStack,
} from '@chakra-ui/react';
import { languages } from '../data/languages';

function SelectorLenguageComponent() {
	const { i18n } = useTranslation();

	const changeLanguage = lng => {
		i18n.changeLanguage(lng);
	};

	const currentLanguage = languages.find(lang => lang.code === i18n.language);

	return (
		<Menu>
			<MenuButton
				as={Button}
				background={'transparent'}
				_hover={{ background: 'transparent' }}
				_active={{ background: 'transparent' }}
			>
				<Image
					boxSize='20px'
					borderRadius='full'
					src={currentLanguage.flag}
					alt={currentLanguage.name}
				/>
			</MenuButton>
			<MenuList>
				{languages.map(lang => (
					<MenuItem
						key={lang.code}
						onClick={() => changeLanguage(lang.code)}
					>
						<HStack spacing={3}>
							<Image
								boxSize='20px'
								borderRadius='full'
								src={lang.flag}
								alt={lang.name}
							/>
							<span>{lang.name}</span>
						</HStack>
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
}

export default SelectorLenguageComponent;
