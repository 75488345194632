import { createIcon } from '@chakra-ui/react';

const IconWallet = createIcon({
	displayName: 'IconWallet',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M10.938 8.24436H10.944M12.6042 1.26678H4.07083C3.3241 1.26678 2.95073 1.26678 2.66551 1.41665C2.41463 1.54847 2.21065 1.75882 2.08283 2.01755C1.9375 2.31167 1.9375 2.69671 1.9375 3.46678V10.0668C1.9375 10.8369 1.9375 11.2219 2.08283 11.516C2.21065 11.7747 2.41463 11.985 2.66551 12.1169C2.95073 12.2668 3.32409 12.2668 4.07083 12.2668H11.8042C12.5509 12.2668 12.9243 12.2668 13.2095 12.1169C13.4604 11.985 13.6644 11.7747 13.7922 11.516C13.9375 11.2219 13.9375 10.8369 13.9375 10.0668V6.21679C13.9375 5.44672 13.9375 5.06167 13.7922 4.76754C13.6644 4.50882 13.4604 4.29847 13.2095 4.16664C12.9243 4.01678 12.5509 4.01678 11.8042 4.01678H4.60417M11.238 8.24436C11.238 8.41521 11.1037 8.55374 10.938 8.55374C10.7724 8.55374 10.638 8.41521 10.638 8.24436C10.638 8.07352 10.7724 7.93499 10.938 7.93499C11.1037 7.93499 11.238 8.07352 11.238 8.24436Z'
			fill='none'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconWallet;
