import { createIcon } from '@chakra-ui/react';

const IconPDF = createIcon({
	displayName: 'IconPDF',
	viewBox: '0 0 15 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M3.78223 1.09961C3.09187 1.09961 2.53223 1.65925 2.53223 2.34961V13.8496C2.53223 14.54 3.09187 15.0996 3.78223 15.0996H12.2822C12.9726 15.0996 13.5322 14.54 13.5322 13.8496V8.59961C13.5322 7.2189 12.4129 6.09961 11.0322 6.09961H9.78223C9.09187 6.09961 8.53223 5.53997 8.53223 4.84961V3.59961C8.53223 2.2189 7.41294 1.09961 6.03223 1.09961H3.78223Z'
				fill='currentColor'
			/>
			<path
				d='M8.67969 1.31021C9.21087 1.92393 9.53223 2.72424 9.53223 3.59961V4.84961C9.53223 4.98768 9.64416 5.09961 9.78223 5.09961H11.0322C11.9076 5.09961 12.7079 5.42097 13.3216 5.95215C12.7297 3.68619 10.9456 1.90213 8.67969 1.31021Z'
				fill='currentColor'
			/>
		</>
	),
});

export default IconPDF;
