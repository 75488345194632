import {
	sliderVerifySteps,
	sliderPasswordSteps,
	sliderMagicSteps,
} from '../pages/VerifyAccount/component/SliderVerifySteps';

export const selectElementsOptions = type => {
	switch (type) {
		case 'verify':
			return sliderVerifySteps;
		case 'password':
			return sliderPasswordSteps;
		case 'magic':
			return sliderMagicSteps;
		default:
			return false;
	}
};
