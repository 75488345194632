import { createIcon } from '@chakra-ui/react';

const IconClose = createIcon({
	displayName: 'IconClose',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M11.875 1.87372L1.875 11.8737M1.875 1.87372L11.875 11.8737'
			stroke='currentColor'
			fill='none'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconClose;
