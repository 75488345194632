import SliderVerifyComponent from './component/SliderVerifyComponent';

/**
*
    @name VerifyAccount
    @description The verify account page that include the SliderVerifyComponent
	@returns {JSX.Element} index.js
    @author Saguado | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/
const VerifyAccount = () => {
	return <SliderVerifyComponent />;
};

export default VerifyAccount;
