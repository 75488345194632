import { createIcon } from '@chakra-ui/react';

const IconTokenNFT = createIcon({
	displayName: 'IconTokenNFT',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.21875 9.43774H13.2188M4.41875 1.43774H10.0188C11.1389 1.43774 11.6989 1.43774 12.1267 1.65573C12.5031 1.84748 12.809 2.15344 13.0008 2.52976C13.2188 2.95759 13.2188 3.51764 13.2188 4.63774V10.2377C13.2188 11.3578 13.2188 11.9179 13.0008 12.3457C12.809 12.7221 12.5031 13.028 12.1267 13.2198C11.6989 13.4377 11.1389 13.4377 10.0188 13.4377H4.41875C3.29864 13.4377 2.73859 13.4377 2.31077 13.2198C1.93444 13.028 1.62848 12.7221 1.43674 12.3457C1.21875 11.9179 1.21875 11.3578 1.21875 10.2377V4.63774C1.21875 3.51764 1.21875 2.95759 1.43674 2.52976C1.62848 2.15344 1.93444 1.84748 2.31077 1.65573C2.73859 1.43774 3.29865 1.43774 4.41875 1.43774Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconTokenNFT;
