import { createIcon } from '@chakra-ui/react';

const IconUser = createIcon({
	displayName: 'IconUser',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M3.89603 11.0296C4.26102 10.1697 5.1132 9.56659 6.10625 9.56659H9.70625C10.6993 9.56659 11.5515 10.1697 11.9165 11.0296M10.3063 5.06659C10.3063 6.39207 9.23173 7.46659 7.90625 7.46659C6.58077 7.46659 5.50625 6.39207 5.50625 5.06659C5.50625 3.74111 6.58077 2.66659 7.90625 2.66659C9.23173 2.66659 10.3063 3.74111 10.3063 5.06659ZM13.9063 6.56659C13.9063 9.8803 11.22 12.5666 7.90625 12.5666C4.59254 12.5666 1.90625 9.8803 1.90625 6.56659C1.90625 3.25288 4.59254 0.566589 7.90625 0.566589C11.22 0.566589 13.9063 3.25288 13.9063 6.56659Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconUser;
