import { createIcon } from '@chakra-ui/react';

const IconPortfolio = createIcon({
	displayName: 'IconPortfolio',
	viewBox: '0 0 16 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M10.925 13.9823V2.78226C10.925 2.41096 10.7775 2.05486 10.5149 1.79231C10.2524 1.52976 9.8963 1.38226 9.525 1.38226H6.725C6.3537 1.38226 5.9976 1.52976 5.73505 1.79231C5.4725 2.05486 5.325 2.41096 5.325 2.78226V13.9823M2.525 4.18226H13.725C14.4982 4.18226 15.125 4.80906 15.125 5.58226V12.5823C15.125 13.3555 14.4982 13.9823 13.725 13.9823H2.525C1.7518 13.9823 1.125 13.3555 1.125 12.5823V5.58226C1.125 4.80906 1.7518 4.18226 2.525 4.18226Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.5'
		/>
	),
});

export default IconPortfolio;
