import { createIcon } from '@chakra-ui/react';

const IconLess = createIcon({
	displayName: 'IconLess',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M0.78125 7.82617V5.99817H14.7812V7.82617H0.78125Z'
			stroke='currentColor'
			fill='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconLess;
