import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react';

const ModalComponent = ({
	onClose,
	isOpen,
	modalCross = true,
	closeOnOverlayClick = true,
	moreBlur = false,
	children,
}) => {
	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay
				backdropFilter={moreBlur ? 'blur(60px)' : 'blur(27.2px)'}
			/>
			<ModalContent alignItems='center' justifyContent='center'>
				<ModalBody position='relative' w='fit-content' p={0}>
					{modalCross && (
						<ModalCloseButton
							position='absolute'
							top={6}
							right={10}
							borderRadius='50%'
						/>
					)}
					{children}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ModalComponent;
