const bcrypt = require('bcryptjs');

/**
 *
 * @name createHash
 * @description Creates a hash of the received parameter.
 * @returns {string} Hash.
 *
 */
const createHash = async password => {
	return await bcrypt.hash(password, 10);
};

/**
 *
 * @name checkHash
 * @description Compares the hash entered with the hash in the database.
 * @returns {boolean}
 *
 */
const checkHash = async (password, hash) => {
	return await bcrypt.compare(password, hash);
};

/**
 *
 * @name generateNewPassword
 * @description Generates a random password.
 * Includes lowercase, uppercase, symbols and numbers - 12 digits.
 * @returns {string} Random password.
 *
 */
const generateNewPassword = () => {
	return Math.random().toString(36).slice(-12);
};

module.exports = {
	createHash,
	checkHash,
	generateNewPassword,
};
