import {
	Button,
	Flex,
	Stack,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import {
	IconBuilding,
	IconClose,
	IconGallery,
	IconHelp,
	IconPublish,
	IconSave,
	IconTimeline,
} from '../../assets/Icons';
import TabComponent from './components/TabComponent';
import DollarIcon from '../../assets/Icons/currency/DollarIcon';
import InformationComponent from './components/InformationComponent/InformationComponent';
import ProjectUpdatesComponent from './components/ProjectUpdatesComponent/ProjectUpdatesComponent';
import TimelineComponent from './components/TimelineComponent/TimelineComponent';
import FAQsComponent from './components/FAQsComponent/FAQsComponent';
import FinancialDetailsComponent from './components/FinancialDetailsComponent/FinancialDetailsComponent';
import GalleryComponent from './components/GalleryComponent/GalleryComponent';
import { useEffect, useState } from 'react';
import { savePlaceDetails } from '../../utils/GoogleMapsInfo';
import { addProperty } from '../../services/propertyService';

const initialPropertyInfo = {
	data: {},
	faqs: [],
};

const AddProperty = () => {
	const { colorMode } = useColorMode();
	const iconsColor = colorMode === 'dark' ? 'white' : 'black';
	const [placeSelected, setPlaceSelected] = useState();
	const [selectedSuggestion, setSelectedSuggestion] = useState(null);
	const [propertyInfo, setPropertyInfo] = useState(initialPropertyInfo);

	/* TODO: Add save functionalty with all fields */
	const handleSave = async () => {
		if (placeSelected) {
			savePlaceDetails(selectedSuggestion, setPlaceSelected);
		}
		const result = await addProperty(propertyInfo);
		if (result.success) {
			console.log('Property added successfully:', true);
		}
	};

	useEffect(() => {
		if (placeSelected) {
			console.log('Place selected updated:', placeSelected);
		}
	}, [placeSelected]);

	return (
		<Stack w='100%' gap='30px'>
			<Text fontSize='32px' fontWeight='700' lineHeight='100%'>
				Admin Panel
			</Text>
			<Tabs variant='unstyled'>
				<Flex justify='space-between' pb='30px'>
					<Button variant='secondary' fontSize='13px' gap='5px'>
						<IconClose boxSize='14px' />
						Cancel
					</Button>
					<TabList
						h='40px'
						display='flex'
						alignItems='center'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.100'
						}
						borderRadius='5px'
						gap='5px'
						p='10px'
					>
						<TabComponent
							icon={
								<IconBuilding
									boxSize='14px'
									color={iconsColor}
								/>
							}
							title='Information'
						></TabComponent>
						<TabComponent
							icon={
								<IconGallery
									boxSize='14px'
									color={iconsColor}
								/>
							}
							title='Gallery'
						></TabComponent>
						<TabComponent
							icon={
								<DollarIcon boxSize='14px' color={iconsColor} />
							}
							title='Financial details'
						></TabComponent>
						<TabComponent
							icon={
								<IconBuilding
									boxSize='14px'
									color={iconsColor}
								/>
							}
							title='Project updates'
						></TabComponent>
						<TabComponent
							icon={
								<IconHelp boxSize='14px' color={iconsColor} />
							}
							title='FAQ'
						></TabComponent>
						<TabComponent
							icon={
								<IconTimeline
									boxSize='14px'
									color={iconsColor}
								/>
							}
							title='Timeline'
						></TabComponent>
					</TabList>
					<Flex gap='10px'>
						<Button variant='secondary' fontSize='13px ' gap='5px'>
							<IconSave boxSize='14px' />
							Save as draft
						</Button>

						<Button variant='main' gap='5px' onClick={handleSave}>
							<IconPublish boxSize='14px' />
							Publish
						</Button>
					</Flex>
				</Flex>
				<TabPanels>
					<TabPanel p='0px'>
						<InformationComponent
							setSelectedSuggestion={setSelectedSuggestion}
							selectedSuggestion={selectedSuggestion}
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<GalleryComponent />
					</TabPanel>
					<TabPanel p='0px'>
						<FinancialDetailsComponent />
					</TabPanel>
					<TabPanel p='0px'>
						<ProjectUpdatesComponent />
					</TabPanel>
					<TabPanel p='0px'>
						<FAQsComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
						/>
					</TabPanel>
					<TabPanel p='0px'>
						<TimelineComponent
							propertyInfo={propertyInfo}
							setPropertyInfo={setPropertyInfo}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Stack>
	);
};

export default AddProperty;
