import { createIcon } from '@chakra-ui/react';

const IconPlus = createIcon({
	displayName: 'IconPlus',
	viewBox: '0 0 13 12',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M5.84 11.8737V7.01172H1V5.69172H5.84V0.873718H7.16V5.69172H12V7.01172H7.16V11.8737H5.84Z'
			stroke='currentColor'
			fill='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconPlus;
