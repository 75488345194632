import { createIcon } from '@chakra-ui/react';

const IconEditUser = createIcon({
	displayName: 'IconEditUser',
	viewBox: '0 0 16 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M5.79258 9.67863H4.72553C3.73276 9.67863 3.23638 9.67863 2.83247 9.80116C1.92305 10.077 1.21138 10.7887 0.935514 11.6981C0.812988 12.102 0.812988 12.5984 0.812988 13.5912M9.70512 3.98767C9.70512 5.75562 8.27191 7.18883 6.50395 7.18883C4.736 7.18883 3.30279 5.75562 3.30279 3.98767C3.30279 2.21971 4.736 0.786499 6.50395 0.786499C8.27191 0.786499 9.70512 2.21971 9.70512 3.98767ZM7.21532 13.5912L9.42154 12.9608C9.52718 12.9306 9.58001 12.9155 9.62928 12.8929C9.67303 12.8728 9.71462 12.8484 9.75343 12.8199C9.79713 12.7878 9.83598 12.7489 9.91367 12.6713L14.5069 8.07808C14.998 7.58696 14.998 6.79071 14.5069 6.2996C14.0158 5.80851 13.2196 5.80852 12.7285 6.29962L8.13524 10.8928C8.05755 10.9705 8.0187 11.0094 7.98662 11.0531C7.95813 11.0919 7.93366 11.1335 7.91357 11.1772C7.89095 11.2265 7.87585 11.2793 7.84567 11.385L7.21532 13.5912Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconEditUser;
