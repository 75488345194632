import { createIcon } from '@chakra-ui/react';

const IconPassword = createIcon({
	displayName: 'IconPassword',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.96875 7.18884H7.97227M11.4844 7.18884H11.4879M4.45312 7.18884H4.45664M3.1875 3.67322H12.75C13.5376 3.67322 13.9314 3.67322 14.2322 3.82649C14.4968 3.96131 14.7119 4.17644 14.8467 4.44104C15 4.74186 15 5.13564 15 5.92322V8.45447C15 9.24204 15 9.63583 14.8467 9.93664C14.7119 10.2012 14.4968 10.4164 14.2322 10.5512C13.9314 10.7045 13.5376 10.7045 12.75 10.7045H3.1875C2.39993 10.7045 2.00614 10.7045 1.70533 10.5512C1.44072 10.4164 1.22559 10.2012 1.09077 9.93664C0.9375 9.63583 0.9375 9.24204 0.9375 8.45447V5.92322C0.9375 5.13564 0.9375 4.74186 1.09077 4.44104C1.22559 4.17644 1.44072 3.96131 1.70533 3.82649C2.00614 3.67322 2.39993 3.67322 3.1875 3.67322ZM8.14453 7.18884C8.14453 7.28592 8.06583 7.36462 7.96875 7.36462C7.87167 7.36462 7.79297 7.28592 7.79297 7.18884C7.79297 7.09176 7.87167 7.01306 7.96875 7.01306C8.06583 7.01306 8.14453 7.09176 8.14453 7.18884ZM11.6602 7.18884C11.6602 7.28592 11.5815 7.36462 11.4844 7.36462C11.3873 7.36462 11.3086 7.28592 11.3086 7.18884C11.3086 7.09176 11.3873 7.01306 11.4844 7.01306C11.5815 7.01306 11.6602 7.09176 11.6602 7.18884ZM4.62891 7.18884C4.62891 7.28592 4.55021 7.36462 4.45312 7.36462C4.35604 7.36462 4.27734 7.28592 4.27734 7.18884C4.27734 7.09176 4.35604 7.01306 4.45312 7.01306C4.55021 7.01306 4.62891 7.09176 4.62891 7.18884Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconPassword;
