import BoxComponent from '../../../components/BoxComponent';
import { Grid, Text } from '@chakra-ui/react';
import InfoDashboardComponent from './InfoDashboardComponent';
import { currencyFormatter } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

/**
*
    @name OverviewComponent
    @description Component that give the user a general view of the projects, the status of them and the general revenue.
	@returns {JSX.Element} OverviewComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const OverviewComponent = ({ user, isTablet }) => {
	const { t } = useTranslation();

	return (
		<BoxComponent
			h='100%'
			w='100%'
			maxW='500px'
			p='0'
			inPadding='15px 20px'
			gap='8px'
		>
			<Text fontSize='16' fontWeight='700'>
				{t('dashboard.overview.title')}
			</Text>
			<Grid
				templateColumns={
					isTablet
						? 'repeat(2, minmax(0, 1fr))'
						: 'repeat(3, minmax(0, 1fr))'
				}
				h='100%'
				placeItems='center'
				gap='10px'
			>
				<InfoDashboardComponent
					header={t('dashboard.overview.totalInvested')}
					info={currencyFormatter(25215)}
				/>
				<InfoDashboardComponent
					header='Total P&L'
					info={currencyFormatter(25215)}
				/>
				<InfoDashboardComponent header='ROI' info='15%' />
				<InfoDashboardComponent
					header={t('dashboard.overview.projectExited')}
					info={10}
				/>
				<InfoDashboardComponent
					header={t('dashboard.overview.projectOwner')}
					info={10}
				/>
				<InfoDashboardComponent
					header={t('dashboard.overview.averageMonthlyIncome')}
					info='1000 USD'
				/>
			</Grid>
		</BoxComponent>
	);
};

export default OverviewComponent;
