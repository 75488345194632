// LineChart.js
import { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useTheme,
} from '@chakra-ui/react';
import BoxComponent from '../../../components/BoxComponent';

import { IconArrowDown } from '../../../assets/Icons';
import { format, subDays, getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';

/**
*
    @name TrendComponent
    @description Component used to generate a chart of the data that we provide. We can filter by time and by type
				 It uses Chart.js and react-chart.js
	@returns {JSX.Element} TrendComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

ChartJS.register(
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
);

const generateMockData = days => {
	const today = new Date();
	const data = [];
	for (let i = 0; i < days; i++) {
		const date = subDays(today, days - 1 - i);
		data.push({
			date,
			formattedDate: format(date, 'MMM dd, yyyy'),
			year: getYear(date),
			value: Math.floor(Math.random() * 1000),
		});
	}
	return data;
};

const TrendComponent = () => {
	const { t } = useTranslation();
	const chartRef = useRef(null);
	const [gradient, setGradient] = useState(null);
	const [days, setDays] = useState(30);
	const theme = useTheme();

	const chartColor = useColorModeValue(
		theme.colors.brand[500],
		theme.colors.brand[200],
	);

	const chartTransparency = useColorModeValue(
		theme.colors.transparencyWhite[100],
		theme.colors.transparencyBlack[100],
	);

	useEffect(() => {
		if (chartRef.current) {
			const chart = chartRef.current;
			const ctx = chart.ctx;
			const gradient = ctx.createLinearGradient(0, 0, 0, 400);
			gradient.addColorStop(0, chartColor);
			gradient.addColorStop(0.1, chartColor);
			gradient.addColorStop(0.8, chartTransparency);
			setGradient(gradient);
		}
	}, [chartColor, chartTransparency]);

	const allData = generateMockData(days);

	// Selecciona 12 puntos de datos y etiquetas equidistantes
	const step = Math.max(Math.floor(days / 12), 1);
	const labels = [];
	const values = [];
	for (let i = 0; i < 12; i++) {
		const index = i * step;
		if (index < allData.length) {
			labels.push(allData[index]);
			values.push(allData[index].value);
		} else {
			break;
		}
	}

	// Verificar si todos los años son iguales
	const allYearsSame = labels.every(label => label.year === labels[0].year);

	const chartLabels = labels.map(label =>
		allYearsSame
			? format(label.date, 'MMM dd')
			: format(label.date, 'MMM yyyy'),
	);

	const chartData = {
		labels: chartLabels, // Agregar etiquetas aquí
		datasets: [
			{
				label: 'Beneficios',
				data: values, // Usar solo los valores aquí
				fill: true,
				backgroundColor: gradient,
				borderColor: 'transparent',
				pointRadius: 0,
				tension: 0.5,
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 100,
					font: {
						size: 10, // Tamaño de fuente para el eje Y
					},
				},
				grid: {
					color: 'rgba(255, 255, 255, 0.1)', // Color de las líneas de puntos
					borderDash: [5, 5], // Configuración de puntos
				},
			},
			x: {
				ticks: {
					font: {
						size: 10, // Tamaño de fuente para el eje X
					},
				},
				grid: {
					display: 'none',
					borderDash: [0, 0],
				},
			},
		},
		plugins: {
			legend: {
				display: false, // Ocultar la leyenda
			},
		},
	};

	return (
		<BoxComponent
			h='100%'
			w='100%'
			maxW='500px'
			p='0'
			inPadding='15px 20px'
		>
			<Text fontSize='16' fontWeight='700'>
				{t('dashboard.trend.title')}
			</Text>
			<Flex justifyContent='space-between' pt='12px' pb='9px'>
				<Flex gap='3px' flexDirection={{ base: 'column', sm: 'row' }}>
					<Text variant='colored' fontSize='12' fontWeight='400'>
						(+5) {t('dashboard.trend.more')}
					</Text>
					<Text variant='gray' fontSize='12' fontWeight='400'>
						{t('dashboard.trend.in')} 2023
					</Text>
				</Flex>
				<Flex
					flexDirection={{ base: 'column', sm: 'row' }}
					align='center'
					gap='10px'
				>
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<IconArrowDown boxSize='10px' />}
							borderRadius='27px'
							fontSize='10px'
							px='10px'
							w='fit-content'
							h='22px'
						>
							{`${t('dashboard.trend.last')} ${days} ${t('dashboard.trend.days')}`}
						</MenuButton>
						<MenuList fontSize={10}>
							<MenuItem onClick={() => setDays(30)}>
								{t('dashboard.trend.30days')}
							</MenuItem>
							<MenuItem onClick={() => setDays(90)}>
								{t('dashboard.trend.90days')}
							</MenuItem>
							<MenuItem onClick={() => setDays(180)}>
								{t('dashboard.trend.6Months')}
							</MenuItem>
							<MenuItem onClick={() => setDays(365)}>
								{t('dashboard.trend.year')}
							</MenuItem>
							<MenuItem onClick={() => setDays(730)}>
								{t('dashboard.trend.2years')}
							</MenuItem>
						</MenuList>
					</Menu>
					<Menu>
						<MenuButton
							as={Button}
							rightIcon={<IconArrowDown boxSize='10px' />}
							borderRadius='27px'
							fontSize='10px'
							px='10px'
							w='fit-content'
							h='22px'
						>
							P&L
						</MenuButton>
						<MenuList fontSize={10}>
							<MenuItem>P&L</MenuItem>
							<MenuItem>
								{t('dashboard.trend.avgMonthly')}
							</MenuItem>
							<MenuItem>ROI</MenuItem>
							<MenuItem>
								{t('dashboard.trend.projectExited')}
							</MenuItem>
							<MenuItem>{t('dashboard.trend.invested')}</MenuItem>
							<MenuItem>
								{t('dashboard.trend.projectOwner')}
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Flex>
			<Box position='relative' h='100%' w='100%'>
				<Line ref={chartRef} data={chartData} options={options} />
			</Box>
		</BoxComponent>
	);
};

export default TrendComponent;
