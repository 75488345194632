import { useState } from 'react';
import { Button, Flex, Stack, Text, useColorMode } from '@chakra-ui/react';
import { IconPassword, IconTrash } from '../../../assets/Icons';
import ChangePassModal from './ChangePassModal';
import DeleteAccountModal from './DeleteAccountModal';
import SuccessfulComponent from '../../../components/SuccessfulComponent';
import ModalComponent from '../../../components/ModalComponent';
import { useTranslation } from 'react-i18next';

const SecurityComponent = ({ userData, isTablet }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [showPassChangeModal, setShowPassChangeModal] = useState(false);
	const [showSuccessfulModal, setSuccessfulModal] = useState(false);
	const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

	const openChangePassModal = () => setShowPassChangeModal(true);
	const closeChangePassModal = () => setShowPassChangeModal(false);

	const openSuccessfulModal = () => setSuccessfulModal(true);
	const closeSuccessfulModal = () => setSuccessfulModal(false);

	const openDeleteAccountModal = () => setShowDeleteAccountModal(true);
	const closeDeleteAccountModal = () => setShowDeleteAccountModal(false);

	return (
		<>
			<Stack
				h='100%'
				px={isTablet ? '10px' : '55px'}
				pt='15px'
				pb='5px'
				gap={isTablet ? '35px' : '50px'}
			>
				<Text
					variant='colored'
					textAlign='center'
					fontWeight='700'
					fontSize='24px'
					lineHeight='20px'
					letterSpacing={-0.02}
					onClick={openSuccessfulModal}
				>
					{t('settings.security.title')}
				</Text>
				<Flex justifyContent='space-between' align='center' gap='25px'>
					<Flex gap='10px'>
						<IconPassword
							boxSize='21px'
							color={
								colorMode === 'dark' ? 'brand.200' : 'brand.500'
							}
						/>
						<Text
							variant='colored'
							fontSize='14'
							fontWeight='700'
							letterSpacing={-0.02}
							lineHeight='20px'
						>
							{t('settings.security.password')}
						</Text>
					</Flex>
					<Flex
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.50'
						}
						borderRadius='5px'
						align='center'
						w='185px'
						h='40px'
						px='10px'
					>
						<Text
							fontSize='13'
							fontWeight='400'
							letterSpacing={-0.02}
							lineHeight='20px'
						>
							*************
						</Text>
					</Flex>
				</Flex>

				<Flex
					flexDir={isTablet ? 'column' : 'row'}
					align='center'
					justify='space-between'
					w='100%'
					h='100%'
					gap='26px'
					mt='-8px'
				>
					<Button
						variant='main'
						fontWeight='normal'
						p='10px 20px'
						color={
							colorMode === 'dark'
								? 'transparencyBlack.950'
								: 'transparencyWhite.950'
						}
						leftIcon={<IconPassword boxSize='20px' />}
						onClick={openChangePassModal}
					>
						{t('settings.security.changePass')}
					</Button>
					<Flex
						h='100%'
						justify='center'
						align='center'
						gap='5px'
						cursor='pointer'
						onClick={openDeleteAccountModal}
					>
						<IconTrash boxSize='15px' />
						<Text
							fontSize='12px'
							fontWeight='400'
							letterSpacing={-0.02}
							textDecoration='underline'
						>
							{t('settings.security.deleteAccount')}
						</Text>
					</Flex>
				</Flex>
			</Stack>
			{showPassChangeModal && (
				<ChangePassModal
					isOpen={openChangePassModal}
					onClose={closeChangePassModal}
					openSuccessfulModal={openSuccessfulModal}
					userData={userData}
				/>
			)}
			{showSuccessfulModal && (
				<ModalComponent
					isOpen={openSuccessfulModal}
					onClose={closeSuccessfulModal}
				>
					<SuccessfulComponent
						p='30px'
						h='305px'
						text='Congratulations! Your password has been changed. Click continue to login'
						buttonTitle='Back to dashboard'
					/>
				</ModalComponent>
			)}
			{showDeleteAccountModal && (
				<DeleteAccountModal
					isOpen={openDeleteAccountModal}
					onClose={closeDeleteAccountModal}
					userData={userData}
				/>
			)}
		</>
	);
};

export default SecurityComponent;
