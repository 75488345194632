import { createIcon } from '@chakra-ui/react';

const IconSea = createIcon({
	displayName: 'IconSea',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.46875 5.10376C3.27927 5.10376 4.63698 2.91626 4.63698 2.91626C4.63698 2.91626 5.99469 5.10376 7.80521 5.10376C9.61573 5.10376 11.4255 2.91626 11.4255 2.91626C11.4255 2.91626 13.236 5.10376 14.5938 5.10376M1.46875 10.2079C3.27927 10.2079 4.63698 8.02043 4.63698 8.02043C4.63698 8.02043 5.99469 10.2079 7.80521 10.2079C9.61573 10.2079 11.4255 8.02043 11.4255 8.02043C11.4255 8.02043 13.236 10.2079 14.5938 10.2079'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconSea;
