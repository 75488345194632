import { Stack } from '@chakra-ui/react';
import PublicOfferComponent from './components/PublicOfferComponent';
import { financialSummary } from '../../../../../data/mockdata';
import FinalPriceComponent from './components/FinalPriceComponent';
import YieldsComponent from './components/YieldsComponent';

const FinancialSummaryTab = () => {
	return (
		<Stack gap='26px' pb='40px'>
			<PublicOfferComponent data={financialSummary.publicOfferPrice} />
			<FinalPriceComponent data={financialSummary.finalPrice} />
			<YieldsComponent data={financialSummary.yields} />
		</Stack>
	);
};

export default FinancialSummaryTab;
