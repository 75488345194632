import { Stack, Text, useColorMode } from '@chakra-ui/react';

const FinancialSummaryComponent = ({ title, children }) => {
	const { colorMode } = useColorMode();
	return (
		<Stack
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.600'
			}
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			py='15px'
			px='20px'
			gap={0}
		>
			<Text variant='colored' fontSize='20px' fontWeight='bold' mb='15px'>
				{title}
			</Text>
			{children}
		</Stack>
	);
};
export default FinancialSummaryComponent;
