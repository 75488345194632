import { useNavigate } from 'react-router-dom';
import {
	Text,
	Button,
	Stack,
	Flex,
	Icon,
	useColorMode,
} from '@chakra-ui/react';

import { IconCheck2 } from '../assets/Icons';
import { PrivateRoutes } from '../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
    @name SuccessfulBuyComponent
    @description Component to display a success message after a successful purchase or claim
    @returns {JS.Element} SuccessfulBuyComponent.js
	@author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const SuccessfulComponent = ({
	p,
	w,
	h = 'fit-content',
	text,
	buttonTitle,
	modal,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const navigate = useNavigate();

	const bgColorModal =
		colorMode === 'dark' ? 'transparencyWhite.100' : 'transparencyBlack.50';

	const bgColorGeneral =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.900';

	const handleClick = () => {
		if (modal) {
			navigate(0);
		} else {
			navigate(`/${PrivateRoutes.Dashboard}`);
		}
	};

	return (
		<Flex
			bg={!modal ? bgColorModal : bgColorGeneral}
			w={w}
			h={h}
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			borderRadius='12px'
			p={p}
			gap='10px'
		>
			<Text fontSize='20' fontWeight='800'>
				{t('projectDetails.offerDetails.succesful.title')}
			</Text>
			<Text
				fontSize='14'
				fontWeight='400'
				textAlign='center'
				flexWrap='wrap'
				letterSpacing={0.02}
			>
				{text}
			</Text>
			<Stack
				bg={
					colorMode === 'dark'
						? 'transparencyBlack.400'
						: 'transparencyBlack.100'
				}
				borderRadius='100'
				p='20px'
				my='10px'
			>
				<Icon
					as={IconCheck2}
					color={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
					boxSize='1.8em'
				/>
			</Stack>
			<Button variant='main' onClick={handleClick}>
				{buttonTitle}
			</Button>
		</Flex>
	);
};

export default SuccessfulComponent;
