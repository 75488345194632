import BoxComponent from '../../../../components/BoxComponent';
import OptionsComponent from './OptionsComponent';
import { calcTokensNumber, currencyFormatter } from '../../../../utils/utils';
import { SpainCircle } from '../../../../assets/countries';
import {
	Box,
	Flex,
	Image,
	Table,
	Tbody,
	Td,
	Tr,
	useColorMode,
} from '@chakra-ui/react';

const ListTokenGallery = ({ data, isTablet }) => {
	const { colorMode } = useColorMode();

	const colorHeadTable = colorMode === 'dark' ? 'brand.200' : 'brand.500';
	const colorBorder =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyBlack.200';

	return (
		<BoxComponent p='0' w='100%'>
			<Box
				borderWidth={{ base: '2px', md: '0' }}
				borderColor={colorBorder}
				borderRadius={'10px'}
			>
				<Table>
					<Tbody>
						<Tr
							borderColor={colorBorder}
							borderBottomWidth={{ base: '2px', md: '3px' }}
						>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Project name
							</Td>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Country
							</Td>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Total tokens
							</Td>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Fiat value
							</Td>
							<Td
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Options
							</Td>
						</Tr>
						{data.map((project, index) => (
							<Tr
								key={project.id}
								fontSize={isTablet ? '10' : '12px'}
								borderColor={
									index === data.length - 1
										? 'transparent'
										: colorBorder
								}
								borderBottomWidth='2px'
								px={{ base: '4px', md: '10px' }}
							>
								<Td
									fontSize={isTablet ? '10' : '14px'}
									fontWeight={isTablet ? '600' : '500'}
									p='8px'
								>
									{project.name}
								</Td>
								<Td p='8px'>
									<Flex justify='center'>
										<Image src={SpainCircle} w='15px' />
									</Flex>
								</Td>
								<Td fontWeight='300' p='8px' textAlign='center'>
									{calcTokensNumber(project)}
								</Td>
								<Td fontWeight='300' p='8px' textAlign='center'>
									{currencyFormatter(project.totalPrize)}
								</Td>
								<Td
									p='8px'
									justifyContent='center'
									display='flex'
									border='none'
								>
									<OptionsComponent />
								</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</BoxComponent>
	);
};

export default ListTokenGallery;
