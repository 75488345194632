import { Button } from '@chakra-ui/react';

const ButtonComponent = ({
	type,
	children,
	onClick,
	maxW,
	w,
	disabled,
	mt,
	leftIcon,
	rightIcon,
}) => {
	return (
		<Button
			onClick={onClick}
			variant={type === 'main' ? 'main' : 'secondary'}
			maxW={maxW}
			w={w}
			m='auto'
			mt={mt}
			disabled={disabled}
			leftIcon={leftIcon}
			rightIcon={rightIcon}
		>
			{children}
		</Button>
	);
};

export default ButtonComponent;
