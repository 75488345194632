import { createIcon } from '@chakra-ui/react';

const IconFilter = createIcon({
	displayName: 'IconFilter',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M3.17708 12.5666L3.17708 8.56659M3.17708 8.56659C3.91346 8.56659 4.51042 7.96964 4.51042 7.23326C4.51042 6.49688 3.91346 5.89992 3.17708 5.89992C2.4407 5.89992 1.84375 6.49688 1.84375 7.23326C1.84375 7.96964 2.4407 8.56659 3.17708 8.56659ZM3.17708 3.23326V0.566589M7.84375 12.5666V8.56659M7.84375 3.23326V0.566589M7.84375 3.23326C7.10737 3.23326 6.51042 3.83021 6.51042 4.56659C6.51042 5.30297 7.10737 5.89992 7.84375 5.89992C8.58013 5.89992 9.17708 5.30297 9.17708 4.56659C9.17708 3.83021 8.58013 3.23326 7.84375 3.23326ZM12.5104 12.5666V9.89992M12.5104 9.89992C13.2468 9.89992 13.8438 9.30297 13.8438 8.56659C13.8438 7.83021 13.2468 7.23326 12.5104 7.23326C11.774 7.23326 11.1771 7.83021 11.1771 8.56659C11.1771 9.30297 11.774 9.89992 12.5104 9.89992ZM12.5104 4.56659V0.566589'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconFilter;
