import { createIcon } from '@chakra-ui/react';

const IconSales = createIcon({
	displayName: 'IconSales',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M5.93125 6.08872H5.93825M10.1312 10.2887H10.1383M10.8312 5.38872L5.23125 10.9887M15.0312 8.18872C15.0312 12.0547 11.8972 15.1887 8.03125 15.1887C4.16526 15.1887 1.03125 12.0547 1.03125 8.18872C1.03125 4.32273 4.16526 1.18872 8.03125 1.18872C11.8972 1.18872 15.0312 4.32273 15.0312 8.18872ZM6.28125 6.08872C6.28125 6.28202 6.12455 6.43872 5.93125 6.43872C5.73795 6.43872 5.58125 6.28202 5.58125 6.08872C5.58125 5.89542 5.73795 5.73872 5.93125 5.73872C6.12455 5.73872 6.28125 5.89542 6.28125 6.08872ZM10.4812 10.2887C10.4812 10.482 10.3245 10.6387 10.1312 10.6387C9.93795 10.6387 9.78125 10.482 9.78125 10.2887C9.78125 10.0954 9.93795 9.93872 10.1312 9.93872C10.3245 9.93872 10.4812 10.0954 10.4812 10.2887Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconSales;
