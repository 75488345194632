import { createIcon } from '@chakra-ui/react';

const IconLocked = createIcon({
	displayName: 'IconLocked',
	viewBox: '0 0 15 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M10.7683 5.86841V4.42358C10.7683 2.4287 9.15114 0.811523 7.15625 0.811523C5.16136 0.811523 3.54419 2.4287 3.54419 4.42358V5.86841M7.15625 9.11926V10.5641M4.84453 13.8149H9.46797C10.6817 13.8149 11.2886 13.8149 11.7522 13.5787C12.16 13.3709 12.4916 13.0394 12.6993 12.6316C12.9355 12.168 12.9355 11.5611 12.9355 10.3474V9.33599C12.9355 8.12222 12.9355 7.51534 12.6993 7.05174C12.4916 6.64395 12.16 6.3124 11.7522 6.10462C11.2886 5.86841 10.6817 5.86841 9.46797 5.86841H4.84453C3.63077 5.86841 3.02388 5.86841 2.56028 6.10462C2.15249 6.3124 1.82095 6.64395 1.61317 7.05174C1.37695 7.51534 1.37695 8.12222 1.37695 9.33599V10.3474C1.37695 11.5611 1.37695 12.168 1.61317 12.6316C1.82095 13.0394 2.15249 13.3709 2.56028 13.5787C3.02388 13.8149 3.63077 13.8149 4.84453 13.8149Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconLocked;
