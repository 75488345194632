import {
	Divider,
	Flex,
	Grid,
	GridItem,
	Stack,
	Text,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';

import { userExample } from '../../../data/mockdata';

import PublishedInfoComponent from './PublishedInfoComponent';

const PublishedPropertiesComponent = ({ isMobile }) => {
	const theme = useTheme();
	const { colorMode } = useColorMode();

	const colorBorderTablet =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[100];
	const colorBorderDesktop =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[200];

	return (
		<Stack w='100%' h='100%' p='15px' gap='11px'>
			<Text
				fontWeight='700'
				fontSize='16'
				lineHeight='100%'
				letterSpacing={0.02}
			>
				Published properties
			</Text>
			<Flex
				flexDirection='column'
				justifyContent='center'
				h='100%'
				gap='5px'
			>
				<Grid
					templateColumns='repeat(4, 1fr)'
					placeItems='center'
					textAlign='center'
					fontSize={isMobile ? '12px' : '14px'}
					border={isMobile ? '2px solid' : 'none'}
					borderColor={colorBorderTablet}
					borderRadius={isMobile ? '12px' : '0'}
					rowGap={isMobile ? '0' : '0'}
				>
					<GridItem
						borderRight={
							isMobile ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isMobile ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							wordBreak='break-all'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
						>
							Name
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isMobile ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isMobile ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
							wordBreak='break-all'
						>
							Price
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isMobile ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isMobile ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
							wordBreak='break-all'
						>
							Status
						</Text>
					</GridItem>

					<GridItem
						py={isMobile ? '12px' : '5px'}
						px='5px'
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
							wordBreak='break-all'
						>
							Action
						</Text>
					</GridItem>
					<Divider
						gridColumn={isMobile ? 'span 5' : 'span 6'}
						borderWidth='1px'
						borderColor={colorBorderDesktop}
					/>
					{userExample.properties.map((property, index) => {
						return (
							<PublishedInfoComponent
								key={index}
								data={property}
								last={
									index === userExample.properties.length - 1
								}
								isMobile={isMobile}
								colorBorder={colorBorderDesktop}
							/>
						);
					})}
				</Grid>
			</Flex>
		</Stack>
	);
};

export default PublishedPropertiesComponent;
