import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

export const addProperty = async propertyInfo => {
	try {
		const token = encodeToken({
			/* data: propertyInfo.data, */
			data: {
				title: propertyInfo.data.title,
				areaM2: propertyInfo.data.areaM2,
				purchasedOrPledge: propertyInfo.data.purchasedOrPledge,
				propertyStatus: propertyInfo.data.projectStatus,
				category: propertyInfo.data.category,
				subcategory: propertyInfo.data.subcategory,
				distributionFrequency: propertyInfo.data.distributionFrequency,
				postedBy: 'Pepita Pérez',
				address: 'Calle Larios',
				country: 'Spain',
				city: 'Málaga',
				postalCode: '29006',
				coordinates: { lat: 36.7213, long: -4.4217 },
				description: propertyInfo.data.description,
				propertyType: propertyInfo.data.propertyType,
				financingStart: propertyInfo.data.financingStart,
				financingEnd: propertyInfo.data.financingEnd,
				projectStart: propertyInfo.data.projectStart,
				projectEnd: propertyInfo.data.projectEnd,
				pricePerToken: 150,
				quantityOfTokens: 500,
				appreciation: 1500,
			},
			equipments: propertyInfo.equipments,
			/* FAQ: [
				{ title: 'ja', response: '12' },
				{ title: 'jaja', response: '1234' },
				{ title: 'jajaja', response: '123456' },
			], */
		});

		const formData = new FormData();
		formData.append('token', token);

		const response = await axios.post('/project/create', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};
