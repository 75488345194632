import {
	Grid,
	Stack,
	Text,
	useColorMode,
	useColorModeValue,
	useMediaQuery,
} from '@chakra-ui/react';
import { userExample } from '../../data/mockdata';
import AccountBalanceComponent from './components/AccountBalanceComponent';
import CryptoCurrencyComponent from './components/CryptoCurrencyComponent';
import ProjectTokensComponent from './components/ProjectTokenComponent/ProjectTokensComponent';

const WalletComponent = () => {
	const [isMobile] = useMediaQuery('(max-width: 720px)');
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const { colorMode } = useColorMode();
	const color = useColorModeValue('brand.500', 'brand.200');

	return (
		<Stack align='center' gap='30px'>
			<Stack gap='25px'>
				<Text
					gridColumn={!isTablet && 'span 2'}
					fontWeight='700'
					fontSize='32px'
				>
					Wallet
				</Text>
				<Grid
					templateColumns={isTablet ? '1fr' : 'repeat(2, 1fr)'}
					maxW='1080px'
					gap='20px'
				>
					<AccountBalanceComponent
						balance={userExample.accBalance}
						colorMode={colorMode}
						color={color}
						isMobile={isMobile}
					/>
					<CryptoCurrencyComponent
						user={userExample}
						colorMode={colorMode}
						color={color}
						isMobile={isMobile}
					/>
				</Grid>
			</Stack>
			<ProjectTokensComponent isTablet={isTablet} isMobile={isMobile} />
		</Stack>
	);
};

export default WalletComponent;
