import { FormControl, FormLabel, Input, useColorMode } from '@chakra-ui/react';

const ProfileFormControlComponent = ({
	name,
	formLabel,
	value,
	placeholder = '',
	type = 'text',
	editProfile,
	onChange,
	errorMsg,
}) => {
	const { colorMode } = useColorMode();
	return (
		<FormControl
			type={type}
			display='flex'
			justifyContent='space-between'
			alignItems='center'
			gap='18px'
		>
			<FormLabel
				minW='69px'
				fontSize='14px'
				fontWeight='700'
				letterSpacing={-0.02}
				color={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
				m='0px'
			>
				{formLabel}
			</FormLabel>

			<Input
				variant={errorMsg ? 'error' : 'none'}
				name={name}
				maxW='290px'
				value={value}
				placeholder={placeholder}
				_placeholder={{ color: 'transparencyWhite.950' }}
				fontSize='13px'
				fontWeight='400'
				lineHeight='20px'
				p='10px'
				onChange={onChange}
				isDisabled={!editProfile}
				_active={{ boxShadow: '0px' }}
			/>
		</FormControl>
	);
};

export default ProfileFormControlComponent;
