import { Button, Flex, Image, Text } from '@chakra-ui/react';

import { IconArrow } from '../../../assets/Icons';

const SlideTemplateComponentMobile = ({
	image,
	header,
	info,
	isLastSlide,
	handleNext,
	closeOnboardingModal,
}) => {
	return (
		<>
			<Flex
				flexDirection='column'
				justifyContent='space-between'
				alignItems='center'
				w='100%'
				h='100%'
			>
				<Image
					objectFit='cover'
					src={image}
					sx={{
						maskImage:
							'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
					}}
				/>
				<Flex
					flexDir='column'
					position='absolute'
					top='110px'
					w='100%'
					h='100%'
					gap='22px'
					px={8}
					justifyContent='center'
				>
					<Text
						textAlign='center'
						variant='colored'
						fontWeight='700'
						fontSize='32px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{header}
					</Text>
					<Text lineHeight='25px' fontWeight='regular'>
						{info}
					</Text>
				</Flex>
				<Button
					variant='main'
					position='absolute'
					bottom='60px'
					h='33px'
					w='89px'
					borderRadius='30px'
					gap='5px'
					alignSelf='center'
					_active={{
						transform: 'scale(0.95) ',
					}}
					onClick={isLastSlide ? closeOnboardingModal : handleNext}
				>
					<IconArrow
						boxSize='14px'
						sx={{ transform: 'rotate(180deg)' }}
					/>
					<Text
						lineHeight='150% '
						fontSize='13px'
						fontWeight='400'
						letterSpacing={0.02}
					>
						Next
					</Text>
				</Button>
			</Flex>
		</>
	);
};

export default SlideTemplateComponentMobile;
