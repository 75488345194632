import {
	Flex,
	Text,
	Breadcrumb,
	BreadcrumbItem,
	useToken,
	useColorMode,
} from '@chakra-ui/react';
import useCountdownTimer from '../../../../hooks/useCountdownTimer';

const Timer = ({ endDate }) => {
	const { days, hours, minutes, seconds } = useCountdownTimer(endDate);
	const { colorMode } = useColorMode();
	const [transparencyBlack200] = useToken('colors', [
		'transparencyBlack.200',
	]);

	const isLive = new Date() >= endDate;

	return (
		<Flex justify='center' align='center'>
			{!isLive && (
				<Flex
					bgGradient='linear(to-t, transparencyWhite.600  , transparencyWhite.950 )'
					w='219px'
					h='47px'
					borderRadius='50'
					backdropFilter='blur(3px)'
					boxShadow={`0 0 6px ${transparencyBlack200}`}
					align='center'
					justify='center'
				>
					<Breadcrumb separator='|' color='transparencyWhite.300'>
						<BreadcrumbItem>
							<Text variant='inverse'>{days}d</Text>
						</BreadcrumbItem>
						<BreadcrumbItem>
							<Text variant='inverse'>{hours}h</Text>
						</BreadcrumbItem>
						<BreadcrumbItem>
							<Text variant='inverse'>{minutes}m</Text>
						</BreadcrumbItem>
						<BreadcrumbItem>
							<Text variant='inverse'>{seconds}s</Text>
						</BreadcrumbItem>
					</Breadcrumb>
				</Flex>
			)}
			{isLive && (
				<Flex
					bgGradient={
						colorMode === 'dark'
							? 'linear(to-t, transparencyBlack.600, transparencyBlack.950 )'
							: 'linear(to-t, transparencyWhite.600  , transparencyWhite.950 )'
					}
					px='20px'
					h='47px'
					borderRadius='50'
					backdropFilter='blur(3px)'
					boxShadow={`0 0 6px ${transparencyBlack200}`}
					align='center'
					justify='center'
				>
					<Text variant='colored' fontSize='17px' fontWeight='600'>
						Live
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default Timer;
