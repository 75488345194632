import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';

import { IconPlus } from '../../../../assets/Icons';
import ProjectUpdatesTable from './ProjectUpdatesTable';

const ProjectUpdatesComponent = () => {
	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Title</FormLabel>
				<Input variant='withBorder' />
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Description</FormLabel>
				<Textarea variant='withBorder' rows={5} />
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				<Button variant='secondary' gap='5px'>
					<IconPlus boxSize='13px' mt='-2px' />
					<Text fontSize='13px'>Add update</Text>
				</Button>
			</Flex>
			<Box gridColumn='span 12'>
				<ProjectUpdatesTable />
			</Box>
		</Grid>
	);
};

export default ProjectUpdatesComponent;
