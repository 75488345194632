/* eslint-disable react/display-name */
import { memo } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from './Routes.js';
import RoutesNotFound from './RoutesNotFound';
import AppLayout from '../layout/AppLayout.js';
import AuthGuard from './guard/Auth.guards.js';
import Discovery from '../pages/Discovery';
import Login from '../pages/Login';
import ResetPassword from '../pages/ResetPassword';
import Register from '../pages/Register';
import Settings from '../pages/Settings';
import AdminPanel from '../pages/Admin';
import Wallet from '../pages/Wallet';
import ProjectDetails from '../pages/ProjectDetails';
import Dashboard from '../pages/Dashboard';
import VerifyAccount from '../pages/VerifyAccount/index.js';
import AddProperty from '../pages/AddProperty/index.js';
import Trade from '../pages/Trade/index.js';
import Stake from '../pages/Stake/index.js';
import Tutorial from '../pages/Tutorial/index.js';
import HowTo from '../pages/HowTo/index.js';

const AppRoutes = memo(() => {
	return (
		<RoutesNotFound>
			{/* Public Routes */}
			<Route
				path={'/'}
				element={<AppLayout component={<Discovery />} />}
			/>

			<Route
				path={`/${PublicRoutes.Trade}`}
				element={<AppLayout component={<Trade />} />}
			/>

			<Route
				path={`/${PublicRoutes.Stake}`}
				element={<AppLayout component={<Stake />} />}
			/>

			<Route
				path={`/${PublicRoutes.Register}`}
				element={<AppLayout component={<Register />} />}
			/>

			<Route
				path={`/${PublicRoutes.Login}`}
				element={<AppLayout component={<Login />} />}
			/>

			<Route
				path={`/${PublicRoutes.ResetPassword}`}
				element={<AppLayout component={<ResetPassword />} />}
			/>

			<Route
				path={`/${PublicRoutes.VerifyAccount}`}
				element={<AppLayout component={<VerifyAccount />} />}
			/>

			<Route
				path={`/${PublicRoutes.Project}/:id`}
				element={<AppLayout secondary component={<ProjectDetails />} />}
			/>

			{/* Private Routes */}
			<Route element={<AuthGuard />}>
				<Route
					path={PrivateRoutes.Settings}
					element={<AppLayout secondary component={<Settings />} />}
				/>

				<Route
					path={PrivateRoutes.Admin}
					element={<AppLayout secondary component={<AdminPanel />} />}
				/>

				<Route
					path={PrivateRoutes.AddProperty}
					element={
						<AppLayout secondary component={<AddProperty />} />
					}
				/>

				<Route
					path={PrivateRoutes.Wallet}
					element={<AppLayout secondary component={<Wallet />} />}
				/>

				<Route
					path={PrivateRoutes.Dashboard}
					element={<AppLayout secondary component={<Dashboard />} />}
				/>

				<Route
					path={`/${PrivateRoutes.Tutorial}`}
					element={<AppLayout component={<Tutorial />} />}
				/>
				<Route
					path={`/${PrivateRoutes.HowTo}`}
					element={<AppLayout component={<HowTo />} />}
				/>
			</Route>
		</RoutesNotFound>
	);
});

export default AppRoutes;
