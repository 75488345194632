import { Stack, Text, useColorMode } from '@chakra-ui/react';

import { calculateElapsedTime } from '../../../../../utils/CalculateTime';

const SingleUpdateComponent = ({ data }) => {
	const { colorMode } = useColorMode();
	return (
		<Stack
			p='20px'
			gap='12px'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.600'
			}
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='16px'
		>
			<Text
				variant='colored'
				fontSize='16px'
				fontWeight='700'
				lineHeight='100%'
			>
				{data.update}
			</Text>
			<Text
				variant='gray'
				fontSize='10px'
				fontWeight='400'
				lineHeight='100%'
			>
				{calculateElapsedTime(data.time)}
			</Text>

			<Text fontSize='14px' fontWeight='300' lineHeight='100%'>
				{data.description}
			</Text>
		</Stack>
	);
};

export default SingleUpdateComponent;
