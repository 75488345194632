import { createIcon } from '@chakra-ui/react';

const IconExit = createIcon({
	displayName: 'IconExit',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M9.9375 12.7556H12.6042C12.9578 12.7556 13.2969 12.6151 13.547 12.365C13.797 12.115 13.9375 11.7758 13.9375 11.4222V2.08889C13.9375 1.73527 13.797 1.39613 13.547 1.14608C13.2969 0.89603 12.9578 0.755554 12.6042 0.755554H9.9375M5.27083 10.0889L1.9375 6.75555M1.9375 6.75555L5.27083 3.42222M1.9375 6.75555H9.9375'
			stroke='currentColor'
			fill='none'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconExit;
