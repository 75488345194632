// function to calculate percentage of property covered:
const propertyPercentage = project => {
	const percentage = parseInt((project?.funded * 100) / project?.totalPrize);
	return percentage;
};

// function to calculate the numbers of tokens:
const calcTokensNumber = project => {
	return Math.floor(project.totalPrize / project.tokensPrize);
};

// function to calculate the numbers of avariable tokens:
const calcAvariableTokens = project => {
	const tokensNumber = calcTokensNumber(project);
	return tokensNumber - project.tokensPurchased;
};

// function to calculate the total value of the tokens:
const calcTotalValue = (tokensPrize, tokensNumber) => {
	return tokensPrize * tokensNumber;
};

// function to calculate the number of tokens you can buy with a certain amount:
const calcBuyableTokens = (tokensPrize, totalAmount) => {
	return totalAmount / tokensPrize;
};

function formatDateLongMonth(date) {
	const day = date.getDate();
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
		date,
	);
	const year = date.getFullYear();

	return `${day} ${month} ${year}`;
}

function formatDateShort(date) {
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear();

	return `${day}/${month}/${year}`;
}

function formatMonthDate(date) {
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
		date,
	);
	const year = date.getFullYear();

	return `${month} ${year}`;
}

// function to calculate what remains to be covered:
const propertyAvariable = project => {
	return project?.totalPrize - project?.funded;
};

// function to add a point every 3 digits and a comma for decimals:
const formatNumber = num => {
	const [integerPart, decimalPart] = num.toString().split('.');
	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		'.',
	);
	const formattedNumber = decimalPart
		? `${formattedIntegerPart},${decimalPart}`
		: formattedIntegerPart;
	return formattedNumber;
};

const currencyFormatter = (
	money,
	currency = 'USD',
	currencyDisplay,
	minimumFractionDigits = 0,
) => {
	const locales = {
		USD: 'en-US',
		EUR: 'de-DE',
		JPY: 'ja-JP',
		GBP: 'en-GB',
	};
	const locale = locales[currency] || 'en-US';

	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		currencyDisplay,
		minimumFractionDigits,
		maximumFractionDigits: 2,
	});
	return formatter.format(Number(money));
};

const checkPath = (pathname, activePathnames) => {
	const rootPath = pathname.split('/')[1];
	return activePathnames.includes(rootPath);
};

export {
	propertyPercentage,
	calcTokensNumber,
	calcAvariableTokens,
	calcTotalValue,
	calcBuyableTokens,
	propertyAvariable,
	formatDateShort,
	formatDateLongMonth,
	formatMonthDate,
	formatNumber,
	currencyFormatter,
	checkPath,
};
