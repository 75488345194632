import { createIcon } from '@chakra-ui/react';

const IconMarketplace = createIcon({
	displayName: 'IconMarketplace',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.1875 1.25781H3.73295L5.43841 9.77872C5.4966 10.0717 5.65599 10.3349 5.88867 10.5222C6.12134 10.7095 6.41249 10.809 6.71114 10.8033H12.8966C13.1952 10.809 13.4864 10.7095 13.7191 10.5222C13.9517 10.3349 14.1111 10.0717 14.1693 9.77872L15.1875 4.43963H4.36932M6.91477 13.9851C6.91477 14.3365 6.62986 14.6214 6.27841 14.6214C5.92696 14.6214 5.64205 14.3365 5.64205 13.9851C5.64205 13.6336 5.92696 13.3487 6.27841 13.3487C6.62986 13.3487 6.91477 13.6336 6.91477 13.9851ZM13.9148 13.9851C13.9148 14.3365 13.6299 14.6214 13.2784 14.6214C12.927 14.6214 12.642 14.3365 12.642 13.9851C12.642 13.6336 12.927 13.3487 13.2784 13.3487C13.6299 13.3487 13.9148 13.6336 13.9148 13.9851Z'
			stroke='currentColor'
			fill='none'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconMarketplace;
