import { Icon, InputGroup, Switch } from '@chakra-ui/react';
import { IconNight, IconSunny } from '../assets/Icons';

/**
*
    @name DarkLightModeComponent
    @description Switcher component that allow us to toggle the color mode in the website
    @returns {JS.Element} DarkLightModeComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const DarkLightModeComponent = ({ colorMode, toggleColorMode }) => {
	return (
		<InputGroup w='fit-content'>
			<Switch
				isChecked={colorMode === 'dark'}
				onChange={toggleColorMode}
			/>
			{colorMode === 'dark' && (
				<Icon
					as={IconNight}
					boxSize='19px'
					color='white'
					position='absolute'
					top='50%'
					left='50%'
					transform='translate(-15%, -50%)'
					pointerEvents='none'
				/>
			)}
			{colorMode === 'light' && (
				<Icon
					as={IconSunny}
					boxSize='13px'
					color='gray.500'
					position='absolute'
					top='50%'
					left='50%'
					transform='translate(-105%, -50%)'
					pointerEvents='none'
				/>
			)}
		</InputGroup>
	);
};

export default DarkLightModeComponent;
