import { Text } from '@chakra-ui/react';

/**
*
    @name ErrorMessage
    @description Styled text used for the error messages we need to print
    @returns {JS.Element} ErrorMessage.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const ErrorMessage = ({ children }) => {
	return (
		<Text fontWeight='bold' color='red.500' textAlign='center'>
			{children}
		</Text>
	);
};

export default ErrorMessage;
