import { IconArrow } from '../../../assets/Icons';
import { onboardingImages } from '../../../assets/onboarding';
import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const MainSlide = ({ handleNext }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const logo = colorMode === 'dark' ? 'logo-dark' : 'logo-light';

	return (
		<Flex
			h='100%'
			justifyContent='space-between'
			align='center'
			px='85px'
			gap='31px'
			bg={
				colorMode === 'dark'
					? 'rgb(44, 44, 43)'
					: 'transparencyWhite.800'
			}
		>
			<Image src={onboardingImages.building} maxH='100%' />
			<Flex
				h='289px'
				w='303px'
				direction='column'
				align='center'
				justify='center'
				p='50px'
				gap='50px'
			>
				<Image
					maxW='200px'
					maxH='77px'
					src={`/assets/images/brand/${logo}.png`}
					transition='transform 0.5s, filter 0.7s'
					borderRadius='12px'
					_hover={{
						filter: 'brightness(1.3)',
					}}
					_active={{
						transform: 'scale(0.97)',
					}}
				/>

				<Button
					variant='main'
					h='33px'
					minW='89px'
					borderRadius='30px'
					px='15px'
					gap='5px'
					_active={{
						transform: 'scale(0.95) ',
					}}
					onClick={handleNext}
				>
					<IconArrow
						boxSize='14px'
						sx={{ transform: 'rotate(180deg)' }}
					/>
					<Text
						lineHeight='150% '
						fontSize='13px'
						fontWeight='400'
						letterSpacing={0.02}
					>
						{t('onboardingImages.button')}
					</Text>
				</Button>
			</Flex>
		</Flex>
	);
};

export default MainSlide;
