import { useNavigate } from 'react-router-dom';
import {
	Box,
	Flex,
	Image,
	Progress,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { formatNumber } from '../../../utils/utils';
import { currency } from '../../../data/mockdata';
import { IconBathroom, IconRooms } from '../../../assets/Icons';
import { PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
    @name InfoPropertyCard
    @description This is the important information of the property component. It is the body part of the property card.
    @returns {JS.Element} InfoPropertyCard.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const InfoPropertyCardDiscovery = ({ project, percentage, icon, dash }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const navigate = useNavigate();

	return (
		<Box
			w='100%'
			onClick={() => navigate(`/${PublicRoutes.Project}/${project.id}`)}
		>
			<Box p='10px'>
				<Flex justifyContent='space-between'>
					<Text fontSize='14px' fontWeight='semibold'>
						{project.name}
					</Text>
					<Flex alignItems='center' gap='5px'>
						<Stack
							direction='row'
							alignItems='center'
							gap='3px'
							mr='2px'
						>
							<Image src={icon} boxSize='12px' />
							<Text fontSize='10' fontWeight='300'>
								{project.city}
							</Text>
						</Stack>
						<Stack direction='row' alignItems='center' gap='3px'>
							<Text
								variant='gray'
								fontSize='10px'
								fontWeight='400'
							>
								{project.bedrooms}
							</Text>

							<IconRooms
								boxSize='11px'
								alignSelf='center'
								color={
									colorMode === 'dark'
										? 'transparencyWhite.500'
										: 'transparencyBlack.600'
								}
							/>
						</Stack>
						<Stack direction='row' alignItems='center' gap='3px'>
							<Text
								variant='gray'
								fontSize='10px'
								fontWeight='400'
							>
								{project.bathrooms}
							</Text>
							<IconBathroom
								boxSize='11px'
								alignSelf='center'
								color={
									colorMode === 'dark'
										? 'transparencyWhite.500'
										: 'transparencyBlack.600'
								}
							/>
						</Stack>
					</Flex>
				</Flex>
			</Box>
			{!dash && (
				<Box p='10px'>
					<Flex justifyContent='space-between' alignItems='end'>
						<Text
							variant='colored'
							fontSize='10px'
							fontWeight='400'
						>
							{percentage}
							{t('infoPropertyCard.funded')}
						</Text>
						<Flex gap='2px' alignItems='flex-end'>
							<Text
								variant='colored'
								fontSize='10px'
								fontWeight='400'
								lineHeight='20px'
								marginBottom='-1px'
							>
								{currency[1].symbol}
							</Text>
							<Text
								variant='gray'
								fontSize='14px'
								fontWeight='400'
								lineHeight='20px'
							>
								{formatNumber(project.totalPrize)}
							</Text>
						</Flex>
					</Flex>
					<Progress
						value={percentage}
						borderRadius='25px'
						h='5px'
						colorScheme='brand'
					/>
					<Flex justifyContent='space-around' mt='10px'>
						<Flex
							flexDirection='column'
							alignItems='center'
							justifyContent='center'
							gap='3px'
						>
							<Text variant='gray' fontSize='12' fontWeight='400'>
								{t('infoPropertyCard.estProjectDuration')}
							</Text>
							<Text fontSize='14' fontWeight={'medium'}>
								{project.projectDuration}{' '}
								{t('infoPropertyCard.months')}
							</Text>
						</Flex>
						<Flex
							flexDirection='column'
							alignItems='center'
							gap='3px'
						>
							<Text variant='gray' fontSize='12' fontWeight='400'>
								ROI
							</Text>
							<Text
								variant='colored'
								fontSize='14'
								fontWeight={'medium'}
							>
								{project.roi}
							</Text>
						</Flex>
					</Flex>
				</Box>
			)}
			{dash && (
				<Flex justifyContent='center' gap='30px'>
					<Stack alignItems='center' gap='4px'>
						<Text variant='gray' fontSize='12'>
							Total tokens
						</Text>
						<Text fontSize='14'>20</Text>
					</Stack>
					<Stack alignItems='center' gap='4px'>
						<Text variant='gray' fontSize='12'>
							{t('infoPropertyCard.totalInvestment')}
						</Text>
						<Text fontSize='14'>2.300 €</Text>
					</Stack>
				</Flex>
			)}
		</Box>
	);
};

export default InfoPropertyCardDiscovery;
