// Validation for the register component
const validateRegistrationInfo = ({
	fullname,
	username,
	email,
	password,
	passConfirm,
}) => {
	let error = false;

	if (fullname.trim() === '') {
		error = {
			message: 'Full name cannot be empty',
			field: 'fullname',
		};
	} else if (fullname.length < 4 || fullname.length > 35) {
		error = {
			message: 'Full name must be between 4 and 35 characters',
			field: 'fullname',
		};
	} else if (username.trim() === '') {
		error = {
			message: 'Username cannot be empty',
			field: 'username',
		};
	} else if (username.length < 4 || username.length > 16) {
		error = {
			message: 'Username must be between 4 and 16 characters',
			field: 'username',
		};
	} else if (!validateEmail(email)) {
		error = {
			message: 'The email is not valid',
			field: 'email',
		};
	} else if (email.trim() === '') {
		error = {
			message: 'Email cannot be empty',
			field: 'email',
		};
	} else if (password.trim() === '' || passConfirm.trim() === '') {
		error = {
			message: 'Password cannot be empty',
			field: 'password',
		};
	} else if (password !== passConfirm) {
		error = {
			message: 'Passwords do not match',
			field: 'password',
		};
	} else if (password.length < 8) {
		error = {
			message: 'Password must be at least 8 characters',
			field: 'password',
		};
	}

	return error;
};

// Validation for the edit user
export const validateEditInfo = ({ fullname, username, email }) => {
	let error = false;

	if (fullname.trim() === '') {
		error = 'Full name cannot be empty';
	} else if (username.trim() === '') {
		error = 'Username cannot be empty';
	} else if (fullname.length < 4 || fullname.length > 35) {
		error = 'Full name must be between 4 and 35 characters';
	} else if (username.length < 4 || username.length > 16) {
		error = 'Username must be between 4 and 16 characters';
	} else if (!validateEmail(email)) {
		error = 'The email is not valid';
	} else if (email.trim() === '') {
		error = 'Email cannot be empty';
	}

	return error;
};

// Validation for the email
const validateEmail = email => {
	const re = /\S+@\S+\.\S+/;
	if (!re.test(email)) {
		return false;
	}

	return true;
};

// Validation for the passwords
const validatePasswordConfirm = (pass, passConfirm) => {
	let error;
	if (pass.trim() === '' || passConfirm.trim() === '') {
		error = 'Password cannot be empty';
	} else if (pass !== passConfirm) {
		error = 'Passwords do not match';
	} else if (pass.length < 8) {
		error = 'Password must be at least 8 characters';
	}

	return error;
};

// Validation for change the passwords
const validatePasswordChange = pass => {
	let error;
	if (pass.trim() === '') {
		error = 'Password cannot be empty';
	} else if (pass.length < 8) {
		error = 'Password must be at least 8 characters';
	}
	return error;
};

/**
 * Return null if image is valid. Return {error: string, field: string} if image is different to jpeg, png or jpg.
 * @param {*} img
 * @returns
 */
const validateImage = img => {
	const mimeTypes = ['image/jpeg', 'image/png', 'image/jpg'];

	if (!mimeTypes.includes(img.type)) {
		return {
			message: 'The image must be a .jpeg, .jpg or .png file',
			field: 'image',
		};
	}

	return null;
};

export {
	validateRegistrationInfo,
	validateEmail,
	validatePasswordConfirm,
	validatePasswordChange,
	validateImage,
};
