import { IconUSDC, IconEther, IconUSDT } from '../assets/Icons';

/* -------- Discovery -------- */

// Filters:

const alphabeticalFilters = ['a-z', 'z-a'];
const statusFilters = ['open', 'soldOut', 'ended', 'upComming'];
const typesFilters = ['rental', 'purchased'];
const oldFilters = ['NewestToOldest', 'OldestToNewest'];
const priceFilters = ['PriceHighToLow', 'PriceLowToHigh'];

/* -------- Wallet -------- */

// SendTokensComponent:

const tokenList = [
	{
		token: 'Ethereum',
		abbr: 'ETH',
		icon: IconEther,
	},
	{
		token: 'Tether',
		abbr: 'USDT',
		icon: IconUSDT,
	},
	{
		token: 'USD Coin',
		abbr: 'USDC',
		icon: IconUSDC,
	},
];

/* -------- AddProperty -------- */

// Info tab menu options:
const purchasedOrPledgeOptions = ['Purchased', 'Pledge'];
const projectStatusOptions = [
	'Upcoming',
	'Open',
	'Funded-Open Market',
	'Cancelled-Refund',
	'Ended-Burned',
];
const projectCategoryOptions = ['Rental', 'Flip', 'Build'];
const buildSubcategories = ['Build to rent', 'Build to sell'];
const rentalSubcategories = [
	'Buy to rent',
	'Buy & renovate to rent',
	'Off-plan to rent',
];
const flipSubcategories = [
	'Buy to sell',
	'Buy & renovate to sell',
	'Off-plan to sell',
];

const distributionFrequencyOptions = [
	'Monthy',
	'Quaternary',
	'Annualy',
	'Date of sale',
];

const propertyTypeOptions = [
	'Villa',
	'Apartment',
	'Building',
	'Townhouse',
	'Cottage',
	'Hotel',
];

export {
	alphabeticalFilters,
	statusFilters,
	typesFilters,
	oldFilters,
	priceFilters,
	tokenList,
	purchasedOrPledgeOptions,
	projectStatusOptions,
	projectCategoryOptions,
	buildSubcategories,
	rentalSubcategories,
	flipSubcategories,
	distributionFrequencyOptions,
	propertyTypeOptions,
};
