import { createIcon } from '@chakra-ui/react';

const IconBack = createIcon({
	displayName: 'IconBack',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.32292 10.3975L4.98958 8.06421M4.98958 8.06421L7.32292 5.73088M4.98958 8.06421L10.8229 8.06421M0.90625 8.06421C0.90625 11.9302 4.04026 15.0642 7.90625 15.0642C11.7722 15.0642 14.9062 11.9302 14.9062 8.06421C14.9062 4.19822 11.7722 1.06421 7.90625 1.06421C4.04026 1.06421 0.90625 4.19822 0.90625 8.06421Z'
			stroke='currentColor'
			fill='none'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconBack;
