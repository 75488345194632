import { createIcon } from '@chakra-ui/react';

const IconStaking = createIcon({
	displayName: 'IconStaking',
	viewBox: '0 0 15 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.24365 11.2312L7.81265 14.5157L14.3817 11.2312M1.24365 7.94669L7.81265 11.2312L14.3817 7.94669M7.81265 1.37769L1.24365 4.66219L7.81265 7.94669L14.3817 4.66219L7.81265 1.37769Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconStaking;
