import {
	Text,
	Flex,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	useColorMode,
} from '@chakra-ui/react';
import { IconArrowDown } from '../../../../assets/Icons';
import { useTranslation } from 'react-i18next';

const MenuROIComponent = ({
	valueInTokens,
	setValueInTokens,
	setValueInput,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const handleTokenClick = () => {
		if (!valueInTokens) {
			setValueInput('');
			setValueInTokens(true);
		}
	};

	const handleCoinClick = () => {
		if (valueInTokens) {
			setValueInput('');
			setValueInTokens(false);
		}
	};

	return (
		<Menu variant={'withoutBg'}>
			<MenuButton bg='none' _hover='none' _active='none'>
				<Flex align='center' gap='5px'>
					<Text whiteSpace='nowrap'>
						{valueInTokens
							? t(
									'projectDetails.offerDetails.calculatorROI.menuROI.projectToken',
								)
							: 'EUR €'}
					</Text>
					<IconArrowDown
						boxSize='15px'
						color={
							colorMode === 'dark'
								? 'transparencyWhite.900'
								: 'transparencyBlack.700'
						}
					/>
				</Flex>
			</MenuButton>
			<MenuList>
				<MenuItem onClick={handleTokenClick}>
					<Flex flexDirection='column' gap='5px' p='10px'>
						<Text variant='colored' fontWeight='600'>
							{' '}
							{t(
								'projectDetails.offerDetails.calculatorROI.menuROI.projectToken',
							)}
						</Text>
						<Text fontSize='11'>
							{t(
								'projectDetails.offerDetails.calculatorROI.menuROI.projectTokenDescription',
							)}
						</Text>
					</Flex>
				</MenuItem>
				<MenuDivider />
				<MenuItem onClick={handleCoinClick}>
					<Flex flexDirection='column' gap='5px' p='5px'>
						<Text variant='colored' fontWeight='600'>
							{' '}
							EUR €
						</Text>
						<Text fontSize='11'>
							{t(
								'projectDetails.offerDetails.calculatorROI.menuROI.divisaDescription',
							)}
						</Text>
					</Flex>
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default MenuROIComponent;
