import { createIcon } from '@chakra-ui/react';

const IconTimeline = createIcon({
	displayName: 'IconTimeline',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M6.10605 11.3377H9.70605M4.66605 1.43774H11.1461C11.4821 1.43774 11.6501 1.43774 11.7784 1.50314C11.8913 1.56066 11.9831 1.65245 12.0407 1.76535C12.1061 1.8937 12.1061 2.06171 12.1061 2.39774V3.64245C12.1061 3.93596 12.1061 4.08272 12.0729 4.22082C12.0435 4.34327 11.995 4.46032 11.9292 4.56769C11.855 4.68879 11.7512 4.79256 11.5437 5.0001L9.78488 6.75892C9.54727 6.99653 9.42846 7.11534 9.38395 7.25233C9.34479 7.37284 9.34479 7.50265 9.38395 7.62315C9.42846 7.76015 9.54727 7.87896 9.78488 8.11657L11.5437 9.87539C11.7512 10.0829 11.855 10.1867 11.9292 10.3078C11.995 10.4152 12.0435 10.5322 12.0729 10.6547C12.1061 10.7928 12.1061 10.9395 12.1061 11.233V12.4777C12.1061 12.8138 12.1061 12.9818 12.0407 13.1101C11.9831 13.223 11.8913 13.3148 11.7784 13.3723C11.6501 13.4377 11.4821 13.4377 11.1461 13.4377H4.66605C4.33002 13.4377 4.16201 13.4377 4.03366 13.3723C3.92076 13.3148 3.82897 13.223 3.77145 13.1101C3.70605 12.9818 3.70605 12.8138 3.70605 12.4777V11.233C3.70605 10.9395 3.70605 10.7928 3.73921 10.6547C3.76861 10.5322 3.81709 10.4152 3.88289 10.3078C3.9571 10.1867 4.06087 10.0829 4.26841 9.87539L6.02723 8.11657C6.26484 7.87896 6.38365 7.76015 6.42816 7.62315C6.46732 7.50265 6.46732 7.37284 6.42816 7.25233C6.38365 7.11534 6.26484 6.99653 6.02723 6.75892L4.26841 5.0001C4.06087 4.79256 3.9571 4.68879 3.88289 4.56769C3.81709 4.46032 3.76861 4.34327 3.73921 4.22082C3.70605 4.08272 3.70605 3.93596 3.70605 3.64245V2.39774C3.70605 2.06171 3.70605 1.8937 3.77145 1.76535C3.82897 1.65245 3.92076 1.56066 4.03366 1.50314C4.16201 1.43774 4.33002 1.43774 4.66605 1.43774Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconTimeline;
