import { createIcon } from '@chakra-ui/react';

const IconUSDC = createIcon({
	displayName: 'IconUSDC',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M6.061 7.6485V7.73953C6.061 8.26091 6.48307 8.68298 7.00445 8.68298H8.0141C8.53548 8.68298 8.95755 8.26091 8.95755 7.73953C8.95755 7.31746 8.68031 6.94919 8.27479 6.83332L6.7479 6.39884C6.34238 6.28298 6.06514 5.9147 6.06514 5.49263C6.06514 4.97126 6.48721 4.54919 7.00859 4.54919H8.01824C8.53962 4.54919 8.96169 4.97126 8.96169 5.49263V5.58367M7.50928 9.51057V8.68298M7.50928 4.54505V3.71746M6.061 2.5216C4.37273 3.11746 3.16445 4.72298 3.16445 6.61401C3.16445 8.50505 4.37273 10.1106 6.061 10.7064M8.95755 10.7064C10.6458 10.1106 11.8541 8.50505 11.8541 6.61401C11.8541 4.72298 10.6458 3.11746 8.95755 2.5216M13.5093 6.61401C13.5093 9.92772 10.823 12.614 7.50928 12.614C4.19557 12.614 1.50928 9.92772 1.50928 6.61401C1.50928 3.30031 4.19557 0.614014 7.50928 0.614014C10.823 0.614014 13.5093 3.30031 13.5093 6.61401Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeMiterlimit='10'
		/>
	),
});

export default IconUSDC;
