import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react';

import { IconArrow } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';

const SlideTemplateComponent = ({
	image,
	header,
	isLastSlide,
	handleNext,
	closeOnboardingModal,
	isMobile,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	return (
		<>
			<Flex
				h='100%'
				w='100%'
				gap='0px'
				bg={
					colorMode === 'dark'
						? 'rgb(44, 44, 43)'
						: 'transparencyWhite.800'
				}
				overflow='hidden'
			>
				<Image
					width='442px'
					height='100%'
					src={image}
					sx={{
						maskImage:
							'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
					}}
				/>

				<Flex
					w='417px'
					direction='column'
					align='center'
					justify='center'
					p='50px'
					gap='30px'
				>
					<Text
						textAlign='center'
						variant='colored'
						fontWeight='bold'
						fontSize={isMobile ? '20px' : '32px'}
						letterSpacing={-0.02}
						lineHeight='20px'
					>
						{t(`onboardingImages.${header}.title`)}
					</Text>
					<Text lineHeight='25px' fontWeight='regular'>
						{t(`onboardingImages.${header}.info`)}
					</Text>
					<Button
						variant='main'
						h='33px'
						minW={!isLastSlide ? '89px' : '150px'}
						borderRadius='30px'
						px='15px'
						gap='5px'
						_active={{
							transform: 'scale(0.95) ',
						}}
						onClick={
							isLastSlide ? closeOnboardingModal : handleNext
						}
					>
						<IconArrow
							boxSize='14px'
							sx={{ transform: 'rotate(180deg)' }}
						/>
						<Text
							lineHeight='150% '
							fontSize='13px'
							fontWeight='400'
							letterSpacing={0.02}
						>
							{!isLastSlide
								? t('onboardingImages.button')
								: t('onboardingImages.finish')}
						</Text>
					</Button>
				</Flex>
			</Flex>
		</>
	);
};

export default SlideTemplateComponent;
