import {
	Button,
	Flex,
	FormLabel,
	Grid,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import ModalComponent from '../../../components/ModalComponent';

import { useState } from 'react';
import { IconPassword, IconUnview, IconView } from '../../../assets/Icons';
import { validatePasswordChange } from '../../../utils/validations/authValidations';
import { changePassword } from '../../../services/userService';

const initialState = {
	oldPassword: '',
	newPassword: '',
};

const ChangePassModal = ({
	isOpen,
	onClose,
	openSuccessfulModal,
	userData,
}) => {
	const { colorMode } = useColorMode();
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [password, setPassword] = useState(initialState);
	const [errorMsg, setErrorMsg] = useState();

	const toggleShowOldPassWord = () => {
		setShowOldPassword(!showOldPassword);
	};
	const toggleShowNewPassWord = () => {
		setShowNewPassword(!showNewPassword);
	};

	const handleChange = event => {
		const { name, value } = event.target;
		if (errorMsg) {
			setErrorMsg(undefined);
		}
		setPassword({ ...password, [name]: value });
	};

	const onSubmit = () => {
		const error = validatePasswordChange(password.newPassword);
		if (error) {
			setErrorMsg(error);
		} else {
			changePassword(userData?.email, password).then(response => {
				if (response.success) {
					onClose();
					openSuccessfulModal();
				} else {
					setErrorMsg(response.message);
				}
			});
		}
	};

	return (
		<ModalComponent isOpen={isOpen} onClose={onClose} modalCross={false}>
			<Flex
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.900'
				}
				flexDirection='column'
				align='center'
				borderRadius='12px'
				p='21px'
				gap='26px'
			>
				<Text
					variant='colored'
					fontSize='20px'
					fontWeight='bold'
					height='20px'
				>
					Change Password
				</Text>

				<Grid
					w='100%'
					gridTemplateColumns='1fr 1fr'
					justifyContent='space-between'
					gap='12px'
				>
					<FormLabel
						display='flex'
						alignItems='center'
						m='0'
						gap='10px'
					>
						<IconPassword boxSize='21px' />

						<Text
							fontWeight='600'
							fontSize='14px'
							letterSpacing={-0.02}
							whiteSpace='nowrap'
						>
							Old Password
						</Text>
					</FormLabel>
					<InputGroup w='288px'>
						<Input
							variant={errorMsg ? 'error' : 'withBorder'}
							h='40px'
							w='100%'
							type={showOldPassword ? 'text' : 'password'}
							fontSize='12px'
							placeholder='Insert your old password'
							_placeholder={{
								fontWeight: '400',
								color: 'transparencyWhite.500',
							}}
							name='oldPassword'
							onChange={handleChange}
							autoFocus
						/>
						<InputRightElement>
							<IconButton
								icon={
									showOldPassword ? (
										<IconView />
									) : (
										<IconUnview />
									)
								}
								onClick={toggleShowOldPassWord}
								bg='none'
								_hover={{ bg: 'none' }}
							/>
						</InputRightElement>
					</InputGroup>
					<FormLabel
						display='flex'
						alignItems='center'
						m='0'
						gap='10px'
					>
						<IconPassword boxSize='21px' />
						<Text
							fontWeight='600'
							fontSize='14px'
							letterSpacing={-0.02}
							whiteSpace='nowrap'
						>
							New Password
						</Text>
					</FormLabel>
					<InputGroup w='288px'>
						<Input
							variant={errorMsg ? 'error' : 'withBorder'}
							h='40px'
							w='100%'
							type={showNewPassword ? 'text' : 'password'}
							fontSize='12px'
							placeholder='Insert your new password'
							_placeholder={{
								fontWeight: '400',
								color: 'transparencyWhite.500',
							}}
							name='newPassword'
							onChange={handleChange}
						/>
						<InputRightElement>
							<IconButton
								icon={
									showNewPassword ? (
										<IconView />
									) : (
										<IconUnview />
									)
								}
								onClick={toggleShowNewPassWord}
								bg='none'
								_hover={{ bg: 'none' }}
							/>
						</InputRightElement>
					</InputGroup>
					{errorMsg && (
						<Text
							gridColumn='span 2'
							color='negative'
							fontSize='14px'
							fontWeight='700'
							lineHeight='20px'
						>
							{errorMsg}
						</Text>
					)}
				</Grid>
				<Flex gap='50px'>
					<Button
						variant='secondary'
						fontSize='13px'
						border='1px solid'
						borderColor={
							colorMode === 'dark' ? 'brand.200' : 'brand.500'
						}
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						variant='main'
						onClick={onSubmit}
						isDisabled={
							password.newPassword === '' ||
							password.oldPassword === ''
						}
						gap='5px'
					>
						<IconPassword boxSize='21px' />
						Save changes
					</Button>
				</Flex>
			</Flex>
		</ModalComponent>
	);
};

export default ChangePassModal;
