import { Grid } from '@chakra-ui/react';
import PropertyCardComponent from '../../../../components/ProjectCardComponent/PropertyCardComponent';

const CardTokenGallery = ({ data, isTablet, isMobile }) => {
	let templateGallery;

	if (isTablet) {
		if (!isMobile) {
			templateGallery = 'repeat(2, minmax(0, 1fr))';
		}
	} else {
		templateGallery = 'repeat(3, minmax(0, 1fr))';
	}

	return (
		<Grid templateColumns={templateGallery} gap='20px'>
			{data.map(project => {
				return (
					<PropertyCardComponent key={project.id} project={project} />
				);
			})}
		</Grid>
	);
};

export default CardTokenGallery;
