import { createIcon } from '@chakra-ui/react';

const IconTransactions = createIcon({
	displayName: 'IconTransactions',
	viewBox: '0 0 15 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.0625 2.29619V6.29619M1.0625 6.29619H4.66275M1.0625 6.29619L3.8467 3.38952C4.6753 2.47047 5.7503 1.8755 6.90972 1.69427C8.06914 1.51303 9.25017 1.75535 10.2749 2.3847C11.2995 3.01405 12.1124 3.99635 12.5909 5.18359C13.0694 6.37082 13.1877 7.69868 12.9279 8.96709C12.6681 10.2355 12.0443 11.3757 11.1505 12.216C10.2567 13.0563 9.14132 13.5511 7.97242 13.6258C6.80352 13.7006 5.64441 13.3512 4.66975 12.6304C3.69509 11.9096 2.95767 10.8564 2.56861 9.62952'
			stroke='currentColor'
			fill='none'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconTransactions;
