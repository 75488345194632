export const languages = [
	{
		code: 'en',
		name: 'English',
		label: 'english',
		value: 'english',
		flag: 'https://img.freepik.com/vector-gratis/ilustracion-bandera-reino-unido_53876-18166.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1723334400&semt=ais_hybrid',
	},
	{
		code: 'es',
		name: 'Español',
		label: 'spanish',
		value: 'spanish',
		flag: 'https://img.freepik.com/vector-gratis/ilustracion-bandera-espana_53876-18168.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1723248000&semt=ais_hybrid',
	},
];
