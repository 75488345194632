import { createIcon } from '@chakra-ui/react';

const IconRooms = createIcon({
	displayName: 'IconRooms',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M7.91332 12.3636C6.00551 12.3636 4.09819 12.3636 2.19038 12.3636C1.99749 12.3636 1.90831 12.4643 1.90831 12.6814C1.90831 12.7377 1.91082 12.794 1.90781 12.8496C1.90381 12.9172 1.89779 12.9847 1.88677 13.0505C1.84569 13.2907 1.64379 13.4831 1.4023 13.4803C1.15982 13.4774 0.958916 13.2721 0.922343 13.0083C0.913826 12.9464 0.909317 12.8828 0.909317 12.8204C0.908816 11.5423 0.901301 10.2636 0.911822 8.98553C0.917834 8.24072 1.15932 7.59267 1.62224 7.05318C1.9033 6.72578 2.23747 6.49289 2.61974 6.34438C2.92034 6.22793 3.23096 6.19249 3.54759 6.19249C6.48196 6.19362 9.41633 6.1908 12.3507 6.19474C13.2104 6.19587 13.9068 6.58515 14.4228 7.35921C14.6763 7.74005 14.8276 8.17265 14.8792 8.64519C14.8968 8.80439 14.9043 8.9664 14.9048 9.12729C14.9068 10.3683 14.9063 11.6092 14.9058 12.8502C14.9058 13.0443 14.8632 13.2209 14.7209 13.3492C14.5706 13.4848 14.4028 13.5202 14.2239 13.4375C14.0526 13.3576 13.9444 13.2108 13.9198 13.001C13.9073 12.8952 13.9088 12.7866 13.9063 12.6798C13.9023 12.5071 13.8387 12.403 13.7114 12.3709C13.6834 12.3636 13.6538 12.3642 13.6247 12.3642C11.7214 12.3642 9.81713 12.3636 7.91332 12.3636Z'
				fill='currentColor'
			/>
			<path
				d='M7.84368 1.14478C9.08116 1.14478 10.3181 1.14478 11.5551 1.14478C11.9694 1.14478 12.3567 1.24885 12.6909 1.53631C13.1207 1.9059 13.3717 2.3925 13.3928 3.00342C13.4108 3.52153 13.4028 4.04076 13.4048 4.55942C13.4063 4.89358 13.4048 5.22773 13.4048 5.56188C13.4048 5.59169 13.4033 5.6232 13.3963 5.65189C13.3747 5.74246 13.3312 5.77846 13.249 5.76102C13.1593 5.74189 13.0726 5.70083 12.983 5.68958C12.772 5.6637 12.5606 5.6457 12.3487 5.63332C12.2074 5.62488 12.1638 5.59619 12.1438 5.44037C12.0797 4.94645 11.6723 4.50204 11.1513 4.50879C10.5701 4.51611 9.98897 4.51048 9.40831 4.51048C8.97595 4.51048 8.60671 4.79963 8.45992 5.25529C8.43887 5.32055 8.43086 5.39143 8.41983 5.46006C8.39929 5.58326 8.35871 5.62938 8.24949 5.63107C8.02054 5.63388 7.79108 5.63388 7.56212 5.63107C7.45691 5.62995 7.41533 5.58326 7.39629 5.44656C7.36973 5.25473 7.3036 5.08372 7.19539 4.93352C6.99949 4.66124 6.74599 4.51329 6.43336 4.51161C5.83366 4.50823 5.23396 4.50992 4.63376 4.51104C4.12976 4.51161 3.73547 4.95377 3.67134 5.43924C3.6508 5.59619 3.60922 5.62376 3.46793 5.63388C3.23397 5.65132 3 5.67608 2.76653 5.7042C2.69489 5.71264 2.62625 5.74752 2.55511 5.76102C2.47845 5.77508 2.44288 5.74583 2.42134 5.66145C2.41282 5.62826 2.40882 5.59282 2.40882 5.55794C2.40831 4.75631 2.3988 3.95469 2.41082 3.15306C2.42334 2.31431 2.76853 1.69776 3.44389 1.32479C3.71142 1.17797 4.0015 1.14478 4.29509 1.14478C5.47795 1.14478 6.66082 1.14478 7.84368 1.14478Z'
				fill='currentColor'
			/>
		</>
	),
});

export default IconRooms;
