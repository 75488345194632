import LoginComponent from '../../components/LoginComponent';

/**
*
    @name Login
    @description It is the Login page, it has validations for when the email doesn't have any "@" 
				 and the password i shorter that 8 characters
    @returns {JS.Element} index.js
    @author Saguado | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const Login = () => {
	return <LoginComponent />;
};

export default Login;
