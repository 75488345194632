import { createIcon } from '@chakra-ui/react';

const IconPriceHighToLow = createIcon({
	displayName: 'IconPriceHighToLow',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',

	path: (
		<>
			<path
				d='M1.125 1.87683C0.848858 1.87683 0.625 2.10069 0.625 2.37683C0.625 2.65297 0.848858 2.87683 1.125 2.87683H15.1875C15.4636 2.87683 15.6875 2.65297 15.6875 2.37683C15.6875 2.10069 15.4636 1.87683 15.1875 1.87683H1.125Z'
				fill='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.46811 7.56433V8.97895L4.86861 9.10778C5.391 9.27611 5.77989 9.51118 6.03528 9.81301C6.29647 10.1148 6.42707 10.4834 6.42707 10.9187C6.42707 11.0335 6.41794 11.1446 6.39967 11.2518H10.5C10.7761 11.2518 11 11.4757 11 11.7518C11 12.028 10.7761 12.2518 10.5 12.2518H5.85359C5.69992 12.3949 5.51961 12.5165 5.31264 12.6165C5.06217 12.7354 4.78066 12.8108 4.46811 12.8427V13.6265H3.68452V12.8325C3.43405 12.7992 3.19317 12.7359 2.96188 12.6426C2.60781 12.4917 2.30018 12.2799 2.03899 12.0071C1.78359 11.7342 1.59205 11.4121 1.46436 11.0406L2.57008 10.5444C2.73261 10.8984 2.95607 11.177 3.24049 11.3802C3.38163 11.479 3.52964 11.5538 3.68452 11.6045V10.0752L3.2579 9.9349C2.74131 9.76657 2.35242 9.5286 2.09122 9.22097C1.83003 8.90753 1.69943 8.53315 1.69943 8.09783C1.69943 7.71474 1.7923 7.37809 1.97804 7.08787C2.16958 6.79765 2.43368 6.57128 2.77033 6.40876C3.04364 6.27906 3.34837 6.20111 3.68452 6.17492V5.3988H4.46811V6.20806C4.66219 6.24126 4.84793 6.29365 5.02532 6.36523C5.16533 6.42172 5.29702 6.48809 5.42039 6.56433H12.8438C13.1199 6.56433 13.3438 6.78819 13.3438 7.06433C13.3438 7.34047 13.1199 7.56433 12.8438 7.56433H6.3249C6.36272 7.64334 6.39678 7.7254 6.42707 7.81051L5.33005 8.30678C5.20816 8.00496 5.02532 7.76988 4.78154 7.60155C4.7635 7.58867 4.74527 7.57626 4.72685 7.56433H4.46811ZM3.28683 7.53021C3.23098 7.57317 3.1836 7.62307 3.14471 7.67991C3.06926 7.78439 3.03153 7.90628 3.03153 8.04559C3.03153 8.1965 3.08087 8.33 3.17954 8.44609C3.27821 8.55637 3.42622 8.64343 3.62357 8.70728L3.68452 8.72689V7.56433H3.46875C3.40457 7.56433 3.34321 7.55224 3.28683 7.53021ZM4.46811 10.3328V11.6611C4.5437 11.6466 4.61336 11.6255 4.67706 11.5978C4.81056 11.5398 4.91504 11.4614 4.9905 11.3628C5.06595 11.2583 5.10368 11.1364 5.10368 10.9971C5.10368 10.8462 5.05725 10.7185 4.96438 10.614C4.87151 10.5037 4.7264 10.4167 4.52905 10.3528L4.46811 10.3328Z'
				fillRule='evenodd'
				clipRule='evenodd'
				fill='currentColor'
			/>
		</>
	),
});

export default IconPriceHighToLow;
