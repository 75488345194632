import { createIcon } from '@chakra-ui/react';

const IconArrowDown = createIcon({
	displayName: 'IconArrowDown',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.84375 4.06421L7.84375 10.0642L13.8438 4.06421'
			stroke='currentColor'
			fill='none'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconArrowDown;
