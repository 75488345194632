import {
	Card,
	CardBody,
	Flex,
	Image,
	useBoolean,
	useOutsideClick,
} from '@chakra-ui/react';

import { SpainCircle } from '../../assets/countries';
import InfoPropertyCard from './InfoPropertyCard';
import PropertyCardHover from './PropertyCardHover';
import { propertyPercentage } from '../../utils/utils';
import { useRef } from 'react';

/**
*
    @name PropertyCardComponent
    @description It is the component that shows the photos and the information of the property in a Card format.
    @returns {JS.Element} PropertyCardComponent.js
    @Version 1.0.0
*/
const PropertyCardComponent = ({ project }) => {
	const imgRef = useRef();
	const [projectMenuView, setProjectMenuView] = useBoolean(false);
	useOutsideClick({
		ref: imgRef,
		handler: () => setProjectMenuView.off(),
	});
	return (
		<>
			{project && (
				<Card borderRadius='12px'>
					<CardBody p='0' w='100%'>
						<Flex
							ref={imgRef}
							position='relative'
							overflow='hidden'
							borderTopRadius='12px'
							alignItems='center'
							justifyContent='center'
							onMouseEnter={() => setProjectMenuView.on()}
							onMouseLeave={() => setProjectMenuView.off()}
						>
							<Image
								src={`/assets/images/houses/${project.img[0].url}`}
								h='250px'
								w='312px'
								filter={projectMenuView ? 'blur(30px)' : 'none'}
							/>
							{projectMenuView && (
								<PropertyCardHover projectId={project.id} />
							)}
						</Flex>

						<InfoPropertyCard
							project={project}
							percentage={propertyPercentage(project)}
							icon={SpainCircle}
						/>
					</CardBody>
				</Card>
			)}
		</>
	);
};

export default PropertyCardComponent;
