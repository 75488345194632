import { createIcon } from '@chakra-ui/react';

const IconTrade = createIcon({
	displayName: 'IconTrade',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M8.82395 4.751C8.48049 4.99585 8.06019 5.13988 7.60625 5.13988C6.44645 5.13988 5.50625 4.19968 5.50625 3.03988C5.50625 1.88008 6.44645 0.93988 7.60625 0.93988C8.35804 0.93988 9.01757 1.33493 9.38855 1.92877M4.30625 11.7922H5.87242C6.07663 11.7922 6.27958 11.8165 6.47754 11.8651L8.13239 12.2672C8.49146 12.3547 8.86552 12.3632 9.22833 12.2927L11.058 11.9367C11.5414 11.8426 11.986 11.6111 12.3345 11.2722L13.629 10.0129C13.9987 9.6539 13.9987 9.07134 13.629 8.71172C13.2961 8.38794 12.7691 8.35149 12.3925 8.62607L10.8838 9.72679C10.6677 9.88473 10.4048 9.96978 10.1344 9.96978H8.67755L9.60489 9.96974C10.1276 9.96974 10.551 9.55788 10.551 9.04943V8.86537C10.551 8.44318 10.2556 8.07506 9.8347 7.973L8.40341 7.62493C8.17048 7.56843 7.93193 7.53988 7.69213 7.53988C7.11325 7.53988 6.06539 8.01917 6.06539 8.01917L4.30625 8.75481M12.7063 3.63988C12.7063 4.79968 11.766 5.73988 10.6063 5.73988C9.44645 5.73988 8.50625 4.79968 8.50625 3.63988C8.50625 2.48008 9.44645 1.53988 10.6063 1.53988C11.766 1.53988 12.7063 2.48008 12.7063 3.63988ZM1.90625 8.49988L1.90625 11.9799C1.90625 12.3159 1.90625 12.4839 1.97165 12.6123C2.02917 12.7252 2.12096 12.817 2.23386 12.8745C2.3622 12.9399 2.53022 12.9399 2.86625 12.9399H3.34625C3.68228 12.9399 3.8503 12.9399 3.97864 12.8745C4.09154 12.817 4.18333 12.7252 4.24085 12.6123C4.30625 12.4839 4.30625 12.3159 4.30625 11.9799V8.49988C4.30625 8.16385 4.30625 7.99583 4.24085 7.86749C4.18333 7.75459 4.09154 7.6628 3.97864 7.60528C3.8503 7.53988 3.68228 7.53988 3.34625 7.53988L2.86625 7.53988C2.53022 7.53988 2.3622 7.53988 2.23386 7.60528C2.12096 7.6628 2.02917 7.75459 1.97165 7.86749C1.90625 7.99583 1.90625 8.16385 1.90625 8.49988Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconTrade;
