import { createIcon } from '@chakra-ui/react';

const IconNotification = createIcon({
	displayName: 'IconNotification',
	viewBox: '0 0 15 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.96289 2.83534C6.83267 2.83534 5.74873 3.28432 4.94954 4.08351C4.15035 4.8827 3.70137 5.96664 3.70137 7.09687V10.1408C3.70137 10.6252 3.50895 11.0897 3.16644 11.4323C2.82393 11.7748 2.35938 11.9672 1.875 11.9672H14.0508C13.5664 11.9672 13.1019 11.7748 12.7594 11.4323C12.4168 11.0897 12.2244 10.6252 12.2244 10.1408V7.09687C12.2244 5.96664 11.7754 4.8827 10.9762 4.08351C10.1771 3.28432 9.09312 2.83534 7.96289 2.83534ZM7.96289 2.83534V1.00897M9.0161 14.4023C8.90907 14.5869 8.75544 14.74 8.5706 14.8465C8.38577 14.9529 8.1762 15.009 7.96289 15.009C7.74959 15.009 7.54002 14.9529 7.35518 14.8465C7.17035 14.74 7.01672 14.5869 6.90969 14.4023'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.5'
		/>
	),
});

export default IconNotification;
