import { createIcon } from '@chakra-ui/react';

const IconEther = createIcon({
	displayName: 'IconEther',
	viewBox: '0 0 15 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.69287 0.640137L3.1875 7.75117M7.69287 0.640137L12.1875 7.72703M7.69287 0.640137V10.2953M3.1875 7.75117L7.69287 10.2953M3.1875 7.75117L7.69287 5.85393L12.1875 7.72703M7.69287 10.2953L12.1875 7.72703M7.69287 14.6401V11.5505M7.69287 14.6401L3.1875 9.00634L7.69287 11.5505M7.69287 14.6401L12.1875 8.98221L7.69287 11.5505'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='0.5'
			strokeMiterlimit='10'
		/>
	),
});

export default IconEther;
