import { Grid } from '@chakra-ui/react';

/**
*
    @name GridCenterContainer
    @description Little container that center the content in the middle of the screen with a grid.
    @returns {JS.Element} GridCenterContainer.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const GridCenterContainer = ({ w, textAlign, children }) => {
	return (
		<Grid placeContent='center' h='100%' w={w} textAlign={textAlign}>
			{children}
		</Grid>
	);
};

export default GridCenterContainer;
