import { Box, Text, useColorMode } from '@chakra-ui/react';

const BoxCountdownUnitComponent = ({ children }) => {
	const { colorMode } = useColorMode();
	return (
		<Box
			h={'30px'}
			w={'51px'}
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.100'
			}
			display='flex'
			justifyContent='center'
			alignItems='center'
			borderRadius='5px'
		>
			<Text fontWeight='600' fontSize='14px' lineHeight='150%'>
				{children}
			</Text>
		</Box>
	);
};

export default BoxCountdownUnitComponent;
