import {
	Card,
	CardHeader,
	CardBody,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';

const CardBoxComponent = ({
	title,
	pyCardBody = '25px',
	minHeight = 'fix-content',
	children,
}) => {
	const { colorMode } = useColorMode();
	const theme = useTheme();
	return (
		<Card
			minH={minHeight}
			w='100%'
			maxW='358px'
			minW={{ base: '300px', sm: '358px' }}
			boxShadow='none'
			border={
				colorMode === 'dark'
					? 'none'
					: `1px solid ${theme.colors.transparencyBlack[200]}`
			}
			alignItems='center'
			borderRadius='12px'
		>
			<CardHeader
				w='100%'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.950'
				}
				borderBottom='1px solid'
				borderColor='transparencyBlack.50'
				textAlign='center'
				fontSize={20}
				fontWeight='700'
				borderTopRadius={12}
				p='10px'
			>
				{title}
			</CardHeader>
			<CardBody
				py={pyCardBody}
				px='20px'
				w='100%'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.50'
						: 'transparencyWhite.100'
				}
			>
				{children}
			</CardBody>
		</Card>
	);
};

export default CardBoxComponent;
