import { useRef, useState } from 'react';

import MainSlide from './slides/MainSlide';
import SlideTemplateComponent from './slides/SlideTemplateComponent';
import { onboardingSlides } from '../../data/mockdata';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {
	AbsoluteCenter,
	Box,
	HStack,
	Stack,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';
import { changeIsFirstTime } from '../../services/userService';
import { useSelector } from 'react-redux';

/**
*
    @name OnboardingComponent
    @description A Slider component where you can see the onboarding instruction of the website.
    @returns {JS.Element} OnboardingComponent.js
    @Version 1.0.0
*/

const OnboardingComponent = ({ closeOnboardingModal }) => {
	const theme = useTheme();
	const { colorMode } = useColorMode();
	const email = useSelector(store => store.user.email);
	const swiperRef = useRef(null);
	const [isLastSlide, setIsLastSlide] = useState(false);
	const [stepSlide, setStepSlide] = useState(0);
	const totalSlide = onboardingSlides.length + 1;

	const bulletBackgroundActive =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];
	const bulletBackground =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[950]
			: theme.colors.transparencyWhite[800];

	const handleNext = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
			setStepSlide(stepSlide + 1);
		}
	};

	const handleSlideChange = swiper => {
		setIsLastSlide(swiper.activeIndex === swiper.slides.length - 1);
		setStepSlide(swiper.activeIndex);
	};
	const handleSlideClick = index => {
		if (swiperRef.current) {
			swiperRef.current.slideTo(index);
		}
	};

	const handlerCloseOnboarding = () => {
		const closeOnboarding = async () => {
			await changeIsFirstTime(email);
			closeOnboardingModal();
		};

		closeOnboarding();
	};

	return (
		<AbsoluteCenter>
			<Stack
				w='860px'
				justify='center'
				align='center'
				borderRadius='12px'
				overflow='hidden'
				gap='20px'
			>
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y]}
					spaceBetween={0}
					slidesPerView={1}
					onSwiper={swiper => (swiperRef.current = swiper)}
					onSlideChange={handleSlideChange}
					style={{
						width: '100%',
						height: '100%',
						borderWidth: '0.5px',
						borderStyle: 'solid',
						borderColor: theme.colors.transparencyBlack[100],
						borderRadius: '12px',
					}}
				>
					<SwiperSlide>
						<Box h='470px' w='860px' position='relative'>
							<MainSlide handleNext={handleNext} />
						</Box>
					</SwiperSlide>

					{onboardingSlides.map((slide, index) => (
						<SwiperSlide
							style={{
								borderRadius: '50px',
							}}
							key={index}
						>
							<Box
								h='470px'
								w='860px'
								position='relative'
								rounded='12px'
							>
								<SlideTemplateComponent
									image={slide.image}
									header={slide.header}
									info={slide.info}
									isLastSlide={isLastSlide}
									handleNext={handleNext}
									closeOnboardingModal={
										handlerCloseOnboarding
									}
								/>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>

				<HStack spacing='7px'>
					{Array.from({ length: totalSlide }).map((_, i) => (
						<Box
							key={i}
							boxSize={stepSlide === i ? '8px' : '6px'}
							rounded='full'
							backgroundColor={
								stepSlide === i
									? bulletBackgroundActive
									: bulletBackground
							}
							cursor='pointer'
							onClick={() => handleSlideClick(i)}
						/>
					))}
				</HStack>
			</Stack>
		</AbsoluteCenter>
	);
};

export default OnboardingComponent;
