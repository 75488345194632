import { createIcon } from '@chakra-ui/react';

const IconEdit = createIcon({
	displayName: 'IconEdit',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M4.26041 9.11808C4.29982 8.98015 4.31953 8.91119 4.34906 8.84687C4.37528 8.78975 4.40723 8.73545 4.44442 8.68479C4.48631 8.62774 4.53703 8.57702 4.63846 8.47559L10.6349 2.47915C11.276 1.83801 12.3155 1.83801 12.9566 2.47913C13.5978 3.12027 13.5978 4.15978 12.9566 4.80093L6.96019 10.7973C6.85876 10.8988 6.80804 10.9495 6.75099 10.9914C6.70033 11.0286 6.64603 11.0605 6.58891 11.0867C6.52459 11.1163 6.45563 11.136 6.31771 11.1754L3.4375 11.9983L4.26041 9.11808Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconEdit;
