import { Flex, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { IconTokenNFT, IconList } from '../../../../assets/Icons';

import { projectListData } from '../../../../data/mockdata';
import CardTokenGallery from './CardTokenGallery';
import ListTokenGallery from './ListTokenGallery';

const ProjectTokensComponent = ({ isTablet, isMobile }) => {
	const [filter, setFilter] = useState('1');
	const handleChange = event => {
		setFilter(event.target.value);
	};

	return (
		<Stack
			gap='25px'
			gridColumn={{ lg: 'span 2' }}
			w={{ base: '100%', lg: '976px' }}
			justify='center'
			align='center'
		>
			<Text alignSelf='start' fontWeight='700' fontSize='32px'>
				Project tokens
			</Text>

			<RadioGroup alignSelf='start' value={filter}>
				<Radio
					value='1'
					variant='leftRounded'
					onChange={handleChange}
					p='10px'
				>
					<Flex gap='5px'>
						<IconTokenNFT boxSize='12px' />
						<Text
							fontSize='12px'
							fontWeight='400'
							lineHeight='100%'
						>
							Icon
						</Text>
					</Flex>
				</Radio>
				<Radio
					value='2'
					variant='rightRounded'
					onChange={handleChange}
					p='10px'
				>
					<Flex gap='5px'>
						<IconList boxSize='12px' />
						<Text
							fontSize='12px'
							fontWeight='400'
							lineHeight='100%'
						>
							List
						</Text>
					</Flex>
				</Radio>
			</RadioGroup>

			{filter === '1' && (
				<CardTokenGallery
					data={projectListData}
					isTablet={isTablet}
					isMobile={isMobile}
				/>
			)}
			{filter === '2' && (
				<ListTokenGallery data={projectListData} isTablet={isTablet} />
			)}
		</Stack>
	);
};

export default ProjectTokensComponent;
