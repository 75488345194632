import SuccesfulRegister from '../../Register/components/SuccesfulRegister';
import ChangePasswordResetPassword from '../../ResetPassword/components/ChangePasswordResetPassword';
import SuccesfulResetPassword from '../../ResetPassword/components/SuccesfulResetPassword';
import ValidateEmailResetPassword from '../../ResetPassword/components/ValidateEmailResetPassword';
import CheckEmailAndVerifyMagic from '../../../components/MagicLinkComponent/CheckEmailAndVerifyMagic';

export const sliderVerifySteps = [
	{
		component: ValidateEmailResetPassword,
		props: {
			type: 'verify',
		},
	},
	{
		component: CheckEmailAndVerifyMagic,
		props: {
			transform: 'translateY(50px)',
		},
	},
	{
		component: SuccesfulRegister,
		props: {
			transform: 'translateY(50px)',
		},
	},
];

export const sliderPasswordSteps = [
	{
		component: ValidateEmailResetPassword,
		props: {
			type: 'password',
		},
	},
	{
		component: ChangePasswordResetPassword,
	},
	{
		component: SuccesfulResetPassword,
		props: {
			transform: 'translateY(50px)',
		},
	},
];

export const sliderMagicSteps = [
	{
		component: CheckEmailAndVerifyMagic,
		props: {
			type: 'magic',
			transform: 'translateY(50px)',
		},
	},
];
