import {
	Menu,
	MenuButton,
	MenuDivider,
	MenuList,
	useColorMode,
} from '@chakra-ui/react';

import SubmenuComponent from './SubmenuComponent';
import { useNavigate } from 'react-router-dom';
import { resetUser } from '../../redux/states/user';
import { useDispatch } from 'react-redux';
import { PublicRoutes, PrivateRoutes, subMenuLinks } from '../../routes/Routes';
import useMagic from '../../hooks/useMagic';
import { IconExit, IconHelp, IconInvest } from '../../assets/Icons';
import { logout } from '../../services/authService';
import { useTranslation } from 'react-i18next';

const MenuComponent = ({ button }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	const dispatch = useDispatch();
	const { logoutWithMagicLink } = useMagic();

	const navigate = useNavigate();

	const handleNavigate = route => {
		navigate(route);
	};

	const handleLogOut = async () => {
		try {
			await logout();
			await logoutWithMagicLink();
			dispatch(resetUser());

			navigate(`${PublicRoutes.Home}`, { replace: true });
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Menu variant='simple'>
			<MenuButton p={0} my='-1px'>
				{button}
			</MenuButton>
			<MenuList zIndex={10} minW='145px' maxW='145px' border='none'>
				{subMenuLinks.map((link, idx) => (
					<SubmenuComponent
						icon={link.icon}
						label={t(`subMenu.${link.title.toLocaleLowerCase()}`)}
						key={idx}
						onClick={() => handleNavigate(link.to)}
					/>
				))}
				<MenuDivider
					color={
						colorMode === 'dark'
							? 'transparencyWhite.300'
							: 'transparencyBlack.300'
					}
					m={0}
				/>
				<SubmenuComponent
					icon={IconHelp}
					label={t(`subMenu.tutorial`)}
					onClick={() => navigate(`/${PrivateRoutes.Tutorial}`)}
				/>

				<SubmenuComponent
					icon={IconInvest}
					label={t(`subMenu.howTo`)}
					onClick={() => navigate(`/${PrivateRoutes.HowTo}`)}
				/>
				<SubmenuComponent
					icon={IconExit}
					label={t(`subMenu.logout`)}
					onClick={handleLogOut}
				/>
			</MenuList>
		</Menu>
	);
};

export default MenuComponent;
