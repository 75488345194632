import { Flex, MenuItem, useColorMode } from '@chakra-ui/react';

const SubmenuComponent = ({ icon, label, onClick }) => {
	const { colorMode } = useColorMode();
	const Icon = icon;
	return (
		<MenuItem onClick={onClick}>
			<Flex gap={3} fontSize={'12px'}>
				<Icon
					w={'12px'}
					color={colorMode === 'dark' ? 'white' : 'black'}
				/>
				{label}
			</Flex>
		</MenuItem>
	);
};

export default SubmenuComponent;
