import InfoSummaryComponent from './InfoSummaryComponent';
import FinancialSummaryComponent from './FinancialSummaryComponent';
import { useTranslation } from 'react-i18next';

const FinalPriceComponent = ({ data }) => {
	const { t } = useTranslation();
	return (
		<FinancialSummaryComponent
			title={t('projectDetails.projectMoreInfo.finalPrice.title')}
		>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.finalPrice.publicOffer',
				)}
				amount={data.offer}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.finalPrice.capitalGain',
				)}
				amount={data.gain}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.finalPrice.minSelling',
				)}
				amount={data.sellingPrice}
				total
			/>
		</FinancialSummaryComponent>
	);
};

export default FinalPriceComponent;
