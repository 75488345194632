import { Flex, useColorMode, useTheme } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { useRef, useState } from 'react';
import MainSlideMobile from './slides/MainSlideMobile';
import SlideTemplateComponentMobile from './slides/SlideTemplateComponentMobile';
import { onboardingSlides } from '../../data/mockdata';

const OnboardingComponentMobile = ({ closeOnboardingModal }) => {
	const swiperRef = useRef(null);
	const { colorMode } = useColorMode();
	const [isLastSlide, setIsLastSlide] = useState(false);

	const theme = useTheme();

	const bulletColor =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[800]
			: theme.colors.transparencyBlack[200];
	const bulletColorActive =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];

	const handleNext = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	const handleSlideChange = swiper => {
		setIsLastSlide(swiper.activeIndex === swiper.slides.length - 1);
	};
	return (
		<Flex zIndex={15} bg={colorMode === 'dark' ? '#1e1e1f' : 'white'}>
			<Swiper
				modules={[Navigation, Pagination, Scrollbar, A11y]}
				spaceBetween={30}
				slidesPerView={1}
				onSwiper={swiper => (swiperRef.current = swiper)}
				pagination={{ clickable: true }}
				onSlideChange={handleSlideChange}
				style={{
					width: '100vw',
					height: '100vh',
					padding: '0',
					margin: '0',
				}}
			>
				<SwiperSlide
					style={{
						padding: '0',
						margin: '0',
					}}
				>
					<MainSlideMobile handleNext={handleNext} />
				</SwiperSlide>
				{onboardingSlides.map((slide, index) => (
					<SwiperSlide
						key={index}
						style={{
							padding: '0',
							margin: '0',
						}}
					>
						<SlideTemplateComponentMobile
							image={slide.image}
							header={slide.header}
							info={slide.info}
							isLastSlide={isLastSlide}
							handleNext={handleNext}
							closeOnboardingModal={closeOnboardingModal}
							isMobile={true}
						/>
					</SwiperSlide>
				))}
			</Swiper>
			<style>
				{`
						.swiper-pagination {
							transform: translate(0px, -5px);
							text-align: center;
							}
							.swiper-pagination-bullet {
								background: ${bulletColor}  ;
								opacity: 1 ;
								width: 5px ;
								height: 5px ;
								
								
						}
						.swiper-pagination-bullet-active {
							background: ${bulletColorActive} ;
							width: 8px ;
							height: 8px ;
							margin: -1px 4px !important;
							

						}
					`}
			</style>
		</Flex>
	);
};

export default OnboardingComponentMobile;
