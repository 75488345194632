import { AspectRatio, Stack, Text } from '@chakra-ui/react';
import FaqsComponent from './FaqsComponent';
import MapComponent from './MapComponent/OlayersMapComponent';
import DetailsComponent from './DetailsComponent';
import { useTranslation } from 'react-i18next';

const DetailsTab = ({ data }) => {
	const { t } = useTranslation();

	return (
		<Stack direction='column' gap='30px'>
			<DetailsComponent
				title={t('projectDetails.projectMoreInfo.detailsTab.title')}
				data={data}
			>
				<Text>{data.details}</Text>
			</DetailsComponent>
			<DetailsComponent
				title={t('projectDetails.projectMoreInfo.detailsTab.location')}
				data={data}
			>
				{/* Para que se visualice el mapa de google hay que envolver el MapComponent dentro del AspectRatio */}
				<AspectRatio ratio={16 / 9}>
					<MapComponent data={data} />
				</AspectRatio>
			</DetailsComponent>
			<DetailsComponent title='FAQs' data={data}>
				<FaqsComponent />
			</DetailsComponent>
		</Stack>
	);
};

export default DetailsTab;
