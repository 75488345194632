import { useState, useEffect } from 'react';

/**
*
    @name useCountdownTimer
    @description It is a custom hook for a countdown in days, hours, minutes and seconds. It updates once per second.
    @returns {Object} An object containing days, hours, minutes, and seconds remaining. If endDate has passed, all values will be 0. Example: { days: 0, hours: 1, minutes: 23, seconds: 45 }
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const useCountdownTimer = (endDate) => {
    const calculateTimeLeft = (endDate) => {
        const difference = new Date(endDate) - new Date();
        if (difference > 0) {
            return {
                days: parseInt(difference / (1000 * 60 * 60 * 24)),
                hours: parseInt((difference / (1000 * 60 * 60)) % 24),
                minutes: parseInt((difference / 1000 / 60) % 60),
                seconds: parseInt((difference / 1000) % 60),
            };
        } else {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    };

    const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(endDate)); // When you reload the page, the time remaining until the endDate appears by default
	
	useEffect(() => { // The time remaining until the endDate is updated once per second.
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft(endDate));
		}, 1000);
		
		return () => clearInterval(timer);
	}, [endDate]); 

    return timeLeft;
};

export default useCountdownTimer;
