import { createIcon } from '@chakra-ui/react';

const IconArrow = createIcon({
	displayName: 'IconArrow',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M8.125 10.689L5.325 7.88904M5.325 7.88904L8.125 5.08904M5.325 7.88904H10.925M1.125 7.88904C1.125 11.755 4.25901 14.889 8.125 14.889C11.991 14.889 15.125 11.755 15.125 7.88904C15.125 4.02304 11.991 0.889038 8.125 0.889038C4.25901 0.889038 1.125 4.02304 1.125 7.88904Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconArrow;
