import { Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import { format } from 'date-fns';
import { currencyFormatter } from '../../../utils/utils';
import { IconArrow } from '../../../assets/Icons';

/**
*
    @name TransactionInfoComponent
    @description Component that shows the information about 1 transaction. It changes the icon and the color
				 of the money amount depending of the type of the transaction. 0 = sell, 1 = buy
	@returns {JSX.Element} TransactionInfoComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TransactionInfoComponent = ({ data }) => {
	const theme = useTheme();
	const colorSell = useColorModeValue(
		theme.colors.light.primary,
		theme.colors.dark.primary,
	);

	const formatedDate = format(data.date, "dd MMMM yyyy, 'at' hh:mm a");
	const formatedPrice =
		data.type === 1
			? `-${currencyFormatter(data.price)} `
			: `+${currencyFormatter(data.price)} `;

	return (
		<Flex justifyContent='space-between' alignItems='center' gap='3px'>
			<Flex alignItems='center' gap={{ base: '20px', sm: '40px' }}>
				<IconArrow
					color={data.type === 1 ? 'red' : colorSell}
					boxSize='35px'
					style={{
						transform: `rotate(${data.type === 1 ? '270deg' : '90deg'})`,
					}}
				/>
				<Stack gap='3px'>
					<Text fontSize={14} lineHeight='140%'>
						{data.name}
					</Text>
					<Text variant='gray' fontSize='12px' fontWeight={'300'}>
						{formatedDate}
					</Text>
				</Stack>
			</Flex>
			<Text variant={data.type === 1 ? 'gray' : 'colored'} fontSize={14}>
				{formatedPrice}
			</Text>
		</Flex>
	);
};

export default TransactionInfoComponent;
