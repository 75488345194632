import InfoSummaryComponent from './InfoSummaryComponent';
import FinancialSummaryComponent from './FinancialSummaryComponent';
import { useTranslation } from 'react-i18next';

const YieldsComponent = ({ data }) => {
	const { t } = useTranslation();

	return (
		<FinancialSummaryComponent
			title={t('projectDetails.projectMoreInfo.yields.title')}
		>
			<InfoSummaryComponent
				header={t('projectDetails.projectMoreInfo.yields.anualReturn')}
				amount={data.return}
				end
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.yields.returnInvestment',
				)}
				amount={data.roi}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.yields.distributionFrequency',
				)}
				amount={data.distribution}
				string
			/>
		</FinancialSummaryComponent>
	);
};

export default YieldsComponent;
