import { createIcon } from '@chakra-ui/react';

const IconNewestToOldest = createIcon({
	displayName: 'IconNewestToOldest',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M3.40625 7.06433H12.7812M1.0625 2.37683H15.125M5.75 11.7518H10.4375'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconNewestToOldest;
