import { createIcon } from '@chakra-ui/react';

const IconList = createIcon({
	displayName: 'IconList',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M13.7813 6.56205L5.78125 6.56205M13.7813 2.56205L5.78125 2.56205M13.7813 10.5621L5.78125 10.5621M3.11458 6.56205C3.11458 6.93024 2.81611 7.22872 2.44792 7.22872C2.07973 7.22872 1.78125 6.93024 1.78125 6.56205C1.78125 6.19386 2.07973 5.89539 2.44792 5.89539C2.81611 5.89539 3.11458 6.19386 3.11458 6.56205ZM3.11458 2.56205C3.11458 2.93024 2.81611 3.22872 2.44792 3.22872C2.07973 3.22872 1.78125 2.93024 1.78125 2.56205C1.78125 2.19386 2.07973 1.89539 2.44792 1.89539C2.81611 1.89539 3.11458 2.19386 3.11458 2.56205ZM3.11458 10.5621C3.11458 10.9302 2.81611 11.2287 2.44792 11.2287C2.07973 11.2287 1.78125 10.9302 1.78125 10.5621C1.78125 10.1939 2.07973 9.89539 2.44792 9.89539C2.81611 9.89539 3.11458 10.1939 3.11458 10.5621Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconList;
