import { createIcon } from '@chakra-ui/react';

const IconCopy = createIcon({
	displayName: 'IconCopy',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M9.49375 9.83762V11.5176C9.49375 12.1897 9.49375 12.5257 9.36296 12.7824C9.24791 13.0082 9.06433 13.1918 8.83854 13.3068C8.58185 13.4376 8.24581 13.4376 7.57375 13.4376H3.01375C2.34169 13.4376 2.00566 13.4376 1.74896 13.3068C1.52317 13.1918 1.33959 13.0082 1.22454 12.7824C1.09375 12.5257 1.09375 12.1897 1.09375 11.5176V6.95762C1.09375 6.28556 1.09375 5.94953 1.22454 5.69283C1.33959 5.46704 1.52317 5.28346 1.74896 5.16841C2.00566 5.03762 2.34169 5.03762 3.01375 5.03762H4.69375M6.61375 9.83762H11.1737C11.8458 9.83762 12.1818 9.83762 12.4385 9.70683C12.6643 9.59178 12.8479 9.40821 12.963 9.18241C13.0938 8.92572 13.0938 8.58969 13.0938 7.91762V3.35762C13.0938 2.68556 13.0938 2.34953 12.963 2.09283C12.8479 1.86704 12.6643 1.68346 12.4385 1.56841C12.1818 1.43762 11.8458 1.43762 11.1737 1.43762H6.61375C5.94169 1.43762 5.60566 1.43762 5.34896 1.56841C5.12317 1.68346 4.93959 1.86704 4.82454 2.09283C4.69375 2.34953 4.69375 2.68556 4.69375 3.35762V7.91762C4.69375 8.58969 4.69375 8.92572 4.82454 9.18241C4.93959 9.40821 5.12317 9.59178 5.34896 9.70683C5.60566 9.83762 5.94169 9.83762 6.61375 9.83762Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconCopy;
