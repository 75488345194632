import { useState } from 'react';
import GridCenterContainer from '../../../components/GridCenterContainer';
import BoxComponent from '../../../components/BoxComponent';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link, Stack, Text } from '@chakra-ui/react';
import InputComponent from '../../../components/InputComponent';
import ButtonComponent from '../../../components/ButtonComponent';
import ErrorMessage from '../../../components/ErrorMessage';
import { validateEmail } from '../../../utils/validations/authValidations';
import { resetPassword } from '../../../services/userService';
import { PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
	@name SendEmailResetPassword
	@description Component that send allow you to write the recovery email. Website send a recovery email
	@returns {JS.Element} SendEmailResetPassword.js
	@author Edunknown | Unknown Gravity - All-in-one Blockchain Company
	@Version 1.0.0
*/

const SendEmailResetPassword = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [errorMsg, setErrorMsg] = useState();

	const handleChange = event => {
		if (errorMsg) {
			setErrorMsg(undefined);
		}
		setEmail(event.target.value);
	};

	const handleSubmit = () => {
		const error = validateEmail(email);

		if (error) {
			resetPassword(email)
				.then(response => {
					if (!response.success) {
						setErrorMsg(response.message);
					} else {
						navigate(`/${PublicRoutes.VerifyAccount}`, {
							state: {
								userInfo: { email },
								type: 'password',
							},
						});
					}
				})
				.catch(() => {
					setErrorMsg('Something went wrong');
				});
		} else {
			setErrorMsg('Enter a valid email');
		}
	};

	return (
		<GridCenterContainer>
			<BoxComponent
				headerMsg={t('sendEmailResetPassword.headerMsg')}
				explainMsg={t('sendEmailResetPassword.explainMsg')}
				transform='translateY(-73px)'
				w={{ base: null, sm: '328px' }}
				headerFontSize='20px'
			>
				<Stack spacing='30px'>
					<InputComponent
						label={t('sendEmailResetPassword.inputEmail.label')}
						placeholder={t(
							'sendEmailResetPassword.inputEmail.placeholder',
						)}
						value={email}
						name='email'
						errorMsg={errorMsg}
						onChange={handleChange}
					/>
					<Stack>
						<ButtonComponent
							w='100%'
							type='main'
							onClick={handleSubmit}
						>
							{t('sendEmailResetPassword.button')}
						</ButtonComponent>
						<Text
							textAlign='center'
							fontWeight='400'
							fontSize='12px'
							lineHeight='140%'
						>
							{t('sendEmailResetPassword.question')}{' '}
							<Link fontWeight='bold' as={NavLink} to='/login'>
								{t('sendEmailResetPassword.link')}
							</Link>
						</Text>
					</Stack>
					{errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
				</Stack>
			</BoxComponent>
		</GridCenterContainer>
	);
};

export default SendEmailResetPassword;
