import { createIcon } from '@chakra-ui/react';

const IconTrash = createIcon({
	displayName: 'IconTrash',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M5.15625 1.43774H9.15625M1.15625 3.43774H13.1563M11.8229 3.43774L11.3554 10.4506C11.2852 11.5028 11.2502 12.0289 11.0229 12.4277C10.8229 12.7789 10.5211 13.0613 10.1574 13.2375C9.74423 13.4377 9.21698 13.4377 8.16248 13.4377H6.15002C5.09552 13.4377 4.56827 13.4377 4.15514 13.2375C3.79142 13.0613 3.48964 12.7789 3.28957 12.4277C3.06232 12.0289 3.02725 11.5028 2.95711 10.4506L2.48958 3.43774M5.82292 6.43774V9.77108M8.48958 6.43774V9.77108'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconTrash;
