const calculateElapsedTime = data => {
	const msPerMinute = 60 * 1000;
	const msPerHour = msPerMinute * 60;
	const msPerDay = msPerHour * 24;
	const msPerWeek = msPerDay * 7;
	const msPerMonth = msPerDay * 30;
	const msPerYear = msPerDay * 365;

	const elapsed = new Date() - data.getTime();
	let time = '';

	const minutes = Math.round(elapsed / msPerMinute);
	const hours = Math.round(elapsed / msPerHour);
	const days = Math.round(elapsed / msPerDay);
	const weeks = Math.round(elapsed / msPerWeek);
	const months = Math.round(elapsed / msPerMonth);
	const years = Math.round(elapsed / msPerYear);

	if (hours < 1) {
		time = minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
	} else if (hours >= 1 && hours <= 24) {
		time = hours === 1 ? '1 hour ago' : `${hours} hours ago`;
	} else if (hours > 24 && days < 7) {
		time = days === 1 ? '1 day ago' : `${days} days ago`;
	} else if (days >= 7 && days <= 31) {
		time = weeks === 1 ? '1 week ago' : `${weeks} weeks ago`;
	} else if (days > 31 && days < 365) {
		time = months === 1 ? '1 month ago' : `${months} months ago`;
	} else if (days >= 365) {
		time = years === 1 ? '1 year ago' : `${years} years ago`;
	}
	return time;
};

const calculateRemainingTime = data => {
	const msPerMinute = 60 * 1000;
	const msPerHour = msPerMinute * 60;
	const msPerDay = msPerHour * 24;
	const msPerWeek = msPerDay * 7;
	const msPerMonth = msPerDay * 30;
	const msPerYear = msPerDay * 365;

	const remaining = data.getTime() - new Date().getTime();
	let time = '';

	const minutes = Math.round(remaining / msPerMinute);
	const hours = Math.round(remaining / msPerHour);
	const days = Math.round(remaining / msPerDay);
	const weeks = Math.round(remaining / msPerWeek);
	const months = Math.round(remaining / msPerMonth);
	const years = Math.round(remaining / msPerYear);

	if (hours < 1) {
		time = minutes === 1 ? 'in 1 minute ago' : `in ${minutes} minutes`;
	} else if (hours >= 1 && hours <= 24) {
		time = hours === 1 ? 'in 1 hour' : `in ${hours} hours`;
	} else if (hours > 24 && days < 7) {
		time = days === 1 ? 'in 1 day' : `in ${days} days`;
	} else if (days >= 7 && days <= 31) {
		time = weeks === 1 ? 'in 1 week' : `in ${weeks} weeks`;
	} else if (days > 31 && days < 365) {
		time = months === 1 ? 'in 1 month' : `in ${months} months ago`;
	} else if (days >= 365) {
		time = years === 1 ? 'in 1 year' : `in ${years} years`;
	}
	return time;
};

export { calculateElapsedTime, calculateRemainingTime };
