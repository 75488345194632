import { createIcon } from '@chakra-ui/react';

const IconView = createIcon({
	displayName: 'IconView',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M1.0625 6.59762C1.0625 6.59762 3.60795 1.50671 8.0625 1.50671C12.517 1.50671 15.0625 6.59762 15.0625 6.59762C15.0625 6.59762 12.517 11.6885 8.0625 11.6885C3.60795 11.6885 1.0625 6.59762 1.0625 6.59762Z'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M8.0625 8.50671C9.11686 8.50671 9.97159 7.65198 9.97159 6.59762C9.97159 5.54326 9.11686 4.68853 8.0625 4.68853C7.00814 4.68853 6.15341 5.54326 6.15341 6.59762C6.15341 7.65198 7.00814 8.50671 8.0625 8.50671Z'
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</>
	),
});

export default IconView;
