import { createIcon } from '@chakra-ui/react';

const IconAZ = createIcon({
	displayName: 'IconAZ',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M10.5056 9.74448V8.71365L13.3012 4.71401H10.6705V3.60071H14.9176V4.63154L12.1302 8.63118H15V9.74448H10.5056Z'
				fill='currentColor'
			/>
			<path
				d='M6.5242 8.02911V6.95704H10.2352V8.02911H6.5242Z'
				fill='currentColor'
			/>
			<path
				d='M0.393555 9.74448L2.47172 3.60071H4.17053L6.24869 9.74448H4.85501L4.44267 8.49099H2.19133L1.779 9.74448H0.393555ZM2.54594 7.37769H4.08807L3.14794 4.47486H3.4943L2.54594 7.37769Z'
				fill='currentColor'
			/>
		</>
	),
});

export default IconAZ;
