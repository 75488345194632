import { Box, Text, Flex, useColorMode } from '@chakra-ui/react';

/**
*
    @name BoxSmallComponent
    @description A component that displays a small box with a title, content, and optional children.
    @returns {JSX.Element} A styled box component with optional child components.
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const BoxSmallComponent = ({ textTitle, textContent, w, avatar, children }) => {
	const { colorMode } = useColorMode();
	const bgColor =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.600';

	return (
		<Flex
			bg={avatar ? '' : bgColor}
			border={avatar ? 'none' : '1px solid'}
			borderColor='transparencyBlack.200'
			borderRadius={10}
			width={w ? { w } : 'fit-content'}
			align='center'
			px='10px'
			py='5px'
			gap='15px'
		>
			{children}

			<Box align={children ? 'start' : 'center'} justify='center'>
				<Text whiteSpace='nowrap' variant='small'>
					{textTitle}
				</Text>
				<Text
					whiteSpace='nowrap'
					fontWeight='semibold'
					fontSize={children && !avatar ? '20px' : '13px'}
				>
					{textContent}
				</Text>
			</Box>
		</Flex>
	);
};

export default BoxSmallComponent;
