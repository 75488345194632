import { createIcon } from '@chakra-ui/react';

const IconPaidUser = createIcon({
	displayName: 'IconPaidUser',
	viewBox: '0 0 16 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M12.3125 0.873779V11.8738M14.3958 2.87378H11.2708C10.8841 2.87378 10.5131 3.05815 10.2396 3.38634C9.96615 3.71453 9.8125 4.15965 9.8125 4.62378C9.8125 5.08791 9.96615 5.53303 10.2396 5.86122C10.5131 6.1894 10.8841 6.37378 11.2708 6.37378H13.3542C13.7409 6.37378 14.1119 6.55815 14.3854 6.88634C14.6589 7.21453 14.8125 7.65965 14.8125 8.12378C14.8125 8.58791 14.6589 9.03303 14.3854 9.36122C14.1119 9.68941 13.7409 9.87378 13.3542 9.87378H9.8125'
				fill='none'
				stroke='currentColor'
				strokeWidth='0.7'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.65485 3.67377C7.18707 3.15788 6.53372 2.87378 5.81259 2.87378C5.08761 2.87378 4.4321 3.15616 3.96648 3.66886C3.49582 4.18722 3.2665 4.89169 3.32035 5.6524C3.42708 7.15319 4.54507 8.37405 5.81259 8.37405C7.0801 8.37405 8.19618 7.15343 8.30459 5.65289C8.35915 4.89906 8.12839 4.19605 7.65485 3.67377Z'
				fill='currentColor'
			/>
			<path
				d='M10.0432 13.8737H1.58185C1.4711 13.8752 1.36142 13.8514 1.26079 13.8041C1.16016 13.7569 1.07112 13.6873 1.00013 13.6004C0.843887 13.4096 0.780908 13.1491 0.827541 12.8856C1.03042 11.736 1.66358 10.7702 2.65874 10.0923C3.54285 9.49044 4.66277 9.15919 5.8125 9.15919C6.96223 9.15919 8.08215 9.49068 8.96626 10.0923C9.96142 10.77 10.5946 11.7357 10.7975 12.8854C10.8441 13.1489 10.7811 13.4094 10.6249 13.6002C10.5539 13.6871 10.4649 13.7567 10.3642 13.804C10.2636 13.8514 10.1539 13.8752 10.0432 13.8737Z'
				fill='currentColor'
			/>
		</>
	),
});

export default IconPaidUser;
