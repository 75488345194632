import GridCenterContainer from '../../../components/GridCenterContainer';
import { Stack, Text, useColorMode } from '@chakra-ui/react';
import { IconCheck2 } from '../../../assets/Icons';

import ButtonComponent from '../../../components/ButtonComponent';
import BoxComponent from '../../../components/BoxComponent';
import { useDispatch } from 'react-redux';
import { createUser } from '../../../redux/states/user';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
@name SuccesfulRegister
@description Component that show us an alert when the process of changing the password is done and correct.
@returns {JS.Element} SuccesfulResetPassword.js
@author Edunknown | Unknown Gravity - All-in-one Blockchain Company
@Version 1.0.0
*/
const SuccesfulRegister = ({ userInfo }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const goToOnboarding = () => {
		const data = userInfo?.dataValues || userInfo;

		dispatch(
			createUser({
				...data,
				avatarImage: userInfo?.avatarImage,
			}),
		);

		navigate(`${PublicRoutes.Home}`, { replace: true });
	};

	return (
		<GridCenterContainer>
			<BoxComponent
				w={{ base: 'fit-content', md: '450px' }}
				transform={'translateY(-72px)'}
			>
				<Stack alignItems='center'>
					<Text textAlign='center' fontWeight='bold' fontSize={23}>
						{t('succesfulRegister.title')}
					</Text>
					<Text textAlign='center'>
						{t('succesfulRegister.subtitle')}
					</Text>
					<IconCheck2
						color={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
						boxSize='60px'
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyBlack.100'
						}
						borderRadius='50%'
						p={4}
					/>
				</Stack>
				<ButtonComponent type='main' mt='25px' onClick={goToOnboarding}>
					{t('succesfulRegister.button')}
				</ButtonComponent>
			</BoxComponent>
		</GridCenterContainer>
	);
};

export default SuccesfulRegister;
