import {
	Button,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorMode,
} from '@chakra-ui/react';

import { IconArrowDown } from '../assets/Icons';

const MenuSelectComponent = ({
	w = '100%',
	options,
	fieldName,
	informationTabInfo = {},
	setInformationTabInfo,
}) => {
	const { colorMode } = useColorMode();

	const selectedOption = informationTabInfo[fieldName] || options[0];
	const handleItemClick = elem => {
		if (fieldName === 'category') {
			setInformationTabInfo(prevState => ({
				...prevState,
				[fieldName]: elem,
				subcategory: options[0],
			}));
		} else {
			setInformationTabInfo(prevState => ({
				...prevState,
				[fieldName]: elem,
			}));
		}
	};

	return (
		<Menu>
			<MenuButton
				h='40px'
				w={w}
				fontWeight='200'
				borderRadius='5px'
				textAlign='start'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				_hover={{
					bg:
						colorMode === 'dark'
							? 'transparencyWhite.200'
							: 'transparencyBlack.200',
				}}
				_active={{
					border: '0.5px solid',
					borderColor:
						colorMode === 'dark' ? 'brand.200' : 'brand.500',
				}}
				rightIcon={<IconArrowDown boxSize='12px' />}
				as={Button}
			>
				<Text fontSize='13px'>{selectedOption}</Text>
			</MenuButton>
			<MenuList fontSize='12px' zIndex={10}>
				{options.map((elem, i) => (
					<MenuItem
						key={i}
						value={elem}
						onClick={() => handleItemClick(elem)}
					>
						{elem}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default MenuSelectComponent;
