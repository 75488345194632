import { IconEditUser } from '../../../assets/Icons';
import { useState } from 'react';
import { Button, Flex, Stack, Text, useBoolean } from '@chakra-ui/react';
import ProfileFormControlComponent from './ProfileFormControlComponent';
import {
	validateEditInfo,
	validateImage,
} from '../../../utils/validations/authValidations';
import AvatarChangeComponent from '../../../components/AvatarChangeComponent';

import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/states/user';
import { updateUserSettings } from '../../../services/userService';
import { useTranslation } from 'react-i18next';

const ProfileComponent = ({ userData, isTablet }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [editProfile, setEditProfile] = useBoolean(false);
	const [userInfo, setUserInfo] = useState(userData);
	const [error, setError] = useState(undefined);

	const handleChangeUserInfo = event => {
		const { name, value } = event.target;
		setUserInfo({ ...userInfo, [name]: value });
	};

	const handlePhotoChange = event => {
		const image = event.target.files[0];
		const imageError = validateImage(image);

		if (imageError) {
			setUserInfo({ ...userInfo, avatarImage: undefined });
			setError(imageError);
		} else {
			setUserInfo({ ...userInfo, avatarImage: image });
			error?.field === 'image' && setError(undefined);
		}
	};

	const handleSave = async () => {
		if (editProfile) {
			const formErrors = validateEditInfo(userInfo);
			if (formErrors) setError(formErrors);
			if (!formErrors) {
				setError(undefined);

				const { data } = await updateUserSettings(userInfo);
				dispatch(updateUser({ avatarImage: data?.avatarImage }));
				setEditProfile.off();
			}
		} else {
			setEditProfile.on();
		}
	};

	return (
		<Stack h='100%' gap='20px'>
			<Stack>
				<Text
					variant='colored'
					fontSize='24px'
					fontWeight='700'
					textAlign='center'
					letterSpacing={-0.02}
				>
					{t('settings.profile.title')}
				</Text>
				<Flex justifyContent='center' mt='5px'>
					<AvatarChangeComponent
						avatarImage={userInfo?.avatarImage}
						onChange={handlePhotoChange}
						size='2xl'
						onEdit={editProfile}
					/>
				</Flex>
			</Stack>
			<Stack
				justifyContent='space-between'
				h='100%'
				gap='20px'
				position='relative'
			>
				<Stack
					templateColumns='fit-content(100%) 1fr'
					alignItems='center'
					gap='12px'
					px={isTablet ? '5px' : '35px'}
				>
					<ProfileFormControlComponent
						name='fullname'
						formLabel={t('settings.profile.formControl.name')}
						value={userInfo?.fullname}
						placeholder={userInfo.fullname}
						onChange={handleChangeUserInfo}
						editProfile={editProfile}
						errorMsg={error?.field === 'fullname' && error.message}
					/>
					<ProfileFormControlComponent
						name='username'
						formLabel={t('settings.profile.formControl.username')}
						value={userInfo?.username}
						placeholder={userInfo.username}
						onChange={handleChangeUserInfo}
						editProfile={editProfile}
						errorMsg={error?.field === 'username' && error.message}
					/>
					<ProfileFormControlComponent
						name='email'
						formLabel={t('settings.profile.formControl.email')}
						value={userInfo?.email}
						placeholder={userInfo.email}
						onChange={handleChangeUserInfo}
						type='email'
						editProfile={editProfile}
						errorMsg={error?.field === 'email' && error.message}
					/>
					<ProfileFormControlComponent
						name='phoneNumber'
						formLabel={t('settings.profile.formControl.phone')}
						value={userInfo?.phoneNumber ?? ''}
						placeholder={userInfo?.phoneNumber}
						onChange={handleChangeUserInfo}
						type='tel'
						editProfile={editProfile}
					/>
					<ProfileFormControlComponent
						name='city'
						formLabel={t('settings.profile.formControl.city')}
						value={userInfo?.city ?? ''}
						placeholder={userInfo?.city}
						onChange={handleChangeUserInfo}
						editProfile={editProfile}
					/>
				</Stack>

				{error && (
					<Text
						position={isTablet ? 'none' : 'absolute'}
						w='100%'
						fontSize='13px'
						fontWeight='700'
						lineHeight='100%'
						color='negative'
						bottom='50px'
						left='50%'
						transform={isTablet ? 'none' : 'translateX(-50%)'}
						textAlign='center'
						py={isTablet ? '5px' : '0px'}
					>
						{error.message}
					</Text>
				)}
				<Button
					variant='main'
					alignSelf={isTablet ? 'center' : 'end'}
					fontWeight='normal'
					px='20px'
					py='10px'
					leftIcon={<IconEditUser boxSize='15px' />}
					onClick={handleSave}
					isDisabled={error}
				>
					{editProfile
						? t('settings.profile.saveChange')
						: t('settings.profile.edit')}
				</Button>
			</Stack>
		</Stack>
	);
};

export default ProfileComponent;
