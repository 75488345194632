import InfoSummaryComponent from './InfoSummaryComponent';
import FinancialSummaryComponent from './FinancialSummaryComponent';
import { useTranslation } from 'react-i18next';

const PublicOfferComponent = ({ data }) => {
	const { t } = useTranslation();

	return (
		<FinancialSummaryComponent
			title={t('projectDetails.projectMoreInfo.publicOffer.title')}
		>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.publicOffer.acquisitionValue',
				)}
				amount={data.value}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.publicOffer.taxesAndOperatingCosts',
				)}
				amount={data.taxes}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.publicOffer.renovation',
				)}
				amount={data.renovation}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.publicOffer.expenses',
				)}
				amount={data.expenses}
			/>
			<InfoSummaryComponent
				header={t('projectDetails.projectMoreInfo.publicOffer.total')}
				amount={data.total}
				total
			/>
		</FinancialSummaryComponent>
	);
};

export default PublicOfferComponent;
