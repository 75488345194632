import { Box, Flex, Image, Stack, Text, useColorMode } from '@chakra-ui/react';
import { calcAvariableTokens, currencyFormatter } from '../../utils/utils';
import { IconBathroom, IconRooms } from '../../assets/Icons';
import { useTranslation } from 'react-i18next';

/**
*
    @name InfoPropertyCard
    @description This is the important information of the property component. It is the body part of the property card.
    @returns {JS.Element} InfoPropertyCard.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const InfoPropertyCard = ({ project, icon }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	return (
		<>
			<Box p='10px'>
				<Flex justifyContent='space-between'>
					<Text fontSize='14px' fontWeight='600' lineHeight='20px'>
						{project.name}
					</Text>
					<Flex alignItems='center' gap='5px'>
						<Stack
							direction='row'
							alignItems='center'
							gap='3px'
							mr='2px'
						>
							<Image src={icon} boxSize='12px' />
							<Text fontSize='10' fontWeight='300'>
								{project.city}
							</Text>
						</Stack>
						<Stack gap='2px' direction='row'>
							<Text
								variant='gray'
								fontSize='10px'
								fontWeight='400'
							>
								{project.bedrooms}
							</Text>
							<IconRooms
								boxSize='11px'
								alignSelf='center'
								color={
									colorMode === 'dark'
										? 'transparencyWhite.500'
										: 'transparencyBlack.600'
								}
							/>
						</Stack>
						<Stack gap='2px' direction='row'>
							<Text
								variant='gray'
								fontSize='10px'
								fontWeight='400'
							>
								{project.bathrooms}
							</Text>
							<IconBathroom
								boxSize='11px'
								alignSelf='center'
								color={
									colorMode === 'dark'
										? 'transparencyWhite.500'
										: 'transparencyBlack.600'
								}
							/>
						</Stack>
					</Flex>
				</Flex>
			</Box>
			<Flex justifyContent='center' gap='30px' pb='10px'>
				<Stack alignItems='center' gap='1px'>
					<Text variant='gray' fontSize='12'>
						Total tokens
					</Text>
					<Text fontSize='14'>{calcAvariableTokens(project)}</Text>
				</Stack>
				<Stack alignItems='center' gap='1px'>
					<Text variant='gray' fontSize='12'>
						{t('projectCard.infoPropertyCard.value')}
					</Text>
					<Text fontSize='14'>
						{currencyFormatter(project.totalPrize)}
					</Text>
				</Stack>
			</Flex>
		</>
	);
};

export default InfoPropertyCard;
