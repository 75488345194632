const pdfFiles = [
	{
		language: 'en',
		files: ['testEnglish.pdf', 'testEnglish2.pdf'],
	},
	{
		language: 'es',
		files: ['test.pdf'],
	},
];

export default pdfFiles;
