import { createIcon } from '@chakra-ui/react';

const IconStats = createIcon({
	displayName: 'IconStats',
	viewBox: '0 0 15 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M0.75 8.74254H4.41584L6.82481 1.51129L8.7101 14.5113L10.9096 6.25088L11.6428 9.20296H14.75'
			fill='none'
			stroke='currentColor'
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinejoin='round'
		/>
	),
});

export default IconStats;
