import { Text, Divider, Box } from '@chakra-ui/react';
import DocumentList from './DocumentList';
import pdfFiles from '../../../mockData';
import { useTranslation } from 'react-i18next';

/**
*
    @name DocumentsTab
    @description Component that displays a list of documents by language.
    @returns {JS.Element} React component that renders the documents.
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const DocumentsTab = () => {
	const { t } = useTranslation();

	return (
		<>
			{pdfFiles.map((pdfList, index) => (
				<Box key={index} pt='30px'>
					<Text
						variant='colored'
						fontSize='20'
						fontWeight='bold'
						pb='12px'
					>
						{t(
							`projectDetails.projectMoreInfo.documentsTab.title.${pdfList.language}`,
						)}
					</Text>
					<DocumentList pdfFiles={pdfList.files} />
					<Divider pt='30px' />
				</Box>
			))}
		</>
	);
};

export default DocumentsTab;
