import { Grid, Box, useMediaQuery } from '@chakra-ui/react';

import PropertyCardDiscovery from './PropertyCardDiscovery';

const PropertyGallery = ({ projectListFiltered }) => {
	const [isMobile] = useMediaQuery('(max-width: 900px)');
	const cardWidth = isMobile ? '350px' : '312px';
	return (
		<Box width='100%' overflowX='hidden' p='15px'>
			<Grid
				templateColumns={`repeat(auto-fit, minmax(0, ${cardWidth}))`}
				gridAutoColumns={{ xl: cardWidth }}
				gap='28px'
				placeContent='center'
			>
				{projectListFiltered.map(project => (
					<PropertyCardDiscovery
						key={project.id}
						project={project}
						maxW={cardWidth}
					/>
				))}
			</Grid>
		</Box>
	);
};

export default PropertyGallery;
