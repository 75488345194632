import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { PublicRoutes } from '../Routes';

/**
 * Componente pendiente de finalizar cuando se pueda realizar login
 */

export const AuthGuard = () => {
	const userState = useSelector(store => store.user);

	return userState.email.length > 0 ? (
		<Outlet />
	) : (
		<Navigate replace to={`${PublicRoutes.Home}`} />
	);
};

export default AuthGuard;
