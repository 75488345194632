import GridCenterContainer from '../GridCenterContainer';
import { Spinner, Stack, Text, useColorMode } from '@chakra-ui/react';
import useMagic from '../../hooks/useMagic';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../../redux/states/user';
import { useDispatch } from 'react-redux';
import { PublicRoutes } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
@name CheckEmailAndVerifyMagic
@description Component that show us an alert when the process of changing the password is done and correct.
@returns {JS.Element} SuccesfulResetPassword.js
@author Edunknown | Unknown Gravity - All-in-one Blockchain Company
@Version 1.0.0
*/
const CheckEmailAndVerifyMagic = ({ nextStep, userInfo, type = 'default' }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const { loginWithMagicLink } = useMagic();

	useEffect(() => {
		const InitiateLoginWithMagicLink = () => {
			loginWithMagicLink(
				userInfo?.email,
				false,
				!userInfo?.walletAddress,
			).then(() => {
				if (type === 'magic') {
					dispatch(createUser(userInfo));
					navigate(`${PublicRoutes.Home}`, {
						replace: true,
					});
				} else if (type === 'default') {
					nextStep();
				}
			});
		};

		InitiateLoginWithMagicLink();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<GridCenterContainer>
			<Stack
				w='fit-content'
				maxW='373px'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.800'
				}
				border='0.5px solid'
				borderColor='transparencyBlack.200'
				borderRadius='12px'
				alignItems='center'
				p='30px'
				mt='-73px'
				gap='38px'
			>
				<Stack textAlign='center'>
					<Text fontWeight='800' fontSize='20px' lineHeight='20px'>
						{t('checkEmailAndVerifyMagic.title')}
					</Text>
					<Text fontWeight='400' fontSize='14px' lineHeight='20px'>
						{t('checkEmailAndVerifyMagic.subtitle')}
						{userInfo?.email}
					</Text>
				</Stack>
				<Spinner
					boxSize='58px'
					thickness='4px'
					speed='0.8s'
					emptyColor={
						colorMode === 'dark'
							? 'transparencyWhite.500'
							: 'transparencyBlack.200'
					}
					color={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
				/>
			</Stack>
		</GridCenterContainer>
	);
};

export default CheckEmailAndVerifyMagic;
