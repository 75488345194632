import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useMediaQuery,
} from '@chakra-ui/react';
import DetailsTab from './DetailsTab/DetailsTab';
import FinancialSummaryTab from './FinancialSummaryTab/FinancialSummaryTab';
import ProjectUpdatesTab from './ProjectUpdatesTab/ProjectUpdatesTab';
import DocumentsTab from './DocumentsTab/DocumentsTab';
import { useTranslation } from 'react-i18next';

const ProjectMoreInfo = ({ projectSelected }) => {
	const { t } = useTranslation();
	const [isMobile] = useMediaQuery('(max-width: 600px)');
	return (
		<>
			<Tabs>
				<TabList>
					<Tab fontSize={isMobile ? '13' : 'none'}>
						{t('projectDetails.projectMoreInfo.details')}
					</Tab>
					<Tab fontSize={isMobile ? '13' : 'none'}>
						{t('projectDetails.projectMoreInfo.documents')}
					</Tab>
					<Tab fontSize={isMobile ? '13' : 'none'}>
						{t('projectDetails.projectMoreInfo.financialSummary')}
					</Tab>
					<Tab fontSize={isMobile ? '13' : 'none'}>
						{t('projectDetails.projectMoreInfo.projectUpdates')}
					</Tab>
				</TabList>

				<TabPanels>
					<TabPanel p={0} pt='30px'>
						<DetailsTab data={projectSelected} />
					</TabPanel>
					<TabPanel p={0} pt='10px'>
						<DocumentsTab />
					</TabPanel>
					<TabPanel p={0} pt='30px'>
						<FinancialSummaryTab />
					</TabPanel>
					<TabPanel p={0} pt='30px'>
						<ProjectUpdatesTab data={projectSelected} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};

export default ProjectMoreInfo;
