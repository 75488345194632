import { createIcon } from '@chakra-ui/react';

const IconPublish = createIcon({
	displayName: 'IconPublish',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.14686 7.25735L11.732 3.30873M8.12469 1.87378H1V12.8738H13V6.22104M7.20257 7.38072L8.35022 9.92212C8.45132 10.146 8.50187 10.258 8.57471 10.2906C8.63786 10.319 8.71308 10.319 8.77626 10.2907C8.84914 10.2581 8.89985 10.1462 9.00126 9.92245L11.8791 3.57167C11.9707 3.36966 12.0164 3.26865 11.9914 3.20411C11.9697 3.14806 11.9186 3.10407 11.8535 3.08535C11.7786 3.06378 11.6613 3.1032 11.4267 3.18203L4.05212 5.66039C3.79227 5.74772 3.66234 5.79138 3.62447 5.85415C3.59165 5.90856 3.59169 5.97334 3.62459 6.02772C3.66254 6.09044 3.79253 6.13398 4.05251 6.22104L7.0036 7.20937C7.05637 7.22704 7.08276 7.23588 7.10498 7.24953C7.12467 7.26162 7.14189 7.27645 7.15594 7.29341C7.17179 7.31255 7.18205 7.33527 7.20257 7.38072Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconPublish;
