import { createIcon } from '@chakra-ui/react';

const IconWithdraw = createIcon({
	displayName: 'IconWithdraw',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M10.0005 8.24436H10.0065M11.6667 1.26678H3.13333C2.3866 1.26678 2.01323 1.26678 1.72801 1.41665C1.47713 1.54847 1.27315 1.75882 1.14533 2.01755C1 2.31167 1 2.69671 1 3.46678V10.0668C1 10.8369 1 11.2219 1.14533 11.516C1.27315 11.7747 1.47713 11.985 1.72801 12.1169C2.01323 12.2668 2.38659 12.2668 3.13333 12.2668M10.8667 12.2668C11.6134 12.2668 11.9868 12.2668 12.272 12.1169C12.5229 11.985 12.7269 11.7747 12.8547 11.516C13 11.2219 13 10.8369 13 10.0668V6.21679C13 5.44672 13 5.06167 12.8547 4.76754C12.7269 4.50882 12.5229 4.29847 12.272 4.16664C11.9868 4.01678 11.6134 4.01678 10.8667 4.01678H3.66667M10.3005 8.24436C10.3005 8.41521 10.1662 8.55374 10.0005 8.55374C9.83487 8.55374 9.70053 8.41521 9.70053 8.24436C9.70053 8.07352 9.83487 7.93499 10.0005 7.93499C10.1662 7.93499 10.3005 8.07352 10.3005 8.24436Z'
				fill='none'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.50744 11.1731L7.27755 13.4008C7.14072 13.5376 6.91896 13.5374 6.78226 13.4006L4.55447 11.1707C4.41776 11.0339 4.41787 10.8121 4.5547 10.6754C4.69154 10.5387 4.91329 10.5388 5.05 10.6757L6.68019 12.3074L6.68187 8.74673C6.68196 8.55331 6.83884 8.39658 7.03226 8.39667C7.22569 8.39676 7.38242 8.55364 7.38233 8.74706L7.38065 12.3077L9.01238 10.6775C9.14921 10.5408 9.37097 10.5409 9.50767 10.6778C9.64438 10.8146 9.64428 11.0364 9.50744 11.1731Z'
				fillRule='evenodd'
				clipRule='evenodd'
				fill='currentColor'
			/>
		</>
	),
});

export default IconWithdraw;
