import { Box, Flex, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuComponent from './MenuComponent/MenuComponent';
import { headerLinks } from '../routes/Routes';
import { checkPath } from '../utils/utils';
import { IconDotsEmpty } from '../assets/Icons';

/**
*
    @name MenuComponentMobile
    @description Component for the mobile Navbar. It only include three links with their icons, and a menu link with different options
    @returns {JS.Element} MenuComponentMobile.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const MenuComponentMobile = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { colorMode } = useColorMode();
	const { pathname } = location;

	const iconsStyle = activePathnames => ({
		color: checkPath(pathname, activePathnames) ? 'brand.500' : 'black',
		_dark: {
			color: checkPath(pathname, activePathnames) ? 'brand.200' : 'white',
		},
	});

	return (
		<Box
			w='100%'
			py='10px'
			px='15px'
			position='fixed'
			bottom='0'
			bg={
				colorMode === 'dark'
					? 'transparencyBlack.950'
					: 'transparencyWhite.950'
			}
			zIndex={10}
		>
			<Flex h='45px' justifyContent='space-around'>
				{headerLinks.map((menu, idx) => {
					const MenuIcon = menu.icon;
					return (
						<Stack
							key={idx}
							alignItems='center'
							cursor='pointer'
							gap='4px'
							onClick={() => navigate(menu.to)}
							transition='color 0.2s'
							{...iconsStyle(menu.activePathnames)}
							fontFamily={'Space Grotesk'}
						>
							<MenuIcon boxSize='25px' />
							<Text fontSize='10' fontWeight='300'>
								{menu.title}
							</Text>
						</Stack>
					);
				})}
				<Stack
					alignItems='center'
					transition='color 0.2s'
					cursor='pointer'
					gap='4px'
					color={colorMode === 'dark' ? 'white' : 'black'}
					fontFamily={'Space Grotesk'}
				>
					<MenuComponent
						colorMode={colorMode}
						button={<IconDotsEmpty boxSize='25px' />}
					/>
					<Text fontSize='10' fontWeight='300'>
						Menu
					</Text>
				</Stack>
			</Flex>
		</Box>
	);
};

export default MenuComponentMobile;
