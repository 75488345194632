import { createIcon } from '@chakra-ui/react';

const IconSend = createIcon({
	displayName: 'IconSend',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M7.00657 9.27603L14.65 1.63256M7.09944 9.51484L9.01257 14.4343C9.18111 14.8677 9.26538 15.0844 9.3868 15.1477C9.49206 15.2025 9.61745 15.2026 9.72278 15.1479C9.84427 15.0847 9.9288 14.8681 10.0978 14.435L14.8953 2.14154C15.0479 1.7505 15.1242 1.55498 15.0824 1.43005C15.0462 1.32155 14.961 1.2364 14.8525 1.20015C14.7276 1.15842 14.5321 1.23472 14.1411 1.38732L1.84763 6.18475C1.41445 6.3538 1.19786 6.43832 1.13474 6.55982C1.08002 6.66514 1.08009 6.79053 1.13493 6.89579C1.1982 7.01722 1.41489 7.10149 1.84827 7.27002L6.76775 9.18315C6.85572 9.21737 6.89971 9.23447 6.93675 9.26089C6.96958 9.28431 6.99829 9.31302 7.0217 9.34585C7.04812 9.38288 7.06523 9.42687 7.09944 9.51484Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconSend;
