import {
	Divider,
	Flex,
	Grid,
	GridItem,
	Link,
	Text,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';
import BoxComponent from '../../../components/BoxComponent';
import OfferInfoComponent from './OfferInfoComponent';
import { offerData } from '../../../data/mockdata';

import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
    @name TokenHoldingsComponent
    @description Component that list the offers that are avaliable for the user in this moment and shows how many time remains for the next payment
	@returns {JSX.Element} TokenHoldingsComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TokenHoldingsComponent = ({ isTablet }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const { colorMode } = useColorMode();

	const offersPrinted = offerData.slice(0, 5);

	const colorBorderTablet =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[100];
	const colorBorderDesktop =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[200];

	return (
		<BoxComponent
			maxW={isTablet ? '500px' : 'none'}
			w='100%'
			h='100%'
			p='0'
			inPadding='15px'
			gap='11px'
		>
			<Text
				fontWeight='700'
				fontSize='16'
				lineHeight='100%'
				letterSpacing={0.02}
			>
				{t('dashboard.tokenHoldings.title')}
			</Text>
			<Flex
				flexDirection='column'
				justifyContent='center'
				h='100%'
				gap='5px'
			>
				<Grid
					templateColumns='repeat(4, 1fr)'
					placeItems='center'
					textAlign='center'
					fontSize={isTablet ? '12px' : '14px'}
					border={isTablet ? '2px solid' : 'none'}
					borderColor={colorBorderTablet}
					borderRadius={isTablet ? '12px' : '0'}
					rowGap={isTablet ? '0' : '0'}
				>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							wordBreak='break-all'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
						>
							{t('dashboard.tokenHoldings.name')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.price')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							ROI
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.expiration')}
						</Text>
					</GridItem>
					<GridItem
						py={isTablet ? '12px' : '5px'}
						px='5px'
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.action')}
						</Text>
					</GridItem>
					<Divider
						gridColumn={isTablet ? 'span 5' : 'span 6'}
						borderWidth='1px'
						borderColor={colorBorderDesktop}
					/>
					{offersPrinted.map((offer, index) => {
						return (
							<OfferInfoComponent
								key={index}
								data={offer}
								last={index === offersPrinted.length - 1}
								isTablet={isTablet}
								colorBorder={colorBorderDesktop}
							/>
						);
					})}
				</Grid>
				{offerData.length > 5 && (
					<Link
						variant='isActive'
						fontSize='13'
						fontWeight='400'
						letterSpacing={0.02}
						textAlign='center'
						textDecoration='underline'
						onClick={() => navigate(`/${PrivateRoutes.Wallet}`)}
					>
						{t('dashboard.tokenHoldings.viewAll')}
					</Link>
				)}
			</Flex>
		</BoxComponent>
	);
};

export default TokenHoldingsComponent;
