import { createIcon } from '@chakra-ui/react';

const IconLike = createIcon({
	displayName: 'IconLike',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M13.6705 1.75407C13.3284 1.40092 12.9222 1.12078 12.4752 0.929645C12.0282 0.738513 11.549 0.640137 11.0651 0.640137C10.5812 0.640137 10.1021 0.738513 9.65504 0.929645C9.20801 1.12078 8.80185 1.40092 8.45976 1.75407L7.74982 2.48663L7.03988 1.75407C6.3489 1.04107 5.41172 0.640506 4.43453 0.640506C3.45733 0.640506 2.52016 1.04107 1.82917 1.75407C1.13819 2.46707 0.75 3.43411 0.75 4.44244C0.75 5.45078 1.13819 6.41782 1.82917 7.13082L2.53911 7.86339L7.74982 13.2401L12.9605 7.86339L13.6705 7.13082C14.0127 6.77784 14.2842 6.35873 14.4694 5.89745C14.6547 5.43617 14.75 4.94175 14.75 4.44244C14.75 3.94313 14.6547 3.44871 14.4694 2.98743C14.2842 2.52615 14.0127 2.10705 13.6705 1.75407Z'
			stroke='currentColor'
			fill='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconLike;
