import {
	Box,
	Divider,
	Flex,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { IconHelp } from '../../../../assets/Icons';
import { useTranslation } from 'react-i18next';

const EstimatedEarnings = () => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	return (
		<Box mt='-10px'>
			<Flex justifyContent='space-between' align='center' mb='10px'>
				<Text fontSize='12' fontWeight='600'>
					{t(
						'projectDetails.offerDetails.estimatedEarnings.estimatedEarnings',
					)}
				</Text>
				<Box
					bg={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
					borderRadius='20px'
					px='10px'
					py='3px'
					h='fit-content'
				>
					<Text
						color={
							colorMode === 'dark'
								? 'transparencyBlack.800'
								: 'transparencyWhite.800'
						}
						fontSize='11px'
						fontWeight='400'
					>
						5{' '}
						{t(
							'projectDetails.offerDetails.estimatedEarnings.years',
						)}
					</Text>
				</Box>
			</Flex>
			<Stack
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.50'
				}
				border='0.5px solid'
				borderColor='transparencyBlack.100'
				borderRadius='12'
				p='10px'
				gap='10px'
			>
				<Flex justifyContent='space-between'>
					<Flex align='center' gap={1}>
						<Text fontSize='11' fontWeight='400'>
							{t(
								'projectDetails.offerDetails.estimatedEarnings.projectReturn',
							)}
						</Text>
						<IconHelp size='13px' color='gray' />
					</Flex>
					<Text fontSize='11' fontWeight='600' pr='10px'>
						134,50€
					</Text>
				</Flex>
				<Stack
					border='0.5px solid'
					borderRadius='10'
					borderColor={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.100'
					}
					p='10px'
					gap='10px'
				>
					<Flex justifyContent='space-between'>
						<Flex align='center' gap={1}>
							<Text fontSize='10'>
								{t(
									'projectDetails.offerDetails.estimatedEarnings.rentalIncome',
								)}{' '}
							</Text>
							<IconHelp size='12px' color='gray' />
						</Flex>
						<Text fontSize='10'>43,23€</Text>
					</Flex>
					<Divider />
					<Flex justifyContent='space-between'>
						<Flex align='center' gap={1}>
							<Text fontSize='10'>
								{t(
									'projectDetails.offerDetails.estimatedEarnings.appreciation',
								)}
							</Text>
							<IconHelp size='12px' color='gray' />
						</Flex>
						<Text fontSize='10'>23,41€</Text>
					</Flex>
				</Stack>
			</Stack>
		</Box>
	);
};

export default EstimatedEarnings;
