import { createIcon } from '@chakra-ui/react';

const IconBalcony = createIcon({
	displayName: 'IconBalcony',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M12.4062 8.05169V5.24963C12.4062 2.34914 10.057 -0.00012207 7.15646 -0.00012207C4.25597 -0.00012207 1.90671 2.34914 1.90671 5.24963V8.05169C1.51298 8.27481 1.25049 8.70135 1.25049 9.18695V11.8118C1.25049 12.5337 1.84109 13.1243 2.56293 13.1243H11.75C12.4718 13.1243 13.0624 12.5337 13.0624 11.8118V9.18695C13.0624 8.70135 12.8 8.27481 12.4062 8.05169ZM3.87537 11.8118H2.56293V9.18695H3.87537V11.8118ZM6.50024 11.8118H5.18781V9.18695H6.50024V11.8118ZM6.50024 7.87451H3.21915V5.24963C3.21915 3.30066 4.63658 1.6798 6.50024 1.36481V7.87451ZM7.81268 1.36481C8.73057 1.51997 9.5638 1.99533 10.1645 2.70652C10.7651 3.41771 11.0944 4.31872 11.0938 5.24963V7.87451H7.81268V1.36481ZM9.12512 11.8118H7.81268V9.18695H9.12512V11.8118ZM11.75 11.8118H10.4376V9.18695H11.75V11.8118ZM4.53159 5.90585C4.53159 5.54493 4.82688 5.24963 5.18781 5.24963C5.54873 5.24963 5.84403 5.54493 5.84403 5.90585C5.84403 6.26677 5.54873 6.56207 5.18781 6.56207C4.82688 6.56207 4.53159 6.26677 4.53159 5.90585ZM9.78134 5.90585C9.78134 6.26677 9.48604 6.56207 9.12512 6.56207C8.7642 6.56207 8.4689 6.26677 8.4689 5.90585C8.4689 5.54493 8.7642 5.24963 9.12512 5.24963C9.48604 5.24963 9.78134 5.54493 9.78134 5.90585Z'
			fill='currentColor'
		/>
	),
});

export default IconBalcony;
