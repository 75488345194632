import { Flex, Text, useColorMode } from '@chakra-ui/react';

const InfoCurrencyBox = ({ icon, currency, amount }) => {
	const { colorMode } = useColorMode();
	return (
		<Flex
			flexDir='column'
			w='92px'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			borderRadius='5px'
			p='5px'
			gap='0px'
		>
			<Flex alignSelf='center' justifyContent='center' gap='5px' p='5px'>
				{icon}
				<Text
					variant='gray'
					fontSize='10px'
					lineHeight='20px'
					letterSpacing={-0.02}
				>
					{currency}
				</Text>
			</Flex>
			<Text fontSize='15px' fontWeight='semibold'>
				{amount}
			</Text>
		</Flex>
	);
};

export default InfoCurrencyBox;
