import building from './building.svg';
import invest from './invest.png';
import trade from './trade.png';
import tokens from './tokens.png';
import dashboard from './dashboard.png';
import stake from './stake.png';

export const onboardingImages = {
	building,
	invest,
	trade,
	tokens,
	dashboard,
	stake,
};
