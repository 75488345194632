import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { currencyFormatter } from '../../../utils/utils';
import { calculateRemainingTime } from '../../../utils/CalculateTime';
import { IconDots } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
/**
*
    @name OfferInfoComponent
    @description Component that list 1 offer of the TokenHoldingComponent
	@returns {JSX.Element} OfferInfoComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const OfferInfoComponent = ({ last, data, isTablet, colorBorder }) => {
	const { t } = useTranslation();

	return (
		<>
			<GridItem py={isTablet ? '15px' : '0'} px='2px'>
				<Text
					fontSize={isTablet ? '12px' : '14px'}
					fontWeight={isTablet ? '600' : '500'}
				>
					{data.name}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(data.price, 'EUR')}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{data.roi} %
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{calculateRemainingTime(data.expiration)}
			</Text>
			{!isTablet && (
				<Flex
					w='100%'
					justifyContent='center'
					align='center'
					p='6px'
					gap='10px'
				>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
					>
						{t('dashboard.tokenHoldings.offerInfo.buy')}
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
					>
						{t('dashboard.tokenHoldings.offerInfo.transfer')}
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
					>
						{t('dashboard.tokenHoldings.offerInfo.sell')}
					</Button>
				</Flex>
			)}
			{isTablet && (
				<GridItem colSpan={isTablet ? 0 : 2}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem>
								{t('dashboard.tokenHoldings.offerInfo.sell')}
							</MenuItem>
							<MenuItem>
								{t(
									'dashboard.tokenHoldings.offerInfo.transfer',
								)}
							</MenuItem>
							<MenuItem>
								{t('dashboard.tokenHoldings.offerInfo.buy')}
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isTablet ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}
		</>
	);
};

export default OfferInfoComponent;
