const variants = {
	initial: { opacity: 0, x: 40 },
	animate: { opacity: 1, x: 0 },
	exit: { opacity: 0, x: -20 },
};

const duration = 0.25;

module.exports = {
	variants,
	duration,
};
