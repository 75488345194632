import { createIcon } from '@chakra-ui/react';

const IconDots = createIcon({
	displayName: 'IconDots',
	viewBox: '0 0 17 15',
	path: (
		<>
			<path
				d='M8.875 8.375C9.35825 8.375 9.75 7.98325 9.75 7.5C9.75 7.01675 9.35825 6.625 8.875 6.625C8.39175 6.625 8 7.01675 8 7.5C8 7.98325 8.39175 8.375 8.875 8.375Z'
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15 8.375C15.4832 8.375 15.875 7.98325 15.875 7.5C15.875 7.01675 15.4832 6.625 15 6.625C14.5168 6.625 14.125 7.01675 14.125 7.5C14.125 7.98325 14.5168 8.375 15 8.375Z'
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M2.75 8.375C3.23325 8.375 3.625 7.98325 3.625 7.5C3.625 7.01675 3.23325 6.625 2.75 6.625C2.26675 6.625 1.875 7.01675 1.875 7.5C1.875 7.98325 2.26675 8.375 2.75 8.375Z'
				stroke='currentColor'
				fill='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</>
	),
});

export default IconDots;
