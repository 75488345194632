import { configureStore } from '@reduxjs/toolkit';
import userSliceReducer from '../states/user';

/**
 *
 * @name store
 * @description The redux store
 * @version 1.0.0
 *
 */
export const store = configureStore({
	reducer: {
		user: userSliceReducer,
	},
});
