import { createIcon } from '@chakra-ui/react';

const IconSearch = createIcon({
	displayName: 'IconSearch',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M13.6296 12.356L10.873 9.59948C11.5367 8.71599 11.895 7.6406 11.8937 6.53562C11.8937 3.71316 9.59746 1.41687 6.775 1.41687C3.95254 1.41687 1.65625 3.71316 1.65625 6.53562C1.65625 9.35808 3.95254 11.6544 6.775 11.6544C7.87998 11.6556 8.95537 11.2973 9.83886 10.6337L12.5954 13.3902C12.7349 13.5149 12.9169 13.5815 13.104 13.5763C13.2911 13.571 13.4691 13.4944 13.6014 13.362C13.7338 13.2297 13.8104 13.0517 13.8157 12.8646C13.8209 12.6776 13.7543 12.4956 13.6296 12.356ZM3.11875 6.53562C3.11875 5.81248 3.33318 5.10558 3.73494 4.50432C4.13669 3.90305 4.70772 3.43442 5.37581 3.15768C6.0439 2.88095 6.77905 2.80855 7.4883 2.94962C8.19754 3.0907 8.84902 3.43892 9.36036 3.95026C9.87169 4.4616 10.2199 5.11308 10.361 5.82232C10.5021 6.53156 10.4297 7.26671 10.1529 7.9348C9.8762 8.6029 9.40757 9.17392 8.8063 9.57568C8.20503 9.97743 7.49814 10.1919 6.775 10.1919C5.80566 10.1907 4.87635 9.80512 4.19092 9.11969C3.5055 8.43426 3.11991 7.50496 3.11875 6.53562Z'
			fill='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconSearch;
