import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import PropertyGallery from './components/PropertyGallery';
import Filters from './components/DiscoveryFilters/index.js';
import { projectListData } from '../../data/mockdata.js';
import ModalComponent from '../../components/ModalComponent.js';
import Onboarding from './components/Onboarding/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../redux/states/user.js';

const Discovery = () => {
	const projectList = projectListData;
	const dispatch = useDispatch();
	const [projectListFiltered, setProjectListFiltered] = useState(projectList);
	const user = useSelector(store => store.user);
	const [showOnboarding, setShowOnboarding] = useState(
		user.isFirstTime || false,
	);

	const closeOnboardingModal = () => {
		dispatch(updateUser({ ...user, isFirstTime: false }));
		setShowOnboarding(false);
	};

	return (
		<Box mx='-15px'>
			<Filters
				projectList={projectList}
				setProjectListFiltered={setProjectListFiltered}
			/>
			<PropertyGallery projectListFiltered={projectListFiltered} />

			<ModalComponent
				onClose={closeOnboardingModal}
				isOpen={showOnboarding}
				modalCross={false}
				closeOnOverlayClick={false}
				moreBlur
			>
				<Onboarding closeOnboardingModal={closeOnboardingModal} />
			</ModalComponent>
		</Box>
	);
};
export default Discovery;
