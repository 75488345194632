import {
	Box,
	Divider,
	Flex,
	Grid,
	Text,
	useColorMode,
	useClipboard,
} from '@chakra-ui/react';
import ModalComponent from './ModalComponent';
import CrossBoxComponent from './CrossBoxComponent';
import { IconCopy } from '../assets/Icons';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';

const QRScreenComponent = ({ isOpenQRModal, closeQRModal }) => {
	const { colorMode } = useColorMode();
	const walletAddress = useSelector(store => store.user.walletAddress);

	const { hasCopied, onCopy } = useClipboard(walletAddress);

	return (
		<ModalComponent
			isOpen={isOpenQRModal}
			onClose={closeQRModal}
			modalCross={false}
		>
			<Flex justifyContent='flex-end'>
				<CrossBoxComponent closeModal={closeQRModal} />
			</Flex>

			<Flex
				maxW='400px'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.900'
				}
				flexDirection='column'
				justifyContent='center'
				align='center'
				borderRadius='12px'
				px='40px'
				py='30px'
				gap='15px'
			>
				<Box
					bg='white'
					border='0.5px solid'
					borderColor='transparencyBlack.200'
					borderRadius='5px'
					p='5px'
				>
					<QRCodeSVG value={walletAddress} />
				</Box>
				<Flex
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.50'
					}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					flexDirection='column'
					borderRadius='12px'
					px='20px'
					py='10px'
				>
					<Text variant='gray' fontSize='10px' fontWeight='light'>
						Network
					</Text>
					<Text fontSize='14px' fontWeight='light'>
						Polygon
					</Text>
					<Text variant='gray' fontSize='10px' fontWeight='light'>
						Contract information 289402
					</Text>
					<Divider
						my='10px'
						borderColor={
							colorMode === 'dark'
								? 'transparencyWhite.200'
								: 'transparencyBlack.200'
						}
					/>
					<Text variant='gray' fontSize='10px' fontWeight='light'>
						Wallet address
					</Text>
					<Grid templateColumns='1fr auto' gap={3}>
						<Text
							fontSize='14px'
							fontWeight='500'
							wordBreak='break-all'
						>
							{walletAddress}
						</Text>

						<IconCopy
							boxSize='20px'
							mt='4px'
							cursor='pointer'
							onClick={onCopy}
						/>
					</Grid>
					{hasCopied && (
						<Text
							variant='colored'
							fontSize='12px'
							textAlign='center'
						>
							Copied to clipboard!
						</Text>
					)}
				</Flex>
				<Flex flexDirection='column'>
					<Text variant='gray' fontSize='10px'>
						*Do not send NFT to this address*
					</Text>
					<Text variant='gray' fontSize='10px'>
						*Platform only accepts USDT, USDC or Project tokens*
					</Text>
				</Flex>
			</Flex>
		</ModalComponent>
	);
};

export default QRScreenComponent;
