import { createIcon } from '@chakra-ui/react';

const IconLibrary = createIcon({
	displayName: 'IconLibrary',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M3.12731 5.6528H6.76383M3.12731 10.653H6.76383M1.30906 2.01628H2.21819C2.46923 2.01628 2.67275 2.2198 2.67275 2.47085V12.0167C2.67275 12.2677 2.46923 12.4713 2.21819 12.4713H1.30906C1.05801 12.4713 0.854492 12.2677 0.854492 12.0167V2.47085C0.854492 2.2198 1.05801 2.01628 1.30906 2.01628ZM3.58188 3.83454H6.30927C6.56031 3.83454 6.76383 4.03805 6.76383 4.2891V12.0167C6.76383 12.2677 6.56031 12.4713 6.30927 12.4713H3.58188C3.33083 12.4713 3.12731 12.2677 3.12731 12.0167V4.2891C3.12731 4.03805 3.33083 3.83454 3.58188 3.83454ZM7.67296 0.652588H9.49122C9.74227 0.652588 9.94578 0.856103 9.94578 1.10715V12.0167C9.94578 12.2677 9.74227 12.4713 9.49122 12.4713H7.67296C7.42191 12.4713 7.21839 12.2677 7.21839 12.0167V1.10715C7.21839 0.856103 7.42191 0.652588 7.67296 0.652588ZM11.9475 2.01942L10.7997 2.14016C10.4838 2.1734 10.2548 2.46887 10.2903 2.79644L11.2824 11.9329C11.3182 12.2605 11.6054 12.5011 11.9214 12.4682L13.0691 12.3474C13.3851 12.3142 13.6141 12.0187 13.5785 11.6911L12.5879 2.55609C12.5507 2.2271 12.2634 1.98618 11.9475 2.01942Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconLibrary;
