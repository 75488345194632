import { createIcon } from '@chakra-ui/react';

const IconSave = createIcon({
	displayName: 'IconSave',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M4.41667 0.998291V3.26496C4.41667 3.63833 4.41667 3.82501 4.48933 3.96762C4.55324 4.09306 4.65523 4.19505 4.78067 4.25896C4.92328 4.33162 5.10997 4.33162 5.48333 4.33162H10.0167C10.39 4.33162 10.5767 4.33162 10.7193 4.25896C10.8448 4.19505 10.9468 4.09306 11.0107 3.96762C11.0833 3.82501 11.0833 3.63833 11.0833 3.26496V1.66496M11.0833 12.9983V8.73162C11.0833 8.35826 11.0833 8.17157 11.0107 8.02896C10.9468 7.90352 10.8448 7.80154 10.7193 7.73762C10.5767 7.66496 10.39 7.66496 10.0167 7.66496H5.48333C5.10997 7.66496 4.92328 7.66496 4.78067 7.73762C4.65523 7.80154 4.55324 7.90352 4.48933 8.02896C4.41667 8.17157 4.41667 8.35826 4.41667 8.73162V12.9983M13.75 5.21528V9.79829C13.75 10.9184 13.75 11.4784 13.532 11.9063C13.3403 12.2826 13.0343 12.5886 12.658 12.7803C12.2302 12.9983 11.6701 12.9983 10.55 12.9983H4.95C3.8299 12.9983 3.26984 12.9983 2.84202 12.7803C2.46569 12.5886 2.15973 12.2826 1.96799 11.9063C1.75 11.4784 1.75 10.9184 1.75 9.79829V4.19829C1.75 3.07819 1.75 2.51813 1.96799 2.09031C2.15973 1.71399 2.46569 1.40802 2.84202 1.21628C3.26984 0.998291 3.8299 0.998291 4.95 0.998291H9.53301C9.85913 0.998291 10.0222 0.998291 10.1756 1.03513C10.3117 1.06779 10.4418 1.12167 10.561 1.19477C10.6956 1.27723 10.8109 1.39253 11.0415 1.62313L13.1252 3.70679C13.3558 3.93739 13.4711 4.05269 13.5535 4.18724C13.6266 4.30654 13.6805 4.4366 13.7132 4.57265C13.75 4.7261 13.75 4.88916 13.75 5.21528Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconSave;
