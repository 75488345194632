import {
	Box,
	Text,
	Flex,
	Progress,
	Stack,
	NumberInput,
	NumberInputField,
	useColorMode,
	SimpleGrid,
} from '@chakra-ui/react';
import {
	currencyFormatter,
	calcAvariableTokens,
	calcTotalValue,
	calcBuyableTokens,
} from '../../../../utils/utils';
import MenuROIComponent from './MenuROIComponent';
import EstimatedEarnings from './EstimatedEarnings';
import CryptocurrencyBoxComponent from './CryptocurrencyBoxComponent';
import { IconEther, IconUSDC, IconUSDT } from '../../../../assets/Icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CalculatorROI = ({
	projectSelected,
	userExample,
	valueInput,
	setValueInput,
	valueInTokens,
	setValueInTokens,
	setErrMsg,
	setBuyMode,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [selectedCurrency, setSelectedCurrency] = useState();
	const marks = ['', '25%', '50%', '75%', ''];
	const tokensAvariable = calcAvariableTokens(projectSelected);

	const handleInputChangeTokens = value => {
		if (value > tokensAvariable) {
			value = tokensAvariable;
		}
		setErrMsg('');
		setBuyMode(false);
		setValueInput(Math.floor(value));
	};
	const handleInputChangeCoins = value => {
		setErrMsg('');
		setBuyMode(false);
		setValueInput(Math.floor(value));
	};

	const handleCurrencySelect = currency => {
		setSelectedCurrency(currency);
	};

	return (
		<Stack
			minWidth='100%'
			border='0.5px solid'
			borderColor={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.100'
			}
			borderRadius='12px'
			px='10px'
			py='15px'
			my='10px'
			gap='20px'
		>
			<Stack w='100%' align='center' gap='7px' px='15px'>
				<Text fontSize='10px' letterSpacing={-0.02}>
					{t(
						'projectDetails.offerDetails.calculatorROI.selectPayment',
					)}
				</Text>
				<SimpleGrid w='100%' columns={3} spacing='10px'>
					<CryptocurrencyBoxComponent
						icon={<IconUSDT boxSize='14px' />}
						currency='USDT'
						isSelected={selectedCurrency === 'USDT'}
						onClick={() => handleCurrencySelect('USDT')}
					/>
					<CryptocurrencyBoxComponent
						icon={<IconUSDC boxSize='14px' />}
						currency='USDC'
						isSelected={selectedCurrency === 'USDC'}
						onClick={() => handleCurrencySelect('USDC')}
					/>
					<CryptocurrencyBoxComponent
						icon={<IconEther boxSize='14px' />}
						currency='ETH'
						isSelected={selectedCurrency === 'ETH'}
						onClick={() => handleCurrencySelect('ETH')}
					/>
				</SimpleGrid>
			</Stack>
			<Flex
				fontSize='10px'
				fontWeight='400'
				justifyContent='space-between'
			>
				<Flex gap='7px'>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.calculatorROI.accountBalance',
						)}
						:
					</Text>
					<Text fontSize='inherit'>
						{currencyFormatter(userExample.accBalance, 'EUR')}
					</Text>
				</Flex>
				<Flex gap='7px'>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.calculatorROI.maxTokens',
						)}
					</Text>
					<Text fontSize='inherit'>{tokensAvariable}</Text>
				</Flex>
			</Flex>
			<Flex
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.50'
				}
				border='0.5px solid'
				borderColor='transparencyBlack.100'
				borderRadius='12'
				justifyContent='space-between'
				p='15px'
			>
				<NumberInput
					type='number'
					fontSize='24'
					fontWeight='600'
					variant='noStyled'
					value={valueInput}
					onChange={
						valueInTokens
							? handleInputChangeTokens
							: handleInputChangeCoins
					}
				>
					<NumberInputField
						bg='none'
						placeholder={t(
							'projectDetails.offerDetails.calculatorROI.numberInputField.value',
						)}
						_placeholder={{
							color:
								colorMode === 'dark'
									? 'transparencyWhite.500'
									: 'transparencyBlack.500',
						}}
						fontSize='24'
						fontWeight='600'
					/>
				</NumberInput>

				<MenuROIComponent
					valueInTokens={valueInTokens}
					setValueInTokens={setValueInTokens}
					setValueInput={setValueInput}
				/>
			</Flex>
			<Box position='relative' w='100%'>
				<Progress
					value={75}
					h='8px'
					borderRadius='50px'
					colorScheme='brand'
				/>
				<Box position='relative' w='100%' mt='5px'>
					{marks.map((mark, index) => (
						<Text
							key={index}
							position='absolute'
							left={`${(index / (marks.length - 1)) * 100}%`}
							transform='translateX(-50%)'
							fontSize='12'
							fontWeight='400'
						>
							{mark}
						</Text>
					))}
				</Box>

				<Flex justifyContent='space-between' fontWeight='400'>
					<Text fontSize='12'>0%</Text>
					<Text fontSize='12'>100%</Text>
				</Flex>
			</Box>
			<Stack
				minWidth='100%'
				border='0.5px solid'
				borderRadius='12px'
				borderColor={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				p='10px'
				my='10px'
				gap='20px'
			>
				<Flex justifyContent='space-between' px='15px' fontWeight='400'>
					<Text variant='gray' fontSize='12'>
						{t('projectDetails.offerDetails.calculatorROI.cost')}
					</Text>

					<Flex justifyContent='space-between' gap='1'>
						{valueInput !== undefined && (
							<Text variant='smallColored' fontWeight='600'>
								{valueInTokens
									? calcTotalValue(
											projectSelected.tokensPrize,
											valueInput,
										)
									: calcBuyableTokens(
											projectSelected.tokensPrize,
											valueInput,
										)}
							</Text>
						)}
						<Text fontSize='12'>
							{valueInTokens ? 'EUR' : 'Tokens'}
						</Text>
					</Flex>
				</Flex>
			</Stack>
			<EstimatedEarnings />
		</Stack>
	);
};

export default CalculatorROI;
