import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { currencyFormatter } from '../../../utils/utils';
import { IconView, IconEdit, IconDots } from '../../../assets/Icons';

const PublishedInfoComponent = ({ isMobile, colorBorder, data, last }) => {
	return (
		<>
			<GridItem py={isMobile ? '15px' : '0'}>
				<Text
					fontSize={isMobile ? '12px' : '14px'}
					fontWeight={isMobile ? '600' : '500'}
				>
					{data.name}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(data.totalPrize)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{data.status}
			</Text>

			{!isMobile && (
				<Flex
					w='100%'
					justifyContent='center'
					align='center'
					p='6px'
					gap='10px'
				>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
					>
						<IconEdit />
						Edit
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						gap='5px'
					>
						<IconView />
						View
					</Button>
				</Flex>
			)}
			{isMobile && (
				<GridItem colSpan={isMobile ? 0 : 2}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem gap='5px'>
								<IconView />
								Edit
							</MenuItem>
							<MenuItem gap='5px'>
								<IconView />
								View
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isMobile ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}
		</>
	);
};

export default PublishedInfoComponent;
