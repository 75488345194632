import {
	Avatar,
	Box,
	Divider,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Link,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import DarkLightModeComponent from './DarkLightModeComponent';
import LogoComponent from './LogoComponent';
import MenuComponent from './MenuComponent/MenuComponent';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { PrivateRoutes, headerLinks } from '../routes/Routes';
import { checkPath } from '../utils/utils';
import { IconSearch } from '../assets/Icons';
import { useTranslation } from 'react-i18next';
import SelectorLenguageComponent from './SelectorLenguageComponent';

/**
*
    @name HeaderComponent
    @description Component used for the desktop version Navbar. It includes the logo, navigation, the light/dark mode switcher 
				 a searcher. Also a link to Sign in into the website
    @returns {JS.Element} HeaderComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const HeaderComponent = ({ position, bottom }) => {
	const userState = useSelector(store => store.user);
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const { colorMode, toggleColorMode } = useColorMode();
	const [isMobile] = useMediaQuery('(max-width: 768px)');
	const { t } = useTranslation();

	const navigateLink = link => navigate(link);

	return (
		<header>
			<Flex
				w='100%'
				h='70px'
				bg={colorMode === 'dark' ? 'black' : 'rgba(0, 0, 0, 0.05)'}
				position={position}
				bottom={bottom}
				zIndex={10}
				justifyContent='space-between'
			>
				<Flex alignItems='center' px='50px' gap='50px'>
					<LogoComponent m='20px' />
					<Flex gap={'26px'}>
						{!isMobile &&
							headerLinks.map(
								({ title, to, activePathnames }, index) => {
									return (
										<Link
											key={index}
											id={index}
											variant={
												checkPath(
													pathname,
													activePathnames,
												)
													? 'isActive'
													: null
											}
											onClick={() => navigateLink(to)}
										>
											<Text
												fontWeight='bold'
												fontSize='14px'
											>
												{t(
													`menu.${title.toLowerCase()}`,
												)}
											</Text>
										</Link>
									);
								},
							)}
					</Flex>
				</Flex>
				<Flex alignItems='center' px='50px' gap='7px'>
					<DarkLightModeComponent
						colorMode={colorMode}
						toggleColorMode={toggleColorMode}
					/>
					{userState.role === 'admin' && (
						<Box
							bg={
								colorMode === 'dark'
									? 'transparencyWhite.200'
									: 'transparencyBlack.50'
							}
							border='0.5px solid'
							borderColor={
								colorMode === 'dark' ? 'brand.200' : 'brand.500'
							}
							borderRadius='20px'
							p='10px 15px'
							cursor='pointer'
							onClick={() => navigate(`/${PrivateRoutes.Admin}`)}
						>
							<Text
								variant='colored'
								fontSize='14px'
								fontWeight='600'
								lineHeight='14px'
							>
								Admin
							</Text>
						</Box>
					)}
					<InputGroup w='150px'>
						<Input
							type='search'
							variant='search'
							placeholder={t('header.search')}
							lineHeight='14px'
						/>
						<InputLeftElement>
							<IconSearch
								mt='3px'
								ml='15px'
								opacity='50%'
								boxSize='14px'
							/>
						</InputLeftElement>
					</InputGroup>
					{userState.username !== '' ? (
						<MenuComponent
							colorMode={colorMode}
							button={
								<Avatar
									size='sm'
									src={`https://elevex-cdn.s3.eu-west-3.amazonaws.com/avatars/${userState?.avatarImage}`}
									cursor='pointer'
									p={0}
								/>
							}
						/>
					) : (
						<>
							<Divider
								orientation='vertical'
								h='35px'
								bgColor='transparencyWhite.100'
							/>
							<Link
								fontSize='14px'
								fontWeight='bold'
								variant='isActive'
								as={NavLink}
								to='/login'
							>
								{t('header.signIn')}
							</Link>
						</>
					)}
					<Divider
						orientation='vertical'
						h='35px'
						bgColor='transparencyWhite.100'
					/>
					<SelectorLenguageComponent />
				</Flex>
			</Flex>
		</header>
	);
};

export default HeaderComponent;
