import { Box, Flex, useColorMode, useMediaQuery } from '@chakra-ui/react';
import HeaderComponent from '../components/HeaderComponent';
import MenuComponentMobile from '../components/MenuComponentMobile';
import LogoComponent from '../components/LogoComponent';

const AppLayout = ({ secondary, component }) => {
	const [isTablet] = useMediaQuery('(max-width: 1140px)');

	const { toggleColorMode } = useColorMode();

	return (
		<Flex minH='100vh' flexDirection='column'>
			{isTablet ? (
				<Flex pt='10px' pb='20px' justifyContent='center'>
					<LogoComponent onClick={toggleColorMode} />
				</Flex>
			) : (
				<HeaderComponent px={{ base: 0, md: 10 }} />
			)}
			<Flex w='100%' flex='1' justify='center'>
				<Flex
					w='100%'
					maxW={secondary ? '1140px' : '1920px'}
					flexDirection='column'
					px={
						secondary
							? { base: '20px', md: '30px', '2xl': '0' }
							: { base: '20px', md: '30px' }
					}
				>
					<Box
						w='100%'
						flex='1'
						pb={isTablet ? '90px' : '30px'}
						pt={isTablet ? '0px' : '20px'}
					>
						{component}
					</Box>
				</Flex>
			</Flex>
			{isTablet && <MenuComponentMobile />}
		</Flex>
	);
};
export default AppLayout;
