import { createIcon } from '@chakra-ui/react';

const IconCreditCard = createIcon({
	displayName: 'IconCreditCard',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M13.0313 6.23767H1.03125M1.03125 5.15767L1.03125 9.71767C1.03125 10.3897 1.03125 10.7258 1.16204 10.9825C1.27709 11.2083 1.46067 11.3918 1.68646 11.5069C1.94316 11.6377 2.27919 11.6377 2.95125 11.6377L11.1113 11.6377C11.7833 11.6377 12.1193 11.6377 12.376 11.5069C12.6018 11.3918 12.7854 11.2083 12.9005 10.9825C13.0313 10.7258 13.0313 10.3897 13.0313 9.71767V5.15767C13.0313 4.48561 13.0313 4.14958 12.9005 3.89288C12.7854 3.66709 12.6018 3.48351 12.376 3.36846C12.1193 3.23767 11.7833 3.23767 11.1113 3.23767L2.95125 3.23767C2.27919 3.23767 1.94316 3.23767 1.68646 3.36846C1.46067 3.48351 1.27709 3.66709 1.16204 3.89288C1.03125 4.14958 1.03125 4.48561 1.03125 5.15767Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconCreditCard;
