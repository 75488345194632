import { IconArrow } from '../../../assets/Icons';
import { onboardingImages } from '../../../assets/onboarding';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import LogoComponent from '../../LogoComponent';

const MainSlide = ({ isLastSlide, handleNext, handleFinishOnboarding }) => {
	return (
		<Flex h='calc(100% - 50px)' flexDir='column' justifyContent='flex-end'>
			<Image
				src={onboardingImages.building}
				alignSelf='end'
				position='absolute'
				bottom='0%'
				left='50%'
				transform={'translateX(-50%)'}
				maxH='100%'
			/>
			<Box
				position='absolute'
				top='50%'
				left='50%'
				transform='translate(-50%, -50%)'
			>
				<LogoComponent maxWidth='200px' />
			</Box>
			<Button
				variant='main'
				position='absolute'
				bottom='60px'
				h='33px'
				w='89px'
				borderRadius='30px'
				gap='5px'
				alignSelf='center'
				_active={{
					transform: 'scale(0.95) ',
				}}
				onClick={isLastSlide ? handleFinishOnboarding : handleNext}
			>
				<IconArrow
					boxSize='14px'
					sx={{ transform: 'rotate(180deg)' }}
				/>

				<Text
					lineHeight='150% '
					fontSize='13px'
					fontWeight='400'
					letterSpacing={0.02}
				>
					Next
				</Text>
			</Button>
		</Flex>
	);
};

export default MainSlide;
