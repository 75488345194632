import { Box, Grid, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import ProfileComponent from './components/ProfileComponent';
import AppSettingsComponent from './components/AppSettingsComponent';
import SecurityComponent from './components/SecurityComponent';
import BoxComponent from '../../components/BoxComponent';
import { useEffect, useState } from 'react';
import { getUserProfile } from '../../services/userService';
import { useTranslation } from 'react-i18next';

const Settings = () => {
	const { t } = useTranslation();
	const [userData, setUserData] = useState();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');

	useEffect(() => {
		const getUserInfo = async () => {
			const { data } = await getUserProfile();
			setUserData(data);
		};
		getUserInfo();
	}, []);

	return (
		<>
			{userData && (
				<Box maxW='1280px' mx='auto'>
					{!isTablet && (
						<Text fontWeight='700' fontSize='32px' pt='48px'>
							{t('settings.title')}
						</Text>
					)}
					<Grid
						templateColumns={
							isTablet
								? 'minmax(0, 1fr)'
								: 'repeat(2, minmax(0, 1fr))'
						}
						templateRows={
							isTablet ? '1fr auto auto' : 'repeat(2, 260px)'
						}
						maxW={isTablet ? '450px' : 'none'}
						gap={isTablet ? '20px' : '40px'}
						mx='auto'
						pt={isTablet ? '10px' : '68px'}
					>
						<GridItem rowSpan={isTablet ? '0' : '2'}>
							<BoxComponent h='100%' p='0px'>
								<ProfileComponent
									userData={userData}
									isTablet={isTablet}
								/>
							</BoxComponent>
						</GridItem>
						<GridItem>
							<BoxComponent h='100%' p='0px'>
								<AppSettingsComponent userData={userData} />
							</BoxComponent>
						</GridItem>
						<GridItem>
							<BoxComponent h='100%' p='0px'>
								<SecurityComponent
									userData={userData}
									isTablet={isTablet}
								/>
							</BoxComponent>
						</GridItem>
					</Grid>
				</Box>
			)}
		</>
	);
};
export default Settings;
