import { useMediaQuery } from '@chakra-ui/react';
import OnboardingComponent from '../../../../components/OnboardingComponent/OnboardingComponent';
import OnboardingComponentMobile from '../../../../components/OnboardingComponent/OnboardingComponentMobile';

const Onboarding = ({ closeOnboardingModal }) => {
	const [isMobile] = useMediaQuery('(max-width: 900px)');

	return (
		<>
			{!isMobile ? (
				<OnboardingComponent
					closeOnboardingModal={closeOnboardingModal}
				/>
			) : (
				<OnboardingComponentMobile
					closeOnboardingModal={closeOnboardingModal}
				/>
			)}
		</>
	);
};
export default Onboarding;
