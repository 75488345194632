import { createIcon } from '@chakra-ui/react';

const IconTransfer = createIcon({
	displayName: 'IconTransfer',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.82275 10.5221H12.4894M12.4894 10.5221L9.82275 7.85539M12.4894 10.5221L9.82275 13.1887M12.4894 3.85539H1.82275M1.82275 3.85539L4.48942 1.18872M1.82275 3.85539L4.48942 6.52205'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconTransfer;
