import { Magic } from 'magic-sdk';

const customNodeOptions = {
	rpcUrl: process.env.REACT_APP_MAGIC_RPC_PROVIDER,
	chainId: process.env.REACT_APP_MAGIC_CHAIN_ID,
};

const createMagic = () =>
	typeof window !== 'undefined' &&
	new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY, {
		network: customNodeOptions,
	});

export const magic = createMagic();
