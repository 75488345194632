import { createIcon } from '@chakra-ui/react';

const IconGallery = createIcon({
	displayName: 'IconGallery',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M3.11326 12.635L7.07118 8.67711C7.30879 8.4395 7.42759 8.3207 7.56459 8.27619C7.6851 8.23703 7.8149 8.23703 7.93541 8.27619C8.07241 8.3207 8.19122 8.43951 8.42882 8.67711L12.3604 12.6086M8.95 9.19829L10.6712 7.47711C10.9088 7.2395 11.0276 7.1207 11.1646 7.07619C11.2851 7.03703 11.4149 7.03703 11.5354 7.07619C11.6724 7.1207 11.7912 7.2395 12.0288 7.47711L13.75 9.19829M6.55 5.59829C6.55 6.26103 6.01274 6.79829 5.35 6.79829C4.68726 6.79829 4.15 6.26103 4.15 5.59829C4.15 4.93555 4.68726 4.39829 5.35 4.39829C6.01274 4.39829 6.55 4.93555 6.55 5.59829ZM4.63 12.7983H10.87C11.8781 12.7983 12.3821 12.7983 12.7672 12.6021C13.1059 12.4295 13.3812 12.1542 13.5538 11.8155C13.75 11.4304 13.75 10.9264 13.75 9.91829V4.87829C13.75 3.8702 13.75 3.36615 13.5538 2.98111C13.3812 2.64242 13.1059 2.36705 12.7672 2.19448C12.3821 1.99829 11.8781 1.99829 10.87 1.99829H4.63C3.62191 1.99829 3.11786 1.99829 2.73282 2.19448C2.39413 2.36705 2.11876 2.64242 1.94619 2.98111C1.75 3.36615 1.75 3.8702 1.75 4.87829V9.91829C1.75 10.9264 1.75 11.4304 1.94619 11.8155C2.11876 12.1542 2.39413 12.4295 2.73282 12.6021C3.11786 12.7983 3.62191 12.7983 4.63 12.7983Z'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconGallery;
