import { Flex, Text, useColorMode } from '@chakra-ui/react';

const CryptocurrencyBoxComponent = ({
	icon,
	currency,
	isSelected,
	onClick,
}) => {
	const { colorMode } = useColorMode();

	const bgColorDefault =
		colorMode === 'dark' ? 'transparencyWhite.100' : 'transparencyBlack.50';
	const bgColorSelected = colorMode === 'dark' ? 'brand.200' : 'brand.500';

	const textColorDefault =
		colorMode === 'dark'
			? 'transparencyWhite.500'
			: 'transparencyBlack.600';
	const textColorSelected =
		colorMode === 'dark'
			? 'transparencyBlack.950'
			: 'transparencyWhite.950';

	return (
		<Flex
			w='100%'
			color={isSelected ? textColorSelected : textColorDefault}
			bg={isSelected ? bgColorSelected : bgColorDefault}
			borderRadius='5px'
			align='center'
			justify='center'
			p='5px'
			gap='5px'
			cursor='pointer'
			onClick={onClick}
		>
			{icon}
			<Text fontSize='10px' letterSpacing={-0.02} lineHeight='20px'>
				{currency}
			</Text>
		</Flex>
	);
};

export default CryptocurrencyBoxComponent;
