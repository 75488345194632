import { useState } from 'react';
import ModalComponent from '../../../../../components/ModalComponent';
import CreditCardPurchased from './CreditCardPurchase';
import {
	Flex,
	Text,
	useTheme,
	useColorMode,
	useColorModeValue,
} from '@chakra-ui/react';

import {
	IconCreditCard,
	IconWallet,
	IconCircle,
	IconCircleMiddlePoint,
} from '../../../../../assets/Icons';
import WalletPurchased from './WalletPurchase';
import CrossBoxComponent from '../../../../../components/CrossBoxComponent';

const AddCredit = ({ isOpen, closeModal }) => {
	const { colorMode } = useColorMode();
	const [creditCardView, setCreditCardView] = useState(false);
	const [walletView, setWalletView] = useState(false);

	const theme = useTheme();
	const dotColor = useColorModeValue(
		theme.colors.brand[500],
		theme.colors.brand[200],
	);
	const transparencyColor = useColorModeValue(
		theme.colors.transparencyBlack[500],
		theme.colors.transparencyWhite[500],
	);

	const changeWalletView = () => {
		setCreditCardView(false);
		setWalletView(true);
	};

	const changeCreditCardView = () => {
		setWalletView(false);
		setCreditCardView(true);
	};

	return (
		<ModalComponent isOpen={isOpen} onClose={closeModal} modalCross={false}>
			<Flex justifyContent='flex-end'>
				<CrossBoxComponent closeModal={closeModal} />
			</Flex>
			<Flex
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.900'
				}
				flexDirection='column'
				textAlign='center'
				borderRadius='12px'
				p='20px'
				gap='15px'
			>
				<Text variant='colored' fontSize='20px' fontWeight='bold'>
					Select payment method
				</Text>

				<Flex
					justifyContent='space-between'
					align='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.50'
					}
					borderRadius='12px'
					border='0.5px solid'
					borderColor={
						creditCardView ? dotColor : 'transparencyBlack.200'
					}
					cursor='pointer'
					p='20px'
					gap='30px'
					onClick={changeCreditCardView}
				>
					<IconWallet boxSize='18px' />
					<Text fontSize='16px'>Credit or debit card</Text>
					{creditCardView ? (
						<IconCircleMiddlePoint size='20px' color={dotColor} />
					) : (
						<IconCircle size='20px' color={transparencyColor} />
					)}
				</Flex>
				<Flex
					justifyContent='space-between'
					align='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.50'
					}
					borderRadius='12px'
					border='0.5px solid'
					borderColor={
						walletView ? dotColor : 'transparencyBlack.200'
					}
					cursor='pointer'
					p='20px'
					gap='30px'
					onClick={changeWalletView}
				>
					<IconCreditCard boxSize='20px' />
					<Text fontSize='16px'>External wallet</Text>
					{walletView ? (
						<IconCircleMiddlePoint size='20px' color={dotColor} />
					) : (
						<IconCircle size='20px' color={transparencyColor} />
					)}
				</Flex>
				{creditCardView && <CreditCardPurchased />}
				{walletView && <WalletPurchased />}
			</Flex>
		</ModalComponent>
	);
};

export default AddCredit;
