import { createIcon } from '@chakra-ui/react';

const IconCopyLink = createIcon({
	displayName: 'IconCopyLink',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M8.62928 13.133L7.55822 14.2041C6.07938 15.6829 3.68171 15.6829 2.20288 14.2041C0.724041 12.7253 0.724041 10.3276 2.20288 8.84877L3.27394 7.7777M12.9136 8.84877L13.9846 7.7777C15.4635 6.29886 15.4635 3.9012 13.9846 2.42236C12.5058 0.943523 10.1081 0.943524 8.62928 2.42236L7.55822 3.49343M5.44299 10.964L10.7445 5.66246'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconCopyLink;
