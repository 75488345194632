import {
	Divider,
	Flex,
	Box,
	Avatar,
	Text,
	Image,
	useColorMode,
} from '@chakra-ui/react';
import BoxSmallComponent from '../../../components/BoxSmallComponent';
import { SpainCircle } from '../../../assets/countries';
import { useTranslation } from 'react-i18next';

const ProjectGeneralInfo = ({ projectSelected }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	return (
		<Box>
			<Flex justify='space-between'>
				<Flex gap='10px' align='center'>
					<Text fontWeight='bold' fontSize='24px'>
						{projectSelected.name}
					</Text>
				</Flex>
				<Flex gap='10px' alignItems='center' pl='10px'>
					<Image src={SpainCircle} boxSize='20px' />
					<Box
						bg={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
						h={'fit-content'}
						borderRadius='20px'
						px='10px'
						py='5px'
					>
						<Text
							color={
								colorMode === 'dark'
									? 'transparencyBlack.800'
									: 'transparencyWhite.800'
							}
							fontSize='12px'
							fontWeight='light'
						>
							{t(
								`projectDetails.projectGeneralInfo.state.${projectSelected.state}`,
							)}
						</Text>
					</Box>
					<Box
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.200'
								: 'transparencyWhite.800'
						}
						border='0.5px solid'
						borderColor='transparencyBlack.100'
						h='fit-content'
						borderRadius='20px'
						px='10px'
						py='5px'
					>
						<Text
							variant='colored'
							fontSize='12px'
							fontWeight='light'
						>
							{t(
								`projectDetails.projectGeneralInfo.category.${projectSelected.category}`,
							)}
						</Text>
					</Box>
					<Box
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.200'
								: 'transparencyWhite.800'
						}
						border='0.5px solid'
						borderColor='transparencyBlack.100'
						h='fit-content'
						borderRadius='20px'
						px='10px'
						py='5px'
					>
						<Text
							variant='colored'
							fontSize='12px'
							fontWeight='light'
							textAlign='center'
						>
							{t(
								`projectDetails.projectGeneralInfo.subcategory.${projectSelected.subcategory}`,
							)}
						</Text>
					</Box>
				</Flex>
			</Flex>

			<Divider my='30px'></Divider>

			<Flex wrap='wrap' gap='10px' width='100%'>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.type')}
					textContent={projectSelected.type}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.city')}
					textContent={projectSelected.city}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.address')}
					textContent={projectSelected.address}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.area')}
					textContent={`${projectSelected.area}m²`}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.rooms')}
					textContent={projectSelected.bedrooms}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.bathrooms')}
					textContent={projectSelected.bathrooms}
				/>
			</Flex>

			<Divider my='30px' />

			<Flex gap='15px'>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.postedBy')}
					textContent='Stephen Groff'
					avatar
				>
					<Avatar size='md' />
				</BoxSmallComponent>
				<BoxSmallComponent
					textTitle={t(
						'projectDetails.projectGeneralInfo.propertiesType',
					)}
					textContent='Chalet'
					avatar
				>
					<Avatar size='md' />
				</BoxSmallComponent>
			</Flex>

			<Divider my='30px' />
		</Box>
	);
};

export default ProjectGeneralInfo;
