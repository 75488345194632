import { createIcon } from '@chakra-ui/react';

const IconAlert = createIcon({
	displayName: 'IconAlert',
	viewBox: '0 0 15 15',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<mask id='path-1-inside-1_95_1986'>
				<path
					d='M7.125 14.7646C10.991 14.7646 14.125 11.6306 14.125 7.76465C14.125 3.89865 10.991 0.764648 7.125 0.764648C3.25901 0.764648 0.125 3.89865 0.125 7.76465C0.125 11.6306 3.25901 14.7646 7.125 14.7646Z'
					fill='currentColor'
				/>
				<path
					d='M7.825 10.5646C7.825 10.9512 7.5116 11.2646 7.125 11.2646C6.7384 11.2646 6.425 10.9512 6.425 10.5646C6.425 10.178 6.7384 9.86465 7.125 9.86465C7.5116 9.86465 7.825 10.178 7.825 10.5646Z'
					fill='currentColor'
				/>
			</mask>
			<path
				d='M7.625 4.96465C7.625 4.68851 7.40114 4.46465 7.125 4.46465C6.84886 4.46465 6.625 4.68851 6.625 4.96465H7.625ZM6.625 7.76465C6.625 8.04079 6.84886 8.26465 7.125 8.26465C7.40114 8.26465 7.625 8.04079 7.625 7.76465H6.625ZM6.625 4.96465V7.76465H7.625V4.96465H6.625ZM13.125 7.76465C13.125 11.0784 10.4387 13.7646 7.125 13.7646V15.7646C11.5433 15.7646 15.125 12.1829 15.125 7.76465H13.125ZM7.125 13.7646C3.81129 13.7646 1.125 11.0784 1.125 7.76465H-0.875C-0.875 12.1829 2.70672 15.7646 7.125 15.7646V13.7646ZM1.125 7.76465C1.125 4.45094 3.81129 1.76465 7.125 1.76465V-0.235352C2.70672 -0.235352 -0.875 3.34637 -0.875 7.76465H1.125ZM7.125 1.76465C10.4387 1.76465 13.125 4.45094 13.125 7.76465H15.125C15.125 3.34637 11.5433 -0.235352 7.125 -0.235352V1.76465ZM6.825 10.5646C6.825 10.399 6.95931 10.2646 7.125 10.2646V12.2646C8.06388 12.2646 8.825 11.5035 8.825 10.5646H6.825ZM7.125 10.2646C7.29069 10.2646 7.425 10.399 7.425 10.5646H5.425C5.425 11.5035 6.18612 12.2646 7.125 12.2646V10.2646ZM7.425 10.5646C7.425 10.7303 7.29069 10.8646 7.125 10.8646V8.86465C6.18612 8.86465 5.425 9.62576 5.425 10.5646H7.425ZM7.125 10.8646C6.95931 10.8646 6.825 10.7303 6.825 10.5646H8.825C8.825 9.62576 8.06388 8.86465 7.125 8.86465V10.8646Z'
				fill='currentColor'
				mask='url(#path-1-inside-1_95_1986)'
			/>
		</>
	),
});

export default IconAlert;
