import { createIcon } from '@chakra-ui/react';

const IconNight = createIcon({
	displayName: 'IconNightlight',
	viewBox: '0 0 22 22',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<g clipPath='url(#clip0_4008_834)'>
				<path
					d='M11.0059 10.9967C9.81594 8.93553 10.0694 6.44128 11.4483 4.68293C11.8422 4.17842 11.318 3.45721 10.7053 3.6493C9.99022 3.8774 9.29472 4.23276 8.64946 4.72847C6.24561 6.57821 5.39804 9.92351 6.63335 12.6898C8.27873 16.3663 12.6765 17.7302 16.0713 15.7702C16.4928 15.5268 16.8703 15.2473 17.2186 14.9307C17.6971 14.4928 17.3437 13.6807 16.7008 13.7671C14.4117 14.0956 12.1535 12.9976 11.0059 10.9967Z'
					fill='currentColor'
				/>
			</g>
			<defs>
				<clipPath id='clip0_4008_834'>
					<rect
						width='16'
						height='16'
						fill='currentColor'
						transform='translate(0.0717773 8.07181) rotate(-30)'
					/>
				</clipPath>
			</defs>
		</>
	),
});

export default IconNight;
