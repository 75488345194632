import { createIcon } from '@chakra-ui/react';

const IconArrowUp = createIcon({
	displayName: 'IconArrowUp',
	viewBox: '0 0 16 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.27783 7.77477L8.21857 1.31323L15.1593 7.77477M8.21908 2.21067V15.3132'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconArrowUp;
