import { ChevronDownIcon } from '@chakra-ui/icons';
import {
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const SettingSelectorComponent = ({
	label,
	options,
	selectedOption,
	onSelect,
	name,
}) => {
	const { t } = useTranslation();
	return (
		<Stack w='100%' alignItems='center' gap='10px'>
			<Text
				variant='colored'
				fontSize='14px'
				fontWeight='700'
				lineHeight='20px'
				textAlign='center'
			>
				{label}
			</Text>
			<Menu variant='settings'>
				<MenuButton
					h='26px'
					borderRadius='12px'
					fontWeight='400'
					fontSize='13px'
					p='7px 10px'
				>
					{t(
						`settings.appSettings.selectedOption.${selectedOption?.label}`,
					)}
					<ChevronDownIcon
						width='15px'
						height='19px'
						ml='10px'
						mr='-5px'
					/>
				</MenuButton>
				<MenuList
					maxH='195px'
					overflow='auto'
					bg='transparencyWhite.100'
				>
					{options.map((option, index) => {
						return (
							<MenuItem
								name={name}
								key={index}
								value={option.value}
								onClick={onSelect}
							>
								{t(
									`settings.appSettings.selectedOption.${option?.label}`,
								)}
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</Stack>
	);
};

export default SettingSelectorComponent;
