import { createIcon } from '@chakra-ui/react';

const IconUnview = createIcon({
	displayName: 'IconUnview',
	viewBox: '0 0 16 17',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M9.34909 9.98019C9.17431 10.1678 8.96355 10.3182 8.72937 10.4225C8.49519 10.5269 8.24239 10.583 7.98606 10.5875C7.72972 10.592 7.4751 10.5449 7.23739 10.4489C6.99967 10.3529 6.78373 10.2099 6.60245 10.0287C6.42116 9.84737 6.27825 9.63143 6.18223 9.39372C6.08622 9.156 6.03906 8.90138 6.04358 8.64505C6.04811 8.38871 6.10421 8.13591 6.20856 7.90173C6.3129 7.66755 6.46334 7.45679 6.65091 7.28201M1 1.6311L15 15.6311M11.78 12.4111C10.6922 13.2403 9.36761 13.6997 8 13.722C3.54545 13.722 1 8.6311 1 8.6311C1.79157 7.15595 2.88945 5.86713 4.22 4.8511L11.78 12.4111ZM6.66364 3.69292C7.10167 3.59039 7.55013 3.53914 8 3.54019C12.4545 3.54019 15 8.6311 15 8.6311C14.6137 9.35376 14.153 10.0341 13.6255 10.6611L6.66364 3.69292Z'
			stroke='currentColor'
			fill='none'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconUnview;
