import { createIcon } from '@chakra-ui/react';

const IconDeposit = createIcon({
	displayName: 'IconDeposit',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M10.0005 8.24443H10.0065M11.6667 1.26685H3.13333C2.3866 1.26685 2.01323 1.26685 1.72801 1.41671C1.47713 1.54853 1.27315 1.75888 1.14533 2.01761C1 2.31173 1 2.69677 1 3.46684V10.0668C1 10.8369 1 11.2219 1.14533 11.5161C1.27315 11.7748 1.47713 11.9851 1.72801 12.117C2.01323 12.2668 2.38659 12.2668 3.13333 12.2668M10.8667 12.2668C11.6134 12.2668 11.9868 12.2668 12.272 12.117C12.5229 11.9851 12.7269 11.7748 12.8547 11.5161C13 11.2219 13 10.8369 13 10.0668V6.21685C13 5.44679 13 5.06173 12.8547 4.76761C12.7269 4.50888 12.5229 4.29853 12.272 4.1667C11.9868 4.01684 11.6134 4.01684 10.8667 4.01684H3.66667M10.3005 8.24443C10.3005 8.41527 10.1662 8.5538 10.0005 8.5538C9.83487 8.5538 9.70053 8.41527 9.70053 8.24443C9.70053 8.07358 9.83487 7.93505 10.0005 7.93505C10.1662 7.93505 10.3005 8.07358 10.3005 8.24443Z'
				fill='none'
				stroke='currentColor'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.50744 10.727L7.27755 8.49918C7.14072 8.36247 6.91896 8.36258 6.78226 8.49941L4.55447 10.7293C4.41776 10.8661 4.41787 11.0879 4.5547 11.2246C4.69154 11.3613 4.91329 11.3612 5.05 11.2244L6.68019 9.59264L6.68187 13.1533C6.68196 13.3467 6.83884 13.5034 7.03226 13.5034C7.22569 13.5033 7.38242 13.3464 7.38233 13.153L7.38065 9.59231L9.01238 11.2225C9.14921 11.3592 9.37097 11.3591 9.50767 11.2223C9.64438 11.0854 9.64428 10.8637 9.50744 10.727Z'
				fillRule='evenodd'
				clipRule='evenodd'
				fill='currentColor'
			/>
		</>
	),
});

export default IconDeposit;
