import { Divider, Flex, GridItem, Text } from '@chakra-ui/react';
import { IconEdit, IconTrash } from '../../../../assets/Icons';

const ProjectUpdatesTableInfo = ({ data, last, isTablet, colorBorder }) => {
	return (
		<>
			<GridItem py={isTablet ? '15px' : '0'} px='2px'>
				<Text
					fontSize={isTablet ? '12px' : '14px'}
					fontWeight={isTablet ? '600' : '500'}
				>
					{data.update}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{data.description}
			</Text>

			<Flex
				w='100%'
				minH='50px'
				align='center'
				justify='center'
				gap={isTablet ? '10px' : '25px'}
			>
				<IconEdit boxSize='14px' cursor='pointer' />
				<IconTrash boxSize='14px' cursor='pointer' />
			</Flex>
			{!last && <Divider gridColumn='span 4' borderColor={colorBorder} />}
		</>
	);
};

export default ProjectUpdatesTableInfo;
