import { createIcon } from '@chakra-ui/react';

const IconBuilding = createIcon({
	displayName: 'IconBuilding',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M8.38125 5.96663H11.2613C11.9333 5.96663 12.2693 5.96663 12.526 6.09742C12.7518 6.21247 12.9354 6.39604 13.0505 6.62184C13.1813 6.87853 13.1813 7.21456 13.1813 7.88663V11.9666M8.38125 11.9666V3.08663C8.38125 2.41456 8.38125 2.07853 8.25046 1.82184C8.13541 1.59604 7.95183 1.41247 7.72604 1.29742C7.46934 1.16663 7.13331 1.16663 6.46125 1.16663H4.30125C3.62919 1.16663 3.29316 1.16663 3.03646 1.29742C2.81067 1.41247 2.62709 1.59604 2.51204 1.82184C2.38125 2.07853 2.38125 2.41456 2.38125 3.08663V11.9666M13.7813 11.9666H1.78125M4.48125 3.56663H6.28125M4.48125 5.96663H6.28125M4.48125 8.36663H6.28125'
			stroke='currentColor'
			fill='none'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconBuilding;
