import { IconPlus } from '../../../assets/Icons';
import {
	Box,
	Button,
	Stack,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import BoxComponent from '../../../components/BoxComponent';
import PropertyCardComponent from '../../../components/ProjectCardComponent/PropertyCardComponent';

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
@name MyPropertiesComponent
@description Components that list all the properties the user has.
@returns {JSX.Element} MyPropertiesComponent.js
@author Edunknown | Unknown Gravity - All-in-one Blockchain Company
@Version 1.0.0
*/

const MyPropertiesComponent = ({ user, isTablet }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const [isMobile] = useMediaQuery('(max-width: 900px)');
	const maxCards = isMobile ? 1 : 2;

	return (
		<BoxComponent
			w='100%'
			maxW='500px'
			h={isTablet ? 'auto' : '100%'}
			overflowY={isTablet ? 'none' : 'scroll'}
			p='0'
			inPadding='15px 20px'
			gap='15px'
		>
			<Text fontWeight='bold'>{t('dashboard.myProperties.title')}</Text>

			<Stack
				w='100%'
				alignItems='center'
				gap='15px'
				align='center'
				justify='center'
			>
				{maxCards === 2 ? (
					<>
						{user.properties.slice(0, maxCards).map(project => {
							return (
								<PropertyCardComponent
									key={project.id}
									h='340px'
									project={project}
								/>
							);
						})}
					</>
				) : (
					<Box maxW='312px' h='fit-content' mb='-5px'>
						<Swiper
							modules={[Pagination]}
							spaceBetween={20}
							slidesPerView={1}
							pagination={{
								clickable: true,
							}}
							style={{
								width: '100%',
								height: '370px',

								aspectRatio: '16 / 12',
							}}
						>
							{user.properties.map(project => {
								return (
									<SwiperSlide key={project.id}>
										<PropertyCardComponent
											project={project}
										/>
									</SwiperSlide>
								);
							})}
						</Swiper>
					</Box>
				)}

				{user.properties.length > maxCards && (
					<Button
						variant='main'
						fontWeight='400'
						leftIcon={
							<IconPlus
								boxSize='13px'
								color={
									colorMode === 'dark'
										? 'transparencyBlack.500'
										: 'transparencyWhite.900'
								}
							/>
						}
						p='10px 25px'
						alignSelf='center'
						onClick={() => navigate(`/${PrivateRoutes.Wallet}`)}
					>
						{t('dashboard.myProperties.button')}
					</Button>
				)}
			</Stack>
		</BoxComponent>
	);
};

export default MyPropertiesComponent;
