import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from '../locales/en.json';
import esTranslation from '../locales/es.json';

const browserLanguage = navigator.language || navigator.languages[0];

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: enTranslation,
		},
		es: {
			translation: esTranslation,
		},
	},
	lng: browserLanguage.startsWith('es') ? 'es' : 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
