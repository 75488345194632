import RegisterComponent from './components/RegisterComponent';

/**
*
    @name Register
    @description The register page that include the Register component
    @returns {JS.Element} Register.js
    @author Saguado | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const Register = () => {
	return <RegisterComponent />;
};

export default Register;
