import { Stack, Text } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from '../layout/AppLayout';

const RoutesNotFound = ({ children }) => {
	return (
		<Routes>
			{children}
			<Route
				path='*'
				element={
					<AppLayout
						component={
							<Stack
								direction={'row'}
								h='100%'
								w={'100%'}
								display={'flex'}
								alignItems={'center'}
								justifyContent={'center'}
							>
								<Text fontSize={'4xl'}>Page not found</Text>
							</Stack>
						}
					/>
				}
			/>
		</Routes>
	);
};

export default RoutesNotFound;
