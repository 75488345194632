import { createIcon } from '@chakra-ui/react';

const IconInvest = createIcon({
	displayName: 'IconInvest',
	viewBox: '0 0 16 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M1.14136 8.93481H14.3475M1.14136 10.5005H14.3475M1.14136 12.0959H14.3475M14.7315 7.103C14.7906 7.25072 14.7019 7.3689 14.5542 7.3689H0.964056C0.786793 7.3689 0.727711 7.25072 0.757255 7.103L2.44125 2.52371C2.50034 2.37599 2.67761 2.25781 2.82532 2.25781H12.4862C12.6339 2.25781 12.8407 2.37599 12.8998 2.52371L14.7315 7.103ZM9.44322 4.651C9.56139 5.47823 8.79326 6.18728 7.72968 6.18728C6.6661 6.18728 5.89796 5.44868 5.95705 4.651C6.04568 3.9124 6.81382 3.38062 7.67059 3.38062C8.5569 3.38062 9.35459 3.94195 9.44322 4.651ZM4.39476 4.76503C4.43778 5.06615 4.15818 5.32424 3.77103 5.32424C3.38389 5.32424 3.10429 5.05539 3.12579 4.76503C3.15806 4.49618 3.43766 4.30261 3.74953 4.30261C4.07214 4.30261 4.3625 4.50694 4.39476 4.76503ZM12.28 4.76503C12.323 5.06615 12.0434 5.32424 11.6563 5.32424C11.2691 5.32424 10.9895 5.05539 11.011 4.76503C11.0433 4.49618 11.3229 4.30261 11.6348 4.30261C11.9574 4.30261 12.2478 4.50694 12.28 4.76503Z'
			stroke='currentColor'
			fill='none'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconInvest;
