import { createIcon } from '@chakra-ui/react';

const IconNotificationActive = createIcon({
	displayName: 'IconNotificationActive',
	viewBox: '0 0 15 16',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M7.21289 3.08418C6.08267 3.08418 4.99873 3.53316 4.19954 4.33235C3.40035 5.13154 2.95137 6.21548 2.95137 7.34571V10.3897C2.95137 10.874 2.75895 11.3386 2.41644 11.6811C2.07393 12.0236 1.60938 12.216 1.125 12.216H13.3008C12.8164 12.216 12.3519 12.0236 12.0094 11.6811C11.6668 11.3386 11.4744 10.874 11.4744 10.3897V7.34571C11.4744 6.21548 11.0254 5.13154 10.2262 4.33235C9.42706 3.53316 8.34312 3.08418 7.21289 3.08418ZM7.21289 3.08418V1.25781M8.2661 14.6512C8.15907 14.8357 8.00544 14.9888 7.8206 15.0953C7.63577 15.2018 7.4262 15.2578 7.21289 15.2578C6.99959 15.2578 6.79002 15.2018 6.60518 15.0953C6.42035 14.9888 6.26672 14.8357 6.15969 14.6512'
				stroke='currentColor'
				fill='none'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<circle cx='11.125' cy='5.14233' r='3' fill='#EB2D2D' />
		</>
	),
});

export default IconNotificationActive;
