import BoxComponent from '../../../components/BoxComponent';
import { Box, Divider, Text } from '@chakra-ui/react';
import TransactionInfoComponent from './TransactionInfoComponent';
import { useTranslation } from 'react-i18next';

/**
*
    @name TransactionComponent
    @description Component that list all the transactions a user has done. It overflows and generates a scroll
	@returns {JSX.Element} TransactionComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TransactionComponent = ({ data }) => {
	const { t } = useTranslation();
	return (
		<BoxComponent
			h='100%'
			w='100%'
			maxW='500px'
			p='0'
			inPadding='15px 20px'
		>
			<Text fontSize='16' fontWeight='700' pb='8px'>
				{t('dashboard.transaction.title')}
			</Text>
			{data.map((transaction, index) => {
				return (
					<Box key={index}>
						<TransactionInfoComponent data={transaction} />
						{data.length - 1 > index && <Divider my='5px' />}
					</Box>
				);
			})}
		</BoxComponent>
	);
};

export default TransactionComponent;
