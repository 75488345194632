import {
	Image,
	Grid,
	GridItem,
	Box,
	Text,
	Button,
	useColorMode,
} from '@chakra-ui/react';
import { IconPlus } from '../../../../assets/Icons';

import Timer from './Timer';
import { useTranslation } from 'react-i18next';

const endDate = '2024-10-26T23:59:59';

const GalleryPhoto = ({ imagesSelected }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	return (
		<>
			<Grid
				position='relative'
				templateRows='repeat(2, 1fr)'
				templateColumns='repeat(4, 1fr)'
				gap='10px'
				borderRadius='14px'
				overflow='hidden'
				h='516px'
			>
				{imagesSelected.slice(0, 5).map(img => (
					<GridItem
						key={img.id}
						rowSpan={img.id === 1 ? 2 : 1}
						colSpan={img.id === 1 ? 2 : 1}
					>
						<Image
							src={`/assets/images/houses/${img.url}`}
							borderRadius='3px'
							w='100%'
							h='100%'
							objectFit='cover'
						/>
					</GridItem>
				))}

				<Box position='absolute' bottom='10px' right='10px'>
					<Button variant='main' mr='5px'>
						<IconPlus
							mr='5px'
							boxSize='12px'
							color={
								colorMode === 'dark'
									? 'transparencyBlack.500'
									: 'transparencyWhite.900'
							}
						/>

						<Text fontSize='13px'>
							{t('projectDetails.galleryPhoto.button')}
						</Text>
					</Button>
				</Box>
				<Box position='absolute' top='10px' left='10px'>
					<Timer endDate={endDate} />
				</Box>
			</Grid>
		</>
	);
};

export default GalleryPhoto;
