import { Flex, useColorMode } from '@chakra-ui/react';
import { IconClose } from '../assets/Icons';

const CrossBoxComponent = ({ closeModal }) => {
	const { colorMode } = useColorMode();
	return (
		<Flex
			w='35px'
			h='35px'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.900'
			}
			borderRadius='5px'
			align='center'
			justify='center'
			mb='15px'
			cursor='pointer'
			onClick={closeModal}
		>
			<IconClose boxSize='13px' />
		</Flex>
	);
};

export default CrossBoxComponent;
