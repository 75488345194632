import { Flex, Grid, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import BalanceComponent from './components/BalanceComponent';
import { userExample, income, transactions } from '../../data/mockdata';
import MyPropertiesComponent from './components/MyPropertiesComponent';
import OverviewComponent from './components/OverviewComponent';
import IncomeComponent from './components/IncomeComponent';
import TransactionComponent from './components/TransactionComponent';
import TrendComponent from './components/TrendComponent';
import TokenHoldingsComponent from './components/TokenHoldingsComponent';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
	const { t } = useTranslation();
	const [isTablet] = useMediaQuery('(max-width: 1200px)');

	return (
		<>
			<Flex
				flexDirection={isTablet ? 'column' : 'row'}
				justifyContent='space-between'
				align='center'
				mb='12px'
				gap='12px'
				w='100%'
			>
				<Text fontSize={32} fontWeight='700'>
					{t('dashboard.title')}
				</Text>
				<BalanceComponent user={userExample} isTablet={isTablet} />
			</Flex>
			<Grid
				templateColumns={
					isTablet ? '1fr' : '355px repeat(2, minmax(0, 1fr))'
				}
				templateRows={isTablet ? 'auto' : '165px 270px 380px'}
				rowGap='14px'
				columnGap='12px'
			>
				<GridItem
					rowSpan={3}
					colSpan={isTablet ? 3 : 1}
					w={isTablet ? 'auto' : '100%'}
					display='flex'
					justifyContent='center'
				>
					<MyPropertiesComponent
						user={userExample}
						isTablet={isTablet}
					/>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<OverviewComponent user={userExample} isTablet={isTablet} />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
					w='100%'
				>
					<IncomeComponent data={income} />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<TransactionComponent
						data={transactions}
						isTablet={isTablet}
					/>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<TrendComponent isTablet={isTablet} />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 2}
					display='flex'
					justifyContent='center'
				>
					<TokenHoldingsComponent isTablet={isTablet} />
				</GridItem>
			</Grid>
		</>
	);
};

export default Dashboard;
